import React from "react";
import TopBanner from "../components/TopBanner";
import ContactCoverImg from '../images/contact_cover_img.jpg'
import Map from '../components/Map'
import RequestCallbackForm from "../components/RequestCallbackForm";
import styled from "styled-components";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";
import api, { uploadUrl } from "../api";
import NotFound from "../components/NotFound";
import Loader from "../components/Loader";

const BannerDetailed: React.FC = () => {
    const {
        data: banner,
        isFetching: isDetailedBannerLoading,
        refetch: refetchMainPageBanner } = api.useGetMainPageBannerQuery(undefined);
    return (
        <div>
            {isDetailedBannerLoading ? <Loader /> :
                !banner ? (
                    <div>
                        <NotFound />
                    </div>
                ) :
                    (
                        <>
                            <TopBanner
                                title={banner.titleOne}
                                bannerBackImg={uploadUrl(banner.cover)}
                            />
                            <div className="container">
                                <TicketsTittle>
                                    {banner.titleTwo}
                                </TicketsTittle>
                                <TicketsTittle>
                                    {banner.titleThree}
                                </TicketsTittle>
                                <TicketsTittle>
                                    {banner.description}
                                </TicketsTittle>

                            </div>
                        </>
                    )}
            {/*/hero_in*/}
        </div>
    );
};
export default BannerDetailed;

const TicketsTittle = styled.p`
    font-family: ${Fonts.Roboto};
    font-size: 16px;
    color: ${Colors.Black};
    text-align: center;
    margin-top: 20px;
`;
