import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import styled from "styled-components";
import api, { uploadUrl } from "../../../api";
import Input from "../../../components/Input";
import LightBox from "../../../components/LightBox";
import Loader from "../../../components/Loader";
import NotFound from "../../../components/NotFound";
import Textarea from "../../../components/Textarea";
import { EnabledLanguage, supportedLocales, Tour, TourTranslatedFields, Image } from "../../../domain/types";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";

// MUI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Fonts from "../../../assets/Fonts";
import { Editor } from "@tinymce/tinymce-react";
//
import { FaCloudUploadAlt } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import MultipleImageUpload from "../../Admin_components/MultipleImageUpload";

const Admin_tour_edit = () => {
  const { tourId: tourIdStr = "0" } = useParams();
  const tourId = parseInt(tourIdStr);

  // export data
  const {
    data: existingTour,
    isFetching: isExistingTourLoading,
    refetch: refetchExistingTour,
  } = api.useGetTourByIdQuery(tourId);

  const [editedTour, setEditingTour] = useState<Partial<Tour>>({});
  // example: setTourField('slug', 'NEW_CHANGED_SLUG')
  const setTourField = (field: keyof Tour, value: any) => {
    setEditingTour({
      ...editedTour,
      [field]: value,
    });
  };
  /** example:
   * setTranslatedField("city", "ge", value)
   */
  const setTranslatedField = (field: keyof TourTranslatedFields, language: EnabledLanguage, value: any) => {
    setEditingTour({
      ...editedTour,
      translations: {
        ...editedTour.translations,
        [language]: {
          ...editedTour?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  console.log(editedTour)

  const descriptionRef = useRef<any>(null);

  const [language, setLanguage] = useState<EnabledLanguage>("ge");

  useEffect(() => {
    if (existingTour) {
      // copy loaded tour from backend to draft useState tour
      setEditingTour(existingTour);
    }
  }, [existingTour]);

  const [saveTour, { isLoading: isSaveTourLoading, error: saveTourError }] = api.useSaveTourMutation();

  const [uploadFile, { isLoading: isUploadFileLoading, error: uploadFileError }] = api.useAdminUploadFileMutation();

  const [previewCoverPhotoTmpUrl, setPreviewCoverPhotoTmpUrl] = useState("");
  const [previewPosterPhotoTmpUrl, setPreviewPosterPhotoTmpUrl] = useState("");

  const { t } = useTranslation();

  const isLoading = isExistingTourLoading || isSaveTourLoading;

  return isLoading ? (
    <Loader />
  ) : tourId > 0 && !existingTour ? (
    <NotFound />
  ) : (
    <div className="d-flex flex-column flex-lg-row">
      <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
        <div className="card">
          <div className="card-body p-12">
            <div id="kt_invoice_form">
              <div
                className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
              ></div>
              <div className="mb-0">
                <div className="row gx-10 mb-5">
                  <div className="col-lg-12">
                    <div className="row">
                      {/* Tour Title here */}
                      <div className="mb-3 col-lg-6">
                        <Input
                          id=""
                          label={t("title")}
                          placeholder={t("title")}
                          type="text"
                          // instead city there should be country from data
                          value={editedTour?.translations?.[language]?.title || ""}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setTranslatedField("title", language, value)}
                        />
                      </div>
                      {/* Tour Slug here */}
                      <div className="mb-4 col-lg-6">
                        <Input
                          id=""
                          label={t("slug")}
                          placeholder={t("slug") + "..."}
                          type="text"
                          value={editedTour.slug || ""}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setTourField("slug", value)}
                        />
                      </div>
                      {/* Tour City here */}
                      <div className="mb-3 col-lg-6">
                        <Input
                          id=""
                          label={t("allFormCityInput")}
                          placeholder={t("allFormCityExample")}
                          type="text"
                          value={editedTour?.translations?.[language]?.city || ""}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setTranslatedField("city", language, value)}
                        />
                      </div>
                      {/* Tour Country here */}
                      <div className="mb-3 col-lg-6">
                        <Input
                          id=""
                          label={t("allFormCountry")}
                          placeholder={t("allFormCountryExample")}
                          type="text"
                          // instead city there should be country from data
                          value={editedTour?.translations?.[language]?.country || ""}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setTranslatedField("country", language, value)}
                        />
                      </div>
                      {/* Tour Slug here */}
                      <div className="mb-4 col-lg-6">
                        <Input
                          id=""
                          label={t("sort")}
                          placeholder={t("sort") + "..."}
                          type="number"
                          // @ts-ignore
                          value={editedTour.sort}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setTourField("sort", value)}
                        />
                      </div>
                      {/* Tour type here */}
                      <div className="mb-3 col-lg-6">
                        {/* <BoxStyle>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">აირჩიე ტიპი</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={editedTour.type || ''}
                              label={'ირჩიე ტიპი'}
                              onChange={(value) => {
                                setTourField("type", value.target.value);
                              }}
                            >
                              <MenuItemStyle value="incoming">Incoming</MenuItemStyle>
                              <MenuItemStyle value="outgoing">Outgoing</MenuItemStyle>
                            </Select>
                          </FormControl>
                        </BoxStyle> */}

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-2">
                    <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">{t("shortDescription")}</label>
                    {/* Tour Short description here */}
                    <div className="mb-5">
                      <Textarea
                        id=""
                        name="notes"
                        className="form-control form-control-solid"
                        placeholder={t("shortDescription") + "..."}
                        rows={3}
                        cols={1}
                        value={editedTour.translations?.[language]?.shortDescription}
                        onChange={(value) => setTranslatedField("shortDescription", language, value)}
                      />
                    </div>
                    <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">{t("description")}</label>
                    {/* Tour Description here */}
                    <div className="mb-5">
                      <Editor
                        onInit={(evt: any, editor: any) => (descriptionRef.current = editor)}
                        initialValue={editedTour.translations?.[language]?.description}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onChange={() => {
                          if (descriptionRef.current) {
                            setTranslatedField("description", language, descriptionRef.current.getContent());
                          }
                        }}
                      />
                    </div>
                    {/* BEGIN Upload Cover Photo */}
                    <UploadWrapper>
                      <TitleLabel className="form-label fs-6 fw-bolder text-gray-700 mb-3">ქოვერის ფოტო</TitleLabel>
                      <div>
                        {previewCoverPhotoTmpUrl || editedTour.cover ? (
                          <PrevViewImg src={previewCoverPhotoTmpUrl || uploadUrl(editedTour.cover!)} />
                        ) : null}
                      </div>
                      <UploadContent>
                        <UploadFile>
                          <UploadFileIcon />
                          <UploadInput
                            type="file"
                            accept="image/*"
                            onChange={async (event) => {
                              const files = event.target.files;
                              if (!files || !files.length) {
                                return;
                              }
                              try {
                                /** for now only single file upload */
                                const file = files[0];
                                // preview photo before upload
                                setPreviewCoverPhotoTmpUrl(URL.createObjectURL(file));
                                const uploadResult = await uploadFile({
                                  file,
                                }).unwrap();
                                if (uploadResult.path) {
                                  setTourField("cover", uploadResult.path);
                                  // preview photo AFTER upload
                                  setPreviewCoverPhotoTmpUrl(uploadUrl(uploadResult.path));
                                } else {
                                  throw new Error("upload failed");
                                }
                              } catch (error) {
                                console.log("error while uploading file", error);
                                alert((error as Error).message?.[0] ?? "ფაილის ატვირთვა ვერ მოხერხდა.");
                              }
                            }}
                          />
                        </UploadFile>
                        {previewCoverPhotoTmpUrl && (
                          <DeleteUploadFileBtn type="button" onClick={() => setPreviewCoverPhotoTmpUrl("")}>
                            <DeleteFileIcon />
                          </DeleteUploadFileBtn>
                        )}
                      </UploadContent>
                    </UploadWrapper>
                    {/* END upload cover photo */}

                    {/* BEGIN Upload Poster Photo */}
                    <UploadWrapper>
                      <TitleLabel className="form-label fs-6 fw-bolder text-gray-700 mb-3">პოსტერის ფოტო</TitleLabel>
                      <div>
                        {previewPosterPhotoTmpUrl || editedTour.poster ? (
                          <PrevViewImg src={previewPosterPhotoTmpUrl || uploadUrl(editedTour.poster!)} />
                        ) : null}
                      </div>
                      <UploadContent>
                        <UploadFile>
                          <UploadFileIcon />
                          <UploadInput
                            type="file"
                            accept="image/*"
                            onChange={async (event) => {
                              const files = event.target.files;
                              if (!files || !files.length) {
                                return;
                              }
                              try {
                                /** for now only single file upload */
                                const file = files[0];
                                // preview photo before upload
                                setPreviewPosterPhotoTmpUrl(URL.createObjectURL(file));
                                const uploadResult = await uploadFile({
                                  file,
                                }).unwrap();
                                if (uploadResult.path) {
                                  setTourField("poster", uploadResult.path);
                                  // preview photo AFTER upload
                                  setPreviewPosterPhotoTmpUrl(uploadUrl(uploadResult.path));
                                } else {
                                  throw new Error("upload failed");
                                }
                              } catch (error) {
                                console.log("error while uploading file", error);
                                alert((error as Error).message?.[0] ?? "ფაილის ატვირთვა ვერ მოხერხდა.");
                              }
                            }}
                          />
                        </UploadFile>
                        {previewPosterPhotoTmpUrl && (
                          <DeleteUploadFileBtn type="button" onClick={() => setPreviewPosterPhotoTmpUrl("")}>
                            <DeleteFileIcon />
                          </DeleteUploadFileBtn>
                        )}
                      </UploadContent>
                    </UploadWrapper>
                    {/* END upload poster photo */}

                    {/* BEGIN Manage Gallery */}
                    <UploadWrapper>
                      <TitleLabel className="form-label fs-6 fw-bolder text-gray-700 mb-3">გალერეა</TitleLabel>
                      <div>
                        <MultipleImageUpload
                          images={editedTour.gallery || []}
                          setImages={(value: Image[]) => setTourField("gallery", value)}
                        />
                      </div>
                    </UploadWrapper>
                    {/* END Manage Gallery */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-lg-auto min-w-lg-300px">
        <div
          className="card"
          data-kt-sticky="true"
          data-kt-sticky-name="invoice"
          data-kt-sticky-offset="{default: false, lg: '200px'}"
          data-kt-sticky-width="{lg: '250px', lg: '300px'}"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="150px"
          data-kt-sticky-animation="false"
          data-kt-sticky-zindex={95}
        >
          <div className="card-body p-10">
            <div className="mb-10">
              <BoxStyle>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">{t("changeLanguage")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    label={t("changeLanguage")}
                    onChange={(event) => setLanguage(event.target.value as EnabledLanguage)}
                  >
                    {supportedLocales.map((locale) => (
                      <MenuItemStyle value={locale}>{locale}</MenuItemStyle>
                    ))}
                  </Select>
                </FormControl>
              </BoxStyle>
            </div>
            <div className="mb-10">
              <label className="form-label fw-bolder fs-6 text-gray-700">{t("price")}</label>
              <div className="mt-3">
                <Input
                  id=""
                  label={t("price")}
                  placeholder={t("priceExample")}
                  type="text"
                  value={editedTour.price || ""}
                  required={false}
                  error={false}
                  helperText=""
                  onChange={(value) => setTourField("price", value)}
                />
              </div>
            </div>

            <div className="mb-0">
              <button
                type="button"
                className="btn btn-primary w-100"
                id="kt_invoice_submit_button"
                onClick={async () => {
                  // save tour
                  try {
                    const updatedTour = await saveTour(editedTour).unwrap();
                    if ("error" in updatedTour) {
                      // @ts-ignore
                      throw updatedTour.error;
                    }
                    if (!editedTour.id) {
                      // redirect to newly created tour
                      document.location.href = `/admin/tour-edit/${updatedTour.id}`;
                    } else {
                      refetchExistingTour();
                    }
                    alert("მონაცემები წარმატებით დამახსოვრდა");
                    // refetchExistingTour();
                  } catch (error) {
                    const errorMessage =
                      // @ts-ignore
                      error?.data?.errors?.[0].message || t("errorWhileSaving");
                    toast.error(errorMessage);
                  }
                }}
              >
                <span className="svg-icon svg-icon-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <path
                      d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                      fill="black"
                    />
                    <path
                      opacity="0.3"
                      d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

//
const UploadContent = styled.div`
  display: flex;
  margin-top: 20px;
`;
const DeleteUploadFileBtn = styled.button`
  height: 50px;
  width: 70px;
  border: 1px solid #d40000;
  border-radius: 10px;
  margin-left: 20px;
  background-color: #fff;
  outline: none;
`;
const UploadWrapper = styled.div`
  margin-bottom: 40px;
`;
const PrevViewImg = styled.img`
  height: 150px;
  width: 150px;
  object-fit: cover;
  display: block;
`;
const UploadFile = styled.div`
  position: relative;
  height: 50px;
  width: 70px;
  border: 1px solid gray;
  border-radius: 10px;
`;
const DeleteFileIcon = styled(RiDeleteBinFill)`
  font-size: 27px;
  color: #d40000;
`;
const UploadFileIcon = styled(FaCloudUploadAlt)`
  font-size: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: gray;
  user-select: none;
  pointer-events: none;
`;
const UploadInput = styled.input`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  &::after {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    cursor: pointer;
  }
`;
const TitleLabel = styled.label`
  font-size: 18px;
  font-family: ${Fonts.FiraGoRegular};
`;
//
const BoxStyle = styled(Box)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;
const MenuItemStyle = styled(MenuItem)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;
const DateDiv = styled.div`
  margin-left: 10px;
`;
const InvoiceDiv = styled.div`
  margin-left: 10px;
`;
export default Admin_tour_edit;
