import React, { useEffect } from "react";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BannerDetailed from "./screens/BannerDetailed";
import Loader from "./components/Loader";
import GlobalFOnts from "./components/Fonts";

// import styles
import "./App.scss";
// for admin menu editor
import "react-sortable-tree/style.css";

// import screen components
import Layout from "./components/Layout";
import Home from "./screens/Home";
import TourDetails from "./screens/TourDetails";
import NewsDetailed from "./screens/NewsDetailed";
import About from "./screens/About";
import Contact from "./screens/Contact";
import Tours from "./screens/Tours";
import Destinations from "./screens/Destinations";
import DestinationDetails from "./screens/DestinationDetails";
import RequestVisa from "./screens/RequestVisa";
import RequestTickets from "./screens/RequestTickets";
import RequestPackage from "./screens/RequestPackage";
import RequestHotels from "./screens/RequestHotels";
import RequestOther from "./screens/RequestOther";
import PageDetail from "./screens/PageDetail";
import Mice from "./screens/MICE";
import TopDestinations from "./screens/TopDestination";
import MiceService from "./screens/MiceService";
import NotFound404 from "./screens/NotFound404";
import GeorgianTopDestinations from "./screens/GeorgianTopDestinations";
import ComingSoon from "./screens/ComingSoon";

// admin screens
import Admin_layout from "./admin/admin_layout/Admin_layout";
import Admin_login from "./admin/admin_screens/auth/Admin_login";
import Admin_dashboard from "./admin/admin_screens/Admin_dashboard";
import Admin_menus from "./admin/admin_screens/Admin_menus";
import Admin_menu_builder from "./admin/admin_screens/Admin_menu_builder";
import Admin_tour_list from "./admin/admin_screens/tour/Admin_tour_list";
import Admin_tour_edit from "./admin/admin_screens/tour/Admin_tour_edit";
import Admin_tour_create from "./admin/admin_screens/tour/Admin_tour_create";
import { useAppDispatch, useAppSelector } from "./store";
import api from "./api";
import Admin_users_list from "./admin/admin_screens/users/Admin_users_list";
import Admin_destination_list from "./admin/admin_screens/destination/Admin_destination_list";
import Admin_slider_list from "./admin/admin_screens/slider/Admin_slider_list";
import Admin_banner_list from "./admin/admin_screens/banner/Admin_banner_list";
import Admin_banner_edit from "./admin/admin_screens/banner/Admin_banner_edit";
import Admin_news_list from "./admin/admin_screens/news/Admin_news_list";
import Admin_employees_list from "./admin/admin_screens/employees/Admin_employees_list";
import Admin_contact_info from "./admin/admin_screens/contact/Admin_contact_info";
import Admin_slider_edit from "./admin/admin_screens/slider/Admin_slider_edit";
import Admin_news_edit from "./admin/admin_screens/news/Admin_news_edit";
import Admin_destination_edit from "./admin/admin_screens/destination/Admin_destination_edit";
import Admin_about_list from "./admin/admin_screens/about/Admin_about_list";
import Admin_about_edit from "./admin/admin_screens/about/Admin_about_edit";
import Admin_employees_edit from "./admin/admin_screens/employees/Admin_employees_edit";
import Admin_menu_item_edit from "./admin/admin_screens/Admin_menu_item_edit";
import Admin_pages_list from "./admin/admin_screens/pages/Admin_pages_list";
import Admin_page_edit from "./admin/admin_screens/pages/Admin_page_edit";
import Admin_creact_menu_item from "./admin/admin_screens/Admin_creact_menu_item";
import Admin_topic_edit from "./admin/admin_screens/Admin_topic_edit";
import Admin_topics_list from "./admin/admin_screens/Admin_topics_list";


const PrivateRoute = ({ children }: any) => {
  // const auth = useAuth();
  const auth = useAppSelector((data) => data.auth);
  if (auth.userLoading) {
    return <Loader />;
  }
  return auth.token ? children : <Navigate replace to="/admin" state={{ from: "routeProps.location" }} />;
};

function App() {
  const auth = useAppSelector((data) => data.auth);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (auth.token) {
      dispatch(api.endpoints.profile.initiate({ token: auth.token }));
    }
  }, [auth.token, dispatch]);

  const getDomainName = window.location.hostname;

  return (
    <BrowserRouter>
      <GlobalFOnts />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="about-us"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="contact"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          path="tours"
          element={
            <Layout>
              <Tours />
            </Layout>
          }
        />
        <Route
          path="tour/:slug"
          element={
            <Layout>
              <TourDetails />
            </Layout>
          }
        />
        <Route
          path="news/:slug"
          element={
            <Layout>
              <NewsDetailed />
            </Layout>
          }
        />
        <Route
          path="destinations"
          element={
            <Layout>
              <Destinations />
            </Layout>
          }
        />
        <Route
          path="destination/:slug"
          element={
            <Layout>
              <DestinationDetails />
            </Layout>
          }
        />
        <Route
          path="visa/:country"
          element={
            <Layout>
              <RequestVisa />
            </Layout>
          }
        />
        <Route
          path="tickets"
          element={
            <Layout>
              <RequestTickets />
            </Layout>
          }
        />
        <Route
          path="hotels"
          element={
            <Layout>
              <RequestHotels />
            </Layout>
          }
        />
        <Route
          path="packages"
          element={
            <Layout>
              <RequestPackage />
            </Layout>
          }
        />
        <Route
          path="other"
          element={
            <Layout>
              <RequestOther />
            </Layout>
          }
        />
        <Route
          path="detailed/:slug"
          element={
            <Layout>
              <PageDetail />
            </Layout>
          }
        />
        <Route
          path="banner/:slug"
          element={
            <Layout>
              <BannerDetailed />
            </Layout>
          }
        />
        {/* <Route path="page/Mice" element={<Layout><Mice /></Layout>} /> */}
        <Route
          path="top-destinations"
          element={
            <Layout>
              <TopDestinations />
            </Layout>
          }
        />
        <Route
          path="mice-service"
          element={
            <Layout>
              <MiceService />
            </Layout>
          }
        />
        <Route
          path="georgia-top-destinations"
          element={
            <Layout>
              <GeorgianTopDestinations />
            </Layout>
          }
        />
        {/* Admin */}
        <Route path="/admin" element={<Admin_login />} />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_dashboard />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/menus"
          element={
            <PrivateRoute>
              {" "}
              <Admin_layout>
                <Admin_menus />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/menu-item-create/:slug"
          element={
            <PrivateRoute>
              {" "}
              <Admin_layout>
                <Admin_creact_menu_item />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        {/* example */}
        <Route
          path="/admin/menu-builder/:slug"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_menu_builder />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        {/* admin menu edit */}
        <Route
          path="/admin/menu-item-edit/:id"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_menu_item_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/tours"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_tour_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_users_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/destinations"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_destination_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/destination-edit/:destinationId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_destination_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/slider"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_slider_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/slider-edit/:sliderId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_slider_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/banner"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_banner_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/banner-edit/:bannerId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_banner_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/news"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_news_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/news-edit/:newsId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_news_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/about-us"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_about_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/about-edit/:aboutId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_about_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/employees"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_employees_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/founder-edit/:id"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_employees_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/contact"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_contact_info />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/pages"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_pages_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/pages-edit/:pageId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_page_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/tour-edit/:tourId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_tour_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/topics"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_topics_list />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/topic-edit/:topicId"
          element={
            <PrivateRoute>
              <Admin_layout>
                <Admin_topic_edit />
              </Admin_layout>
            </PrivateRoute>
          }
        />
        {/* <Route path="/admin/tour-create" element={<Admin_layout><Admin_tour_create /></Admin_layout>} /> */}
        {/* Admin */}
        <Route path="*" element={<NotFound404 />} /> {/* not found screen */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
