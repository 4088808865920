
import React from "react";
import styled from "styled-components";
import Fonts from "../assets/Fonts";


const Box: React.FC<{
    title?: string;
    text?: string;
    img?: string;

}> = ({ 
    title,
    text,
    img,
    
}) => {


    return (
        <BoxWrapper className="col-lg-4 col-md-6">
            <a className="box_feat" href="#0">
                <img src={img} alt="" />
                <H3>{title}</H3>
                <p>{text}</p>
            </a>
        </BoxWrapper>
    )
}

const BoxWrapper = styled.div`
    font-family: ${Fonts.FiraGoRegular};
`
const H3 = styled.h3`
    font-family: ${Fonts.FiraGoBold};
 
`


export default Box