import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api, { uploadUrl } from '../../../api';
import CustomSelect from '../../../components/CustomSelect';
import Loader from '../../../components/Loader';
import NotFound from '../../../components/NotFound';
import { Destination, Page } from '../../../domain/types';
import { GoPlus } from 'react-icons/go';
import Fonts from '../../../assets/Fonts';

const Admin_pages_list: React.FC = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [action, setAction] = useState('')

    const [deletepage, { isLoading: isDeletePageLoading }] = api.useAdminDeletePageMutation();
    const {
        data: adminPages,
        isFetching: isAdminPagesLoading,
        refetch: refetchAdminPages } = api.useGetAdminPageQuery(undefined);

    const isPageLoader = isDeletePageLoading || isAdminPagesLoading;
    return (
        <>
            {isPageLoader ? (
                <Loader />
            ) : !adminPages ? (
                <NotFound />
            ) : (
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">
                        </div>
                        <div className="card-toolbar">
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">

                                <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                                    <div className="px-7 py-5">
                                        <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                                    </div>
                                    <div className="separator border-gray-200" />
                                    <div className="px-7 py-5" data-kt-user-table-filter="form">
                                        <div className="mb-10">
                                            <label className="form-label fs-6 fw-bold">Role:</label>
                                            <select className="form-select form-select-solid fw-bolder" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-user-table-filter="role" data-hide-search="true">
                                                <option />
                                                <option value="Administrator">Administrator</option>
                                                <option value="Analyst">Analyst</option>
                                                <option value="Developer">Developer</option>
                                                <option value="Support">Support</option>
                                                <option value="Trial">Trial</option>
                                            </select>
                                        </div>
                                        <div className="mb-10">
                                            <label className="form-label fs-6 fw-bold">Two Step Verification:</label>
                                            <select className="form-select form-select-solid fw-bolder" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-user-table-filter="two-step" data-hide-search="true">
                                                <option />
                                                <option value="Enabled">Enabled</option>
                                            </select>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light btn-active-light-primary fw-bold me-2 px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset">Reset</button>
                                            <button type="submit" className="btn btn-primary fw-bold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="filter">Apply</button>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => navigate(`/admin/pages-edit/0`)} style={{ fontFamily: `${Fonts.FiraGoRegular}` }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_user">
                                    <GoPlus style={{ fontSize: '16px', marginTop: '-3px', marginRight: '10px' }} />
                                    Add Page</button>
                            </div>

                            <div className="d-flex justify-content-end align-items-center d-none" data-kt-user-table-toolbar="selected">
                                <div className="fw-bolder me-5">
                                    <span className="me-2" data-kt-user-table-select="selected_count" />Selected</div>
                                <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">Delete Selected</button>
                            </div>
                            <div className="modal fade" id="kt_modal_export_users" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h2 className="fw-bolder">Export Users</h2>
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x={6} y="17.3137" width={16} height={2} rx={1} transform="rotate(-45 6 17.3137)" fill="black" />
                                                        <rect x="7.41422" y={6} width={16} height={2} rx={1} transform="rotate(45 7.41422 6)" fill="black" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                            <form id="kt_modal_export_users_form" className="form" action="#">
                                                <div className="fv-row mb-10">
                                                    <label className="fs-6 fw-bold form-label mb-2">Select Roles:</label>
                                                    <select name="role" data-control="select2" data-placeholder="Select a role" data-hide-search="true" className="form-select form-select-solid fw-bolder">
                                                        <option />
                                                        <option value="Administrator">Administrator</option>
                                                        <option value="Analyst">Analyst</option>
                                                        <option value="Developer">Developer</option>
                                                        <option value="Support">Support</option>
                                                        <option value="Trial">Trial</option>
                                                    </select>
                                                </div>
                                                <div className="fv-row mb-10">
                                                    <label className="required fs-6 fw-bold form-label mb-2">Select Export Format:</label>
                                                    <select name="format" data-control="select2" data-placeholder="Select a format" data-hide-search="true" className="form-select form-select-solid fw-bolder">
                                                        <option />
                                                        <option value="excel">Excel</option>
                                                        <option value="pdf">PDF</option>
                                                        <option value="cvs">CVS</option>
                                                        <option value="zip">ZIP</option>
                                                    </select>
                                                </div>
                                                <div className="text-center">
                                                    <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                                                    <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                                                        <span className="indicator-label">Submit</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="kt_modal_add_user" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    <div className="modal-content">
                                        <div className="modal-header" id="kt_modal_add_user_header">
                                            <h2 className="fw-bolder">Add User</h2>
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                                                <span className="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x={6} y="17.3137" width={16} height={2} rx={1} transform="rotate(-45 6 17.3137)" fill="black" />
                                                        <rect x="7.41422" y={6} width={16} height={2} rx={1} transform="rotate(45 7.41422 6)" fill="black" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                            <form id="kt_modal_add_user_form" className="form" action="#">
                                                <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                                                    <div className="fv-row mb-7">
                                                        <label className="d-block fw-bold fs-6 mb-5">Avatar</label>
                                                        <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: 'url(/assets/media/avatars/blank.png)' }}>
                                                            <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: 'url(/assets/media/avatars/150-1.jpg)' }} />

                                                            <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                                                <i className="bi bi-pencil-fill fs-7" />
                                                                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                                                <input type="hidden" name="avatar_remove" />
                                                            </label>

                                                            <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                                <i className="bi bi-x fs-2" />
                                                            </span>

                                                            <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                                <i className="bi bi-x fs-2" />
                                                            </span>
                                                        </div>

                                                        <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                                                    </div>

                                                    <div className="fv-row mb-7">
                                                        <label className="required fw-bold fs-6 mb-2">Full Name</label>

                                                        <input type="text" name="user_name" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="Full name" defaultValue="Emma Smith" />
                                                    </div>

                                                    <div className="fv-row mb-7">
                                                        <label className="required fw-bold fs-6 mb-2">Email</label>

                                                        <input type="email" name="user_email" className="form-control form-control-solid mb-3 mb-lg-0" placeholder="example@domain.com" defaultValue="e.smith@kpmg.com.au" />
                                                    </div>

                                                    <div className="mb-7">
                                                        <label className="required fw-bold fs-6 mb-5">Role</label>

                                                        <div className="d-flex fv-row">
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={0} id="kt_modal_update_role_option_0" defaultChecked={true} />

                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_0">
                                                                    <div className="fw-bolder text-gray-800">Administrator</div>
                                                                    <div className="text-gray-600">Best for business owners and company administrators</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5" />
                                                        <div className="d-flex fv-row">
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={1} id="kt_modal_update_role_option_1" />

                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_1">
                                                                    <div className="fw-bolder text-gray-800">Developer</div>
                                                                    <div className="text-gray-600">Best for developers or people primarily using the API</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5" />
                                                        <div className="d-flex fv-row">
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={2} id="kt_modal_update_role_option_2" />

                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_2">
                                                                    <div className="fw-bolder text-gray-800">Analyst</div>
                                                                    <div className="text-gray-600">Best for people who need full access to analytics data, but don't need to update business settings</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5" />
                                                        <div className="d-flex fv-row">
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={3} id="kt_modal_update_role_option_3" />

                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_3">
                                                                    <div className="fw-bolder text-gray-800">Support</div>
                                                                    <div className="text-gray-600">Best for employees who regularly refund payments and respond to disputes</div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5" />
                                                        <div className="d-flex fv-row">
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={4} id="kt_modal_update_role_option_4" />

                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_4">
                                                                    <div className="fw-bolder text-gray-800">Trial</div>
                                                                    <div className="text-gray-600">Best for people who need to preview content data, but don't need to make any updates</div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="text-center pt-15">
                                                    <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                                                    <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                                                        <span className="indicator-label">Submit</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body pt-0">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                            <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                    <th className="w-10px pe-2">
                                        {/* <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                            <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_table_users .form-check-input" defaultValue={1} />
                                        </div> */}
                                    </th>
                                    <th className="min-w-125px">Image</th>
                                    <th className="min-w-125px">Title</th>
                                    <th className="min-w-125px">Description</th>
                                    <th className="min-w-125px">Created At</th>
                                    <th className="min-w-125px">Updated At</th>
                                    <th className="text-end min-w-100px">Actions</th>
                                </tr>
                            </thead>

                            <tbody className="text-gray-600 fw-bold">

                                {adminPages?.map((ad: Page) => (
                                    <tr>
                                        <td>
                                            {/* <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                <input className="form-check-input" type="checkbox" defaultValue={1} />
                                            </div> */}
                                        </td>

                                        <td className="d-flex align-items-center">
                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                <Link to={`/admin/pages-edit/${ad.id}`}>
                                                    <div className="symbol-label">
                                                        <img src={uploadUrl(ad.cover)} alt="Emma Smith" className="w-100 h-100" style={{ objectFit: 'cover' }} />
                                                    </div>
                                                </Link>
                                            </div>


                                        </td>

                                        <td>{ad.title}</td>

                                        <td>
                                            <DescrSpan>{ad.description}</DescrSpan>
                                        </td>

                                        <td>{dayjs(ad.createdAt).format('D MMMM YYYY')}</td>

                                        <td>{dayjs(ad.updatedAt).format('D MMMM YYYY')}</td>

                                        <td className="text-end">
                                            <CustomSelect
                                                style={{ width: '120px' }}
                                                id=''
                                                label={t("Action")}
                                                options={[
                                                    { value: 'edit', label: "ჩასწორება" },
                                                    { value: 'delete', label: "წაშლა" },
                                                ]}
                                                value={''}
                                                onChange={async (action) => {
                                                    if (action === 'edit') {
                                                        navigate(`/admin/pages-edit/${ad.id}`)
                                                    }
                                                    else {
                                                        if (window.confirm('are you sure about that ?')) {
                                                            const result = await deletepage(ad.id).unwrap()
                                                            console.log(result)
                                                            refetchAdminPages()
                                                            alert(result.status)
                                                        }
                                                    }
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
            }


        </>
    )
}


const DescrSpan = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

export default Admin_pages_list