import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from "../api";
import appSlice from "./appSlice";
import authSlice from "./authSlice";


const combinedReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authSlice,
    app: appSlice,
})


export const rootReducer: any = (state: RootState, action: AnyAction) => {
    // clear cache/store on logout
    if (action?.meta?.arg?.endpointName == 'logout' &&
        // check that the logout action is fulfilled, otherwise token will be cleared before sending to backend, and second logout without refresh will give an error (token will be cleared before POST logout request is made
        action?.type == 'api/executeMutation/fulfilled') {
        // @ts-ignore
        state = undefined;
    }
    return combinedReducer(state, action);
};


export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(api.middleware)
});




// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type AppThunk = ThunkAction<void, RootState, unknown, Action>

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store