import React from "react";
import RequestCallbackForm from '../components/RequestCallbackForm';
import TopBanner from "../components/TopBanner";
import ContactCoverImg from '../images/contact_cover_img.jpg'
import Map from '../components/Map'
import styled from "styled-components";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";

const RequestVisa: React.FC = () => {
  return (
    <div>
      <TopBanner
        title='გაერთიანებული საემიროების ვიზა'
        bannerBackImg={ContactCoverImg}
      />
      {/*/hero_in*/}

      <div className="container mt-3">
        <VisaTittle>მოთხოვნილი დოკუმენტაცია:</VisaTittle>
        <VisaUl className="px-3">
          <li>პასპორტი (ვადა უნდა აღემატებოდეს 6 თვეს)</li>
          <li>შევსებული ვიზის აპლიკაცია</li>
        </VisaUl>
        <p className="mb-2">ვიზის გაცემას სჭირდება 2-3 სამუშაო დღე. 1 თვიანი ტურისტული ვიზით სარგებლობა, 30 დღე ერთჯერად გამოყენების უფლებით.</p>
        <p className="mb-2">ღირებულება ავიაბილეთთან ერთად 92$</p>
        <p className="mx-5">მხოლოდ ვიზა 129$</p>

        <RequestCallbackForm
          showFullNameInput={true}
          showNumberInput={true}
          showEmailInput={true}
          showDirectionSelector={true}
          showDateInput={true}
        />

      </div>

    </div>
  );
};
export default RequestVisa;

const VisaUl = styled.ul`
    list-style-type: circle;
    color: ${Colors.Gray};
`;
const VisaTittle = styled.p`
    font-family: ${Fonts.Roboto};
    font-size: 16px;
    color: ${Colors.Black};
    margin-top: 20px;
    margin-bottom: 15px;
`;