import React from 'react';
import styled from 'styled-components';
import Colors from '../assets/Colors';
import Fonts from '../assets/Fonts';


const NotFound: React.FC = () => {

    return (
        <Wrapper>
            data is missing
        </Wrapper>
    )
}


const Wrapper = styled.div`
    font-family: ${Fonts.FiraGoBold};
    color: ${Colors.Gray};
    text-align: center;
    font-size: 24px;
`

export default NotFound;