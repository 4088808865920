import React, { useState } from "react";
import RequestCallbackForm from '../components/RequestCallbackForm';
import TopBanner from "../components/TopBanner";
import ContactCoverImg from '../images/contact_cover_img.jpg'
import Map from '../components/Map'
import styled from "styled-components";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";
import CustomSelect from "../components/CustomSelect";

const RequestPackage: React.FC = () => {
  const [destination, setDestination] = useState('')

  return (
    <div>
      <TopBanner
        title='ტურისტული საგზური'
        bannerBackImg={ContactCoverImg}
      />
      {/*/hero_in*/}

      <div className="container">
        <PackageTittle>კომპანია ვოიაჟერი გთავაზობთ სრულ ტურისტულ საგზურებს მსოფლიოს მასშტაბით.</PackageTittle>
        <div className="row mb-4">
          <div className="col-6">
            <CustomSelect
              id='select_review'
              label='აქტუალური მიმართულებები'
              options={[
                { value: '1', label: "გაერთიანებული საემიროები" },
                { value: '2', label: "შრი-ლანკა" },
                { value: '3', label: "მალდივები" },
                { value: '4', label: "ტაილანდი" },
                { value: '5', label: "მალაიზია" },
                { value: '6', label: "ტანზანია" },
                { value: '7', label: "თურქეთი" },
                { value: '8', label: "ეგვიპტე" },
                { value: '9', label: "ინდოეთი" },
              ]}
              value={destination}
              onChange={(value) => setDestination(value)}
            />
          </div>
        </div>


        <RequestCallbackForm
          showFullNameInput={true}
          showCitizenshipInput={true}
          showNumberInput={true}
          showEmailInput={true}
          showDirectionSelector={true}
          showDateInput={true}
        />

      </div>

    </div>
  );
};
export default RequestPackage;

const PackageTittle = styled.p`
    font-family: ${Fonts.FiraGoRegular};
    font-size: 17px;
    color: ${Colors.Black};
    margin-top: 20px;
    margin-bottom: 15px;
`;

