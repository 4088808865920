// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  Tour,
  MenuItem,
  MainSlider,
  Founders,
  AboutUs,
  Contact,
  Banner,
  News,
  EnabledLanguage,
  Footer,
  Page,
  Destination,
  Form,
  PopularTour,
  Topics,
  AdminUser,
  AdminMenuItem,
  AdminTour,
  AdminContact,
  EditableAboutUsFields,
  Menu,
  Image,
  AddMenuItem,
} from "../domain/types";
import { RootState } from "../store";
import { detectUserLanguage } from "../store/langStorage";
export const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
export const serverUrl = devMode ? "http://localhost:3333" : `https://${document.location.hostname}`; // after deployment, the api is `${same hostname}/api`
export const apiUrl = `${serverUrl}/api`;

export const uploadUrl = (uploadedFile: string) => `${serverUrl}${uploadedFile}`;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      const lang = detectUserLanguage();
      headers.set("locale", lang);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMainMenuItems: builder.query<MenuItem[], undefined>({
      query: (noArgs) => ({
        url: "/main-menu-items",
        method: "GET",
      }),
    }),
    getFooterMenuItems: builder.query<Footer[], undefined>({
      query: (noArgs) => ({
        url: "/footer-menu-items",
        method: "GET",
      }),
    }),
    getMainSlider: builder.query<MainSlider[], undefined>({
      query: (noArgs) => ({
        url: "/slider",
        method: "GET",
      }),
    }),
    getFounders: builder.query<Founders[], undefined>({
      query: (noArgs) => ({
        url: "/founders",
        method: "GET",
      }),
    }),
    getAboutUs: builder.query<AboutUs, undefined>({
      query: (noArgs) => ({
        url: "/about-us",
        method: "GET",
      }),
    }),
    getContact: builder.query<Contact, undefined>({
      query: (noArgs) => ({
        url: "/contact",
        method: "GET",
      }),
    }),
    getMainPageBanner: builder.query<Banner, undefined>({
      query: (noArgs) => ({
        url: "/banner",
        method: "GET",
      }),
    }),
    getTours: builder.query<Tour[], undefined>({
      query: (noArgs) => ({
        url: "/tours",
        method: "GET",
      }),
    }),
    getTourDetailed: builder.query<Tour, string>({
      query: (slug) => ({
        url: `/tour/${slug}`,
        method: "GET",
      }),
    }),
    getTourById: builder.query<Tour, number>({
      query: (id) => ({
        url: `/admin-panel/tour/${id}`,
        method: "GET",
      }),
    }),
    getPageById: builder.query<Page, number>({
      query: (id) => ({
        url: `/admin-panel/pages/${id}/current`,
        method: "GET",
      }),
    }),
    getTourPopular: builder.query<PopularTour[], undefined>({
      query: (noArgs) => ({
        url: "/popular-tours",
        method: "GET",
      }),
    }),
    getNews: builder.query<News[], undefined>({
      query: (noArgs) => ({
        url: "/news",
        method: "GET",
      }),
    }),
    getNewsDetailed: builder.query<News, string>({
      query: (slug) => ({
        url: `/news/${slug}`,
        method: "GET",
      }),
    }),
    getPage: builder.query<Page, string>({
      query: (slug) => ({
        url: `/detailed/${slug}`,
        method: "GET",
      }),
    }),
    switchLanguage: builder.mutation({
      // TODO Not Working
      query: (selectedLang) => ({
        url: `/language/${selectedLang}`,
        method: "POST",
      }),
    }),
    getDestinations: builder.query<Destination[], undefined>({
      query: (noArgs) => ({
        url: "/destinations",
        method: "GET",
      }),
    }),
    getGeorgianDestinations: builder.query<Destination[], undefined>({
      query: (noArgs) => ({
        url: "/georgia-top-destinations",
        method: "GET",
      }),
    }),
    gerTopics: builder.query<Topics[], undefined>({
      query: (noArgs) => ({
        url: "/topics",
        method: "GET",
      }),
    }),
    getDestinationDetails: builder.query<Destination, string>({
      query: (slug) => ({
        url: `/destination/${slug}`,
        method: "GET",
      }),
    }),
    /** multiple forms on tour, ticket, visa pages */
    postForm: builder.mutation<Form, Partial<Form>>({
      query: (postForm) => ({
        url: "/form",
        method: "POST",
        body: postForm,
      }),
    }),
    // Admin panel
    // Admin Menu Editor
    getMenuById: builder.query<Menu, number>({
      query: (id) => ({
        url: `/admin-panel/menu/${id}`,
        method: "GET",
      }),
    }),
    // Admin Tours
    saveTour: builder.mutation<Tour, Partial<Tour>>({
      query: (tour) => ({
        url:
          tour && "id" in tour && tour.id && tour.id > 0
            ? `/admin-panel/tour/${tour.id}/update`
            : "/admin-panel/tour/store",
        method: "POST",
        body: tour,
      }),
    }),
    // Admin pages
    savePage: builder.mutation<Page, Partial<Page>>({
      query: (page) => ({
        url:
          page && "id" in page && page.id && page.id > 0
            ? `/admin-panel/pages/${page.id}/update`
            : "/admin-panel/pages/store",
        method: "POST",
        body: page,
      }),
    }),
    // Admin Login
    login: builder.mutation<{ user: AdminUser; token: string }, { email: string; password: string }>({
      query: (credentials) => ({
        url: "/admin/login",
        method: "POST",
        body: credentials,
      }),
    }),
    // admin logout
    logout: builder.mutation({
      query: (args) => ({
        url: "admin-panel/logout",
        method: "POST",
        body: {},
      }),
    }),
    profile: builder.query<AdminUser, { token: string }>({
      // token will be added by `prepareHeaders`
      query: ({ token }) => ({
        url: "auth/profile",
        method: "GET",
      }),
    }),
    // admin menu
    getAdminMenuItems: builder.query<AdminMenuItem[], undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/admin-menu-items",
        method: "GET",
      }),
    }),
    // admin users
    getAdminUser: builder.query<{ data: AdminUser[] }, undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/users",
        method: "GET",
      }),
    }),
    // upload file in admin panel
    adminUploadFile: builder.mutation<{ path: string }, { file: File }>({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append("uploadedFile", file);
        return {
          url: "/admin-panel/storage/upload",
          method: "POST",
          body: formData,
        };
      },
    }),
    // upload file in admin panel
    adminUploadMultipleImages: builder.mutation<Image[], { files: FileList }>({
      query: ({ files }) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("images", files[i]);
        }
        return {
          url: "/admin-panel/images/store",
          method: "POST",
          body: formData,
        };
      },
    }),
    // rearrange menu items
    // save menu item
    saveMenuItem: builder.mutation<AdminMenuItem, Partial<AdminMenuItem>>({
      query: (menuItem) => ({
        url:
          menuItem && "id" in menuItem && menuItem.id && menuItem.id > 0
            ? `/admin-panel/menu/${menuItem.id}/update`
            : "/admin-panel/menu/store",
        method: "POST",
        body: menuItem,
      }),
    }),
    removeMenuItem: builder.mutation<{ success: boolean }, { id: number }>({
      query: ({ id }) => ({
        url: `/admin-panel/menu-item/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    // add menu item
    addMenuItem: builder.mutation<{ success: boolean }, Partial<AddMenuItem>>({
      query: (menuItem) => ({
        url: `/admin-panel/menu-item/store`,
        method: "POST",
        body: menuItem
      }),
    }),
    // GET menu item by id
    getMenuItemById: builder.query<AdminMenuItem, number>({
      query: (id) => ({
        url: `/admin-panel/menu-item/${id}`,
        method: "GET",
      }),
    }),
    moveMenuItemPosition: builder.mutation<
      MenuItem,
      {
        menuItemId: number;
        newParentId: number;
        newSort: number;
      }
    >({
      query: ({ menuItemId, newParentId, newSort }) => ({
        url: `/admin-panel/menu-item/${menuItemId}/move-position`,
        method: "POST",
        body: {
          menuItemId,
          newParentId,
          newSort,
        },
      }),
    }),
    // admin tour
    getAdminTour: builder.query<{ data: AdminTour[] }, undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/tours",
        method: "GET",
      }),
    }),
    // admin tour
    getAdminPage: builder.query<Page[], undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/pages",
        method: "GET",
      }),
    }),
    getAdminContact: builder.query<AdminContact, undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/contact",
        method: "GET",
      }),
    }),
    adminDeleteTour: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/tour/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    adminDeletePage: builder.mutation({
      query: (id) => ({
        url: `admin-panel/pages/${id}/delete`,
        method: "DELETE",
      }),
    }),
    getAdminBanner: builder.query<Banner[], undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/banners",
        method: "GET",
      }),
    }),
    saveBanner: builder.mutation<Banner, Partial<Banner>>({
      query: (banner) => ({
        url:
          banner && "id" in banner && banner.id && banner.id > 0
            ? `/admin-panel/banner/${banner.id}/update`
            : "/admin-panel/banner/store",
        method: "POST",
        body: banner,
      }),
    }),
    getBannerById: builder.query<Banner, number>({
      query: (id) => ({
        url: `/admin-panel/banner/${id}`,
        method: "GET",
      }),
    }),
    adminDeleteBanner: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/banner/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    getAdminSlider: builder.query<MainSlider[], undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/slider",
        method: "GET",
      }),
    }),
    saveSlider: builder.mutation<MainSlider, Partial<MainSlider>>({
      query: (slider) => ({
        url:
          slider && "id" in slider && slider.id && slider.id > 0
            ? `/admin-panel/slider/${slider.id}/update`
            : "/admin-panel/slider/store",
        method: "POST",
        body: slider,
      }),
    }),
    getSliderById: builder.query<MainSlider, number>({
      query: (id) => ({
        url: `/admin-panel/slider/${id}`,
        method: "GET",
      }),
    }),
    adminDeleteSlider: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/slider/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    getAdminNews: builder.query<News[], undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/news",
        method: "GET",
      }),
    }),
    saveNews: builder.mutation<News, Partial<News>>({
      query: (news) => ({
        url:
          news && "id" in news && news.id && news.id > 0
            ? `/admin-panel/news/${news.id}/update`
            : "/admin-panel/news/store",
        method: "POST",
        body: news,
      }),
    }),
    getNewsById: builder.query<News, number>({
      query: (id) => ({
        url: `/admin-panel/news/${id}`,
        method: "GET",
      }),
    }),
    adminDeleteNews: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/news/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    getAdminDestinations: builder.query<{ data: Destination[] }, undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/destinations",
        method: "GET",
      }),
    }),
    saveDestination: builder.mutation<Destination, Partial<Destination>>({
      query: (destination) => ({
        url:
          destination && "id" in destination && destination.id && destination.id > 0
            ? `/admin-panel/destination/${destination.id}/update`
            : "/admin-panel/destination/store",
        method: "POST",
        body: destination,
      }),
    }),
    getDestinationById: builder.query<Destination, number>({
      query: (id) => ({
        url: `/admin-panel/destination/${id}`,
        method: "GET",
      }),
    }),
    adminDeleteDestination: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/destination/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    getAdminAbout: builder.query<AboutUs, undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/about",
        method: "GET",
      }),
    }),
    // saveAbout: builder.mutation<AboutUs, Partial<AboutUs>>({
    saveAbout: builder.mutation<AboutUs, Partial<AboutUs>>({
      query: (about) => ({
        url: `/admin-panel/about/${about.id}/update`,
        method: "POST",
        body: about,
      }),
    }),
    saveContactInfo: builder.mutation<AdminContact, Partial<AdminContact>>({
      query: (contactInfo) => ({
        url: `/admin-panel/contact/${contactInfo.id}/update`,
        method: "POST",
        body: contactInfo,
      }),
    }),

    // ssaveAbout: builder.mutation<AboutUs, Partial<AboutUs>>({
    //   query: (about) => {
    //     const formData = Object.entries(about).reduce((d, [name, value]) => {
    //       name && value && d.append(name, value);
    //       return d;
    //     }, new FormData());
    //     return {
    //       url:
    //         about && "id" in about && about.id && about.id > 0
    //           ? `/admin-panel/about/${about.id}/update`
    //           : "/admin-panel/about/store",
    //       method: "POST",
    //       body: formData,//profileFields,
    //     };
    //   },
    // }),

    getAboutById: builder.query<AboutUs, number>({
      query: (id) => ({
        url: `/admin-panel/about/${id}`,
        method: "GET",
      }),
    }),
    adminDeleteAbout: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/about/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    getAdminFounders: builder.query<Founders[], undefined>({
      query: (noArgs) => ({
        url: "/admin-panel/founders",
        method: "GET",
      }),
    }),
    // save founders
    saveFounders: builder.mutation<Founders, Partial<Founders>>({
      query: (founders) => ({
        url:
          founders && "id" in founders && founders.id && founders.id > 0
            ? `/admin-panel/founder/${founders.id}/update`
            : "/admin-panel/founder/store",
        method: "POST",
        body: founders,
      }),
    }),
    getFoundersById: builder.query<Founders, number>({
      query: (id) => ({
        url: `/admin-panel/founder/${id}`,
        method: "GET",
      }),
    }),
    adminDeleteFounders: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/founder/${id}/destroy`,
        method: "DELETE",
      }),
    }),
    // get topics
    getTopics: builder.query<Topics[], undefined>({
      query: (id) => ({
        url: `/admin-panel/topics`,
        method: "GET",
      }),
    }),
    // get topic by id
    getTopicById: builder.query<Topics, number>({
      query: (id) => ({
        url: `/admin-panel/topics/${id}/current`,
        method: "GET",
      }),
    }),
    // save topic
    saveTopic: builder.mutation<Topics, Partial<Topics>>({
      query: (topic) => ({
        url:
          topic && "id" in topic && topic.id && topic.id > 0
            ? `/admin-panel/topics/${topic.id}/update`
            : "/admin-panel/topics/store",
        method: "POST",
        body: topic,
      }),
    }),
    // delete topic
    deleteTopic: builder.mutation({
      query: (id) => ({
        url: `/admin-panel/topics/${id}/delete`,
        method: "DELETE",
      }),
    }),
  }),
});

export default api;
