import React from "react";
import styled from "styled-components";
import Colors from "../assets/Colors";
import Fonts from "../assets/Fonts";
import Box from "../components/Box";
import TopBanner from "../components/TopBanner";
import MiceServCovImg from '../images/MiceServ.jpg';
import IconImg from '../images/icon.svg'




const MiceService: React.FC = () => {





    return (
        <>
            <TopBanner
                title="მომსახურების ტიპები"
                bannerBackImg={MiceServCovImg}
            />
            <div className="container">
                <Title>
                    hello
                </Title>
                <Text>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi ex qui nulla iure voluptas vero?
                </Text>
                <FaqWrapper className="row" >
                    <Box
                        img={IconImg}
                        title='გჭირდება დახმარება ? დაგვიკავშირდი'
                        text="წიგნის საცდელი ეგზემპლარი დაბეჭდა."
                    />
                    <Box
                        img={IconImg}
                        title='გადახდები და თანხის დაბრუნება'
                        text="ცნობილი ფაქტია, რომ გვერდის წაკითხვად შიგთავსს შეუძლია მკითხველის ყურადღება მიიზიდოს და დიზაინის აღქმაში ხელი შეუშალოს."
                    />
                    <Box
                        img={IconImg}
                        title='ხარისხის სტანდარტები'
                        text="იგი სტანდარტად 1500-იანი წლებიდან იქცა."
                    />
                </FaqWrapper>
            </div>
        </>
    )
}

const Title = styled.h1`
    text-align: center;
    font-family: ${Fonts.FiraGoRegular};
    font-size: 21px;
    margin-top: 50px;
`
const Text = styled.h3`
    font-style: italic;
    font-size: 15px;
    text-align: center;
    font-family: ${Fonts.FiraGoBold};
    color: ${Colors.Gray};
    
`
const FaqWrapper = styled.div`
    margin-top: 25px;
`


export default MiceService