import { string } from "yup";

export type EnabledLanguage = "ge" | "en";
export const supportedLocales: EnabledLanguage[] = ["ge", "en"];
export const isSupportedLanguage = (lang: string | null) => lang && supportedLocales.some((l) => l == lang);

export type TranslationsField<TFields> = Partial<Record<EnabledLanguage, Partial<TFields> | undefined>>;

// update types
export type TourTranslatedFields = {
  title: string;
  shortDescription: string;
  description: string;
  city: string;
  country: string;
};
export type DestinationTranslatedFields = {
  title: string;
  country: string;
  city: string;
  description: string;
  shortDescription: string;
};
export type MainSliderTranslatedFields = {
  title: string;
  description: string;
};
export type BannerTranslatedFields = {
  titleOne: string;
  titleTwo: string;
  titleThree: string;
  description: string;
};
export type FoundersTranslatedFields = {
  name: string;
  position: string;
};
export type ContactTranslatedFields = {
  address: string;
};
export type AboutTranslatedFields = {
  title: string;
  description: string;
};
export type NewsTranslatedFields = {
  title: string;
  shortDescription: string;
  description: string;
  date: string;
};
export type PageTranslatedFields = {
  title: string;
  titleShort: string;
  description: string;
  shortDescription: string;
};
export type MenuItemTranslatedFields = {
  title: string;
};
export type TopicsTranslatedFields = {
  title: string;
};


//
export type Tour = {
  id: number;
  slug: string;
  type: "incoming" | "outgoing";
  poster: string;
  cover: string;
  translations: TranslationsField<TourTranslatedFields>;
  price: string;
  location: string;
  category: string;
  createdAt: Date;
  updatedAt: Date;
  gallery: Image[];
  sort: number;
  // fake translated fields: backend adds this for ease of use
  title: string;
  shortDescription: string;
  description: string;
  city: string;
  country: string;
};

export type AdminUser = {
  id: number;
  avatar: string;
  email: string;
  rememberMeToken: string;
  translations: TranslationsField<{
    firstName: string;
    lastName: string;
    biography: string;
  }>;
  firstName: string;
  lastName: string;
  biography: string;
  createdAt: Date;
  updatedAt: Date;
};

export type AdminMenuItem = {
  id: number;
  menuId: number;
  parentId: number;
  url: string;
  title: string;
  createdAt: Date;
  updatedAt: Date;
  slug: string;
  type: "incoming" | "outgoing";
};

export type AdminTour = {
  id: number;
  slug: string;
  type: "incoming" | "outgoing";
  poster: string;
  cover: string;
  price: string;
  popular: number;
  title: string;
  description: string;
  shortDescription: string;
  createdAt: Date;
  updatedAt: Date;
};

export type PopularTour = {
  id: number;
  slug: string;
  type: "incoming" | "outgoing";
  poster: string;
  cover: string;
  price: string;
  popular: number;
  title: string;
  description: string;
  shortDescription: string;
  country: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Image = {
  id: number;
  tourId: number;
  image: string;
  createdAt: Date;
  updatedAt: Date;
};
export type Form = {
  phone: string;
  userEmail: string;
  topicId: number;
  type: string;
  citizenship: string;
  destination: string;
  description: string;
  fullPassenger: string;
  teenagePassenger: string;
  startDate: string;
  endDate: string;
  city: string;
  fullName: string;
};

export type Topics = {
  id: number;
  type: string;
  email: string;
  translations: TranslationsField<TopicsTranslatedFields>;
  title: string;
};

export type Menu = {
  id: number;
  title: string;
  menuItems: MenuItem[];
  createdAt: Date;
  updatedAt: Date;
};
export type MenuItem = {
  id: number;
  url: string;
  title: string;
  sort: number;
  parentId?: number;
  menuId: number;
  translations: TranslationsField<{
    title: string;
  }>;
  createdAt: Date;
  updatedAt: Date;
  subMenu: MenuItem[];
};
export type Footer = {
  id: number;
  url: string;
  title: string;
  parentId?: number;
  menuId: number;
  translations: TranslationsField<{
    title: string;
  }>;
  createdAt: Date;
  updatedAt: Date;
  subMenu: Footer[];
};
export type MainSlider = {
  id: number;
  cover: string;
  title: string;
  description: string;
  translations: TranslationsField<MainSliderTranslatedFields>;
  createdAt: Date;
  updatedAt: Date;
};
export type Banner = {
  id: number;
  slug: string;
  banner: string;
  cover: string;
  translations: TranslationsField<BannerTranslatedFields>;
  titleOne: string;
  titleTwo: string;
  titleThree: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Founders = {
  id: number;
  image: string;
  email: string;
  translations: TranslationsField<FoundersTranslatedFields>;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  position: string;
};

export type AboutUs = {
  id: number;
  image: string;
  cover: string;
  title: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  translations: TranslationsField<AboutTranslatedFields>;
};
export type AboutUsAdmin = {
  id: number;
  image: string;
  cover: string;
  title: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  translations: TranslationsField<{
    title: string;
    description: string;
  }>;
};
export type EditableAboutUsFields = Partial<Pick<AboutUs, "title" | "description">>;

export type Contact = {
  id: number;
  email: string;
  phone: string;
  address: string;
  workingHours: string;
  city: string;
  translations: TranslationsField<ContactTranslatedFields>;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  cover: string;
  mondayFriday: string;
  weekend: string;
};

export type News = {
  id: number;
  cover: string;
  slug: string;
  translations: TranslationsField<NewsTranslatedFields>;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  shortDescription: string;
  date: string;
  description: string;
};

export type Page = {
  id: number;
  slug: string;
  translations: TranslationsField<PageTranslatedFields>;
  cover: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  shortDescription: string;
  shortTitle: string;
  description: string;
  infoBlocks: InfoBlock[];
  formInputs: string;
  sentTo: number;
};
export type InfoBlock = {
  icon: string;
} & TranslationsField<{
  title: string;
  description: string;
}>;

// export type InfoBlock = {
//     icon: string
//     title: string;
//     description: string;
//     translations: TranslationsField<{ title: string; description: string }>
// }

export type Destination = {
  id: number;
  slug: string;
  cover: string;
  poster: string;
  translations: TranslationsField<DestinationTranslatedFields>;
  title: string;
  description: string;
  shortDescription: string;
  createdAt: Date;
  updatedAt: Date;
  country: string;
  city: string;
  sort: number;
  gallery: Image[];
  type: string;
  infoBlocks: InfoBlock[];
};

export type AdminContact = {
  id: number;
  cover: string;
  email: string;
  phone: string;
  mondayFriday: string;
  weekend: string;
  translations: TranslationsField<ContactTranslatedFields>;
  createdAt: Date;
  updatedAt: Date;
  address: string;
};

export type AddMenuItem = {
  menuId: string;
  url: string;
  translations: TranslationsField<MenuItemTranslatedFields>;
};