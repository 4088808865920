import React, { useEffect, useState } from 'react'
import { SRLWrapper } from 'simple-react-lightbox'
import { uploadUrl } from '../api';
import styled from 'styled-components';
import Responsive from '../assets/Responsive';
import { Image } from '../domain/types'
import Colors from '../assets/Colors';

const LightBox: React.FC<{
  images: Image[]
}> = ({
  images
}) => {
    const [screenSize, getDimension] = useState({ dynamicWidth: window.innerWidth });
    const setDimension = () => {
      getDimension({
        dynamicWidth: window.innerWidth,
      })
    }

    useEffect(() => {
      window.addEventListener('resize', setDimension);

      return (() => {
        window.removeEventListener('resize', setDimension);
      })
    }, [screenSize])


    return (
      <div className='LightBox'>
        <SRLWrapper options={options}>
          <Wrapper>
            {images.map(img => (
              <Div>
                <ImageStyled src={uploadUrl(img.image)} alt="" />
                <span>+ {screenSize.dynamicWidth <= 767 ? images.length - 2 : images.length - 4}</span>
              </Div>
            ))}
          </Wrapper>
        </SRLWrapper>
      </div>
    )
  }

const options = {
  buttons: {
    backgroundColor: 'white',
    iconColor: "black",
    iconPadding: "10px",
    showFullscreenButton: false,
    showDownloadButton: false
  },
  settings: {
    overlayColor: "rgba(0,0,0,0.8)",
  },
  thumbnails: {
    thumbnailsAlignment: "center"
  }
};
const Div = styled.div`
  position: relative;
    span {
      display: none;
      position: absolute;
      color: ${Colors.White};
    }
    &:nth-child(4) {
      span {
        display: block;
      }
    }
    ${Responsive.mobile}{
      &:nth-child(4) {
        span {
          display: none;
        }
      }
      &:nth-child(2) {
        span {
          display: block;
        }
      }
    }
`;
const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  max-height: 140px;
  min-height: 100px;
  object-fit: cover;
`
const Wrapper = styled.div`
	display: grid;
	grid-row-gap: 15px;
	grid-column-gap: 20px;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 1fr;
	overflow: hidden;
  cursor: pointer;
  /* mobile resolution gallery popup dont show more than 2 elements and give second element count background */
	${Responsive.mobile}{
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
		&:nth-child(1) {
      div:nth-child(n + 3) {
			display: none !important;
		}
    /* add + photos here */
    div:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: rgba(0, 0, 0, 0.8);
      img {
        opacity: 30%;
      }
		}
	}
	}
	/* desktop resolution gallery popup dont show more than 4 elements and give number 4 element count background */
	&:nth-child(1) {
		div:nth-child(n + 5) {
			display: none;
		}
    /* add + photos here */
		div:nth-child(4) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: rgba(0, 0, 0, 0.8);
      img {
        opacity: 30%;
      }
		}
	}
`


export default LightBox