import React from "react";

import Header from "./Header";
import Footer from "./Footer";
import api from "../api/index";
import Loader from "./Loader";
import { ToastContainer } from "react-toastify";

const Layout: React.FC = ({ children }) => {
  const { isLoading: isMenuLoading } = api.useGetMainMenuItemsQuery(undefined);

  return isMenuLoading ? (
    <Loader />
  ) : (
    <div id="page">
      <Header />
      <main>{children}</main>
      <Footer />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Layout;
