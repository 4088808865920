import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../assets/Colors";
import Fonts from "../assets/Fonts";


const SubmitButton: React.FC<{
    id?: string;
    title?: string;
    value?: string;
    className?: string;
    circle?: boolean;
    onClick: () => void;
    widthFluid?: boolean;
    heightFluid?: boolean;
}> = ({
    id,
    title,
    value,
    onClick,
    className,
    circle = false,
    widthFluid = false,
    heightFluid = false
}) => {
        return (
            <Btn
                id={id}
                type="submit"
                className={className}
                value={value}
                style={{
                    borderRadius: circle === true ? 25 : 3,
                    width: widthFluid === true ? '100%' : 'fit-content',
                    height: heightFluid === true ? '100%' : 'fit-content'
                }}
                onChange={(event) => onClick()}
            >
                <span>{title}</span>

            </Btn>
        )
    }

const Btn = styled.button`
        border: none;
        background-color: ${Colors.Carnation};
        outline: none;
        text-decoration: none;
        padding: 15px 30px;
        color: ${Colors.White};
        font-weight: 600;
        text-align: center;
        margin-top: unset;
        line-height: 1;
        transition: all 0.3 ease-in-out;
        border-radius: 25px;
        font-family: ${Fonts.FiraGoBold}
    `;



export default SubmitButton;
