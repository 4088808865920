import React from 'react';
import Responsive from "../assets/Responsive";
import styled from "styled-components";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const CustomSelect: React.FC<{
    id: string;
    label: string;
    options: { value: any; label: any }[];
    value: any;
    required?: boolean;
    disabled?: boolean;
    onChange: (value: any) => void;
    style?: React.CSSProperties;
}> = ({
    id,
    label,
    options,
    value,
    onChange,
    required = false,
    disabled = false,
    style
}) => {

        return (
            <Wrapper style={style}>
                <FormControl fullWidth>
                    <InputLabel id={id}>{label}</InputLabel>
                    <Select
                        labelId={id}
                        id={id}
                        value={value}
                        label={label}
                        required={required}
                        disabled={disabled}
                        onChange={(e) => onChange(e.target.value)}
                        fullWidth
                    >
                        {options.map((o) => (
                            <MenuItem selected key={o.value} value={o.value}>{o.label}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </Wrapper>
        )
    }
const Wrapper = styled.div`
    width: 100%;
`;

export default CustomSelect;