import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../api";

// styles
import styled from "styled-components";

// react icons
import { BsEyeFill } from "react-icons/bs"; //open eye icon
import { BsEyeSlashFill } from "react-icons/bs"; //close eye icon

const Admin_login = () => {
  const navigate = useNavigate();

  const [login, { isLoading }] = api.useLoginMutation();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showPass, setShowPass] = useState(false);
  const [disableLoginBtn, setDisableLoginBtn] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // admin login
  const AdminLogin = async () => {
    try {
      await login({ email, password }).unwrap();
      navigate(`dashboard`);
    } catch (error) {
      console.log("login error", error);
      setErrorMessage("Incorrect user credentials");
      alert("Incorrect user credentials");
    }
  };
  // admin login

  // keyboard login
  const EnterTrigger = (e: { charCode: any; keyCode: number }) => {
    if (e.charCode == 13) {
      AdminLogin();
    }
  };
  // keyboard login

  // show pass
  const showPassword = () => {
    setShowPass(!showPass);
  };
  // show pass

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage:
            "url(/assets/media/illustrations/sketchy-1/14-dark.png",
        }}
      >
        <div
          className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
          style={{ height: "100vh" }}
        >
          <div className="col-12 col-xl-4 bg-body rounded shadow-sm p-4 p-md-5 mx-auto">
            {/* <form className="form w-100" noValidate id="kt_sign_in_form" action="#"> */}
            <div className="form w-100" id="kt_sign_in_form">
              <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">
                  Email
                </label>

                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  name="email"
                  autoComplete="off"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <div className="fv-row mb-10" style={{ position: "relative" }}>
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Password
                  </label>
                </div>

                <input
                  className="form-control form-control-lg form-control-solid"
                  type={showPass === true ? "text" : "password"}
                  name="password"
                  autoComplete="off"
                  value={password}
                  onKeyPress={EnterTrigger}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <ShowPassBtn onClick={showPassword}>
                  {showPass === true ? <CloseEye /> : <OpenEye />}
                </ShowPassBtn>
              </div>

              <div className="text-center">
                <button
                  disabled={isLoading === true ? true : false}
                  id="kt_sign_in_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={AdminLogin}
                >
                  <span className="indicator-label">
                    {isLoading === true ? "Wait..." : "Login"}
                  </span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const OpenEye = styled(BsEyeFill)`
  font-size: 18px;
`;

const CloseEye = styled(BsEyeSlashFill)`
  font-size: 18px;
`;

const ShowPassBtn = styled.button`
  position: absolute;
  right: 10px;
  top: calc(100% - 34px);
  background-color: transparent;
  outline: none;
  border: 0px;
  &:hover {
    opacity: 0.7;
  }
`;

export default Admin_login;
