import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import Loader from "../../components/Loader";
import SortableTree, { getNodeAtPath, ReactSortableTreeProps, TreeItem, TreeNode } from "react-sortable-tree";
import { useEffect } from "react";
import { Menu, MenuItem } from "../../domain/types";
import styled from "styled-components";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Fonts from "../../assets/Fonts";
import { GoPlus } from 'react-icons/go';
import Colors from "../../assets/Colors";


// 
const menuIdsBySlug: Record<string, number> = {
  admin: 1,
  main_menu: 2,
  footer_menu: 3,
};

const getMenuIdBySlug = (slug: string) => menuIdsBySlug[slug];

const isRootItem = (item: MenuItem) => !item.parentId;

const menuItemToTreeItem = (item: MenuItem, index: number): TreeItem => {
  let sortedItems: TreeItem[] = [];
  if (item?.subMenu) {
    sortedItems = [...item?.subMenu]
      .sort((a, b) => a.sort - b.sort)
      ?.map((subItem, index) => menuItemToTreeItem(subItem, index));
    sortedItems.sort();
  }
  // @ts-ignore
  const sort = parseFloat(item.sort) || index + 1;
  return {
    // @ts-ignore
    id: item.id,
    // @ts-ignore
    title: `${item.title} ${sort ? "#" + sort : ""}`,
    // @ts-ignore
    sort,
    expanded: true,
    children: sortedItems,
  };
};

const findMenuItemById = (idToFind: number, menu: Menu) => menu.menuItems.find((mi) => mi.id === idToFind);

const Admin_menu_builder = () => {
  const { slug = "" } = useParams();
  const navigate = useNavigate();
  const {
    data: existingMenu = undefined,
    isFetching: isExistingMenuFetching,
    refetch: refetchExistingMenu,
  } = api.useGetMenuByIdQuery(getMenuIdBySlug(slug));
  //
  const [removeMenuItem, { isLoading: isReoveMenuItemLoading }] = api.useRemoveMenuItemMutation();
  //
  const [moveMenuItemPosition, { isLoading: isMoveMenuItemPositionLoading }] = api.useMoveMenuItemPositionMutation();
  //
  const [treeData, setTreeData] = useState<TreeItem[]>([
    // { title: "Chicken", expanded: true, children: [{ title: "Egg" }] },
    // { title: "Fish", expanded: true, children: [{ title: "Shark" }] },
  ]);
  useEffect(() => {
    if (existingMenu) {
      setTreeData(
        existingMenu.menuItems.filter((item) => isRootItem(item)).map((item, index) => menuItemToTreeItem(item, index))
      );
    }
  }, [existingMenu]);
  //
  return !existingMenu ? (
    <Loader />
  ) : (
    <Wrapper>
      <h1>{existingMenu.title}</h1>
      <AddMenuItem onClick={() => navigate(`/admin/menu-item-create/header`)}>
        ახალი მენიუს დამატება <GoPlus />
      </AddMenuItem>
      <div>
        <SortableTree
          treeData={treeData}
          onChange={(treeData) => {
            setTreeData(treeData);
          }}
          generateNodeProps={(extendedNode) => ({
            title:
              // @ts-ignore
              // <Link to={`/admin/tour-edit/${extendedNode.id}`}>
              // @ts-ignore
              extendedNode.node.title,
            // </Link>
            buttons: [
              <MenuEditBtn
                onClick={() => {
                  // @ts-ignore
                  navigate(`/admin/menu-item-edit/${extendedNode.node.id}`);
                }}
              >
                <AiFillEdit />
              </MenuEditBtn>,
              <DeleteMenuItemBtn
                // @ts-ignore
                onClick={async () => {
                  // @ts-ignore
                  try {
                    if (!window.confirm(`ნამდვილად გსურთ "${extendedNode.node.title}"-ს წაშლა?`)) {
                      return;
                    }
                    // @ts-ignore
                    if (extendedNode.node.children.length > 0) {
                      alert("რამდენიმე პუნქტის ერთად წაშლა არაა დაუშვებელი.");
                      return;
                    }
                    // @ts-ignore
                    await removeMenuItem({ id: extendedNode.node.id });
                    refetchExistingMenu();
                  } catch (error) {
                    console.log(extendedNode);
                    alert("წაშლა ვერ მოხერხდა.");
                  }
                }}
              >
                <AiFillDelete />
              </DeleteMenuItemBtn>,
            ],
          })}
          onMoveNode={async (event) => {
            const {
              treeData: nextTreeData,
              node,
              nextParentNode,
              prevPath,
              prevTreeIndex,
              nextPath,
              nextTreeIndex,
            } = event;
            // @ts-ignore
            const menuItemToEditId = node.id;
            // @ts-ignore
            const newParentId = nextParentNode?.id;
            const newLocation = nextParentNode?.children ?? treeData;
            if (true) {
              // @ts-ignore
              const nextIndexUnderParent = newLocation.findIndex((mi: any) => mi.id == menuItemToEditId);
              // რომელი მენიუს პუნქტის შემდეგ დაჯდა
              // @ts-ignore
              const prevNode = newLocation[nextIndexUnderParent - 1];
              // რომელი მენიუს პუნქტის წინ დაჯდა
              // @ts-ignore
              const subsequentNode = newLocation[nextIndexUnderParent + 1];
              const prevNodeSort = prevNode?.sort
                ? prevNode.sort ?? 0
                : // @ts-ignore. newLocation[1] because `node` now has become [0]
                (newLocation[1]?.sort ?? 0) - 1 ?? -1;
              // @ts-ignore
              const subsequentNodeSort = subsequentNode
                ? parseFloat(subsequentNode?.sort || 0)
                : // @ts-ignore
                (prevNode?.sort ?? 0) + 3;
              // წინა და შემდეგი პუნქტების საშუალო თანმიმდევრობა (მაგ. 2 და 3-ს შორის ჩაჯდება 5/2=2.5)
              const sortNumberBetweenPrevNext = (prevNodeSort + subsequentNodeSort) / 2;
              // console.log(prevNodeSort, "...", sortNumberBetweenPrevNext, "...", subsequentNodeSort);
              // console.log(
              //   menuItemToEditId,
              //   `(${node.title})`,
              //   "moved under:",
              //   // @ts-ignore
              //   nextParentNode.id,
              //   `(${nextParentNode.title})`,
              //   "on position (index)",
              //   // @ts-ignore
              //   nextIndexUnderParent,
              //   "after",
              //   prevNode,
              //   "before",
              //   subsequentNode
              // );
              try {
                await moveMenuItemPosition({
                  // @ts-ignore
                  menuItemId: node.id,
                  // @ts-ignore
                  newParentId: nextParentNode?.id ?? null,
                  newSort: sortNumberBetweenPrevNext,
                });
                refetchExistingMenu();
              } catch (error) {
                alert("მოხდა შეცდომა. სცადეთ თავიდან.");
              }
            }
            // else {
            //   // no new parent; means was moved as root menu item
            //   // @ts-ignore
            //   const newIndex = treeData.findIndex((x) => x.id === node.id);
            //   const newSortAsRootItem =
            //   if (newSortAsRootItem !== -1) {
            //     try {
            //       await moveMenuItemPosition({
            //         // @ts-ignore
            //         menuItemId: node.id,
            //         // @ts-ignore
            //         newParentId: null,
            //         newSort: newSortAsRootItem,
            //       });
            //       refetchExistingMenu();
            //     } catch (error) {
            //       alert("მოხდა შეცდომა. სცადეთ თავიდან.");
            //     }
            //   } else {
            //     console.log("treeData doesnt contain node at root level", { treeData, node });
            //   }
            // }
          }}
          onlyExpandSearchedNodes={false}
          // isVirtualized={false}// alternative fix for "Cannot find node for unmounted component". Now active fix is using patch-package.
          style={{ height: "calc(100vh - 175px)" }}
        />
      </div>
    </Wrapper>
  );
};

// styles
const Wrapper = styled.div`
  .rst__rowTitle {
    font-family: ${Fonts.FiraGoRegular};
  }
`;
const DeleteMenuItemBtn = styled.button`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 3px;
  padding: 4px 5px;
  background-color: inherit;
  border: 1px solid #aaa;
  transition: all 200ms ease-in;
  margin-left: 5px;
  margin-right: 3px;
  svg {
    transition: all 200ms ease-in;
    font-size: 15px;
    color: #aaa;
  }
  &:hover {
    transition: all 200ms ease-in;
    background-color: #fff;
    border-color: #c70606;
    svg {
      color: #c70606;
    }
  }
`;
const MenuEditBtn = styled(DeleteMenuItemBtn)`
  margin-right: 3px;
  &:hover {
    border-color: #2e9207;
    svg {
      color: #2e9207;
    }
  }
`;
const AddMenuItem = styled(DeleteMenuItemBtn)`
 color: ${Colors.Primary};
 padding: 10px 20px;
 font-family: ${Fonts.FiraGoRegular} !important;
 margin-bottom: 20px;
 margin-top: 20px;
 font-size: 14px;
  svg {
    margin-left: 13px;
  }
  &:hover {
    border-color: ${Colors.Primary};
    svg {
      color: ${Colors.Primary};
    }
  }
`;

export default Admin_menu_builder;
