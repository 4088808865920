import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import api, { uploadUrl } from "../../api";
import CustomSelect from "../../components/CustomSelect";
import Loader from "../../components/Loader";
import { MainSlider } from "../../domain/types";
import { GoPlus } from "react-icons/go";
import Fonts from "../../assets/Fonts";


// 
// 
// 
const Admin_topics_list: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    // export data
    const {
        data: getTopics,
        isFetching: isTopicsLoading,
        refetch: refetchTopics,
    } = api.useGetTopicsQuery(undefined);
    const [deleteTopic, { isLoading: isDeleteTopicLoading }] = api.useDeleteTopicMutation();
    const mainLoading = isDeleteTopicLoading || isTopicsLoading;
    return (
        <>
            {mainLoading ? (
                <Loader />
            ) : (
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title">
                        </div>

                        <div className="card-toolbar">
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                                    <div className="px-7 py-5">
                                        <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                                    </div>
                                    <div className="separator border-gray-200" />
                                    <div className="px-7 py-5" data-kt-user-table-filter="form">
                                        <div className="mb-10">
                                            <label className="form-label fs-6 fw-bold">Role:</label>
                                            <select
                                                className="form-select form-select-solid fw-bolder"
                                                data-kt-select2="true"
                                                data-placeholder="Select option"
                                                data-allow-clear="true"
                                                data-kt-user-table-filter="role"
                                                data-hide-search="true"
                                            >
                                                <option />
                                                <option value="Administrator">Administrator</option>
                                                <option value="Analyst">Analyst</option>
                                                <option value="Developer">Developer</option>
                                                <option value="Support">Support</option>
                                                <option value="Trial">Trial</option>
                                            </select>
                                        </div>

                                        <div className="mb-10">
                                            <label className="form-label fs-6 fw-bold">Two Step Verification:</label>
                                            <select
                                                className="form-select form-select-solid fw-bolder"
                                                data-kt-select2="true"
                                                data-placeholder="Select option"
                                                data-allow-clear="true"
                                                data-kt-user-table-filter="two-step"
                                                data-hide-search="true"
                                            >
                                                <option />
                                                <option value="Enabled">Enabled</option>
                                            </select>
                                        </div>
                                        {/*end::Input group*/}
                                        {/*begin::Actions*/}
                                        <div className="d-flex justify-content-end">
                                            <button
                                                type="reset"
                                                className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                                                data-kt-menu-dismiss="true"
                                                data-kt-user-table-filter="reset"
                                            >
                                                Reset
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary fw-bold px-6"
                                                data-kt-menu-dismiss="true"
                                                data-kt-user-table-filter="filter"
                                            >
                                                Apply
                                            </button>
                                        </div>
                                        {/*end::Actions*/}
                                    </div>
                                    {/*end::Content*/}
                                </div>

                                <button
                                    style={{ fontFamily: `${Fonts.FiraGoRegular}` }}
                                    onClick={() => navigate(`/admin/topic-edit/0`)}
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_add_user"
                                >
                                    <GoPlus style={{ fontSize: "16px", marginTop: "-3px", marginRight: "10px" }} />
                                    Add Topic
                                </button>
                                {/*end::Add user*/}
                            </div>
                            {/*end::Toolbar*/}
                            {/*begin::Group actions*/}
                            <div
                                className="d-flex justify-content-end align-items-center d-none"
                                data-kt-user-table-toolbar="selected"
                            >
                                <div className="fw-bolder me-5">
                                    <span className="me-2" data-kt-user-table-select="selected_count" />
                                    Selected
                                </div>
                                <button type="button" className="btn btn-danger" data-kt-user-table-select="delete_selected">
                                    Delete Selected
                                </button>
                            </div>
                            {/*end::Group actions*/}
                            {/*begin::Modal - Adjust Balance*/}
                            <div className="modal fade" id="kt_modal_export_users" tabIndex={-1} aria-hidden="true">
                                {/*begin::Modal dialog*/}
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    {/*begin::Modal content*/}
                                    <div className="modal-content">
                                        {/*begin::Modal header*/}
                                        <div className="modal-header">
                                            {/*begin::Modal title*/}
                                            <h2 className="fw-bolder">Export Users</h2>
                                            {/*end::Modal title*/}
                                            {/*begin::Close*/}
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x={6}
                                                            y="17.3137"
                                                            width={16}
                                                            height={2}
                                                            rx={1}
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="black"
                                                        />
                                                        <rect
                                                            x="7.41422"
                                                            y={6}
                                                            width={16}
                                                            height={2}
                                                            rx={1}
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </div>
                                            {/*end::Close*/}
                                        </div>
                                        {/*end::Modal header*/}
                                        {/*begin::Modal body*/}
                                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                            {/*begin::Form*/}
                                            <form id="kt_modal_export_users_form" className="form" action="#">
                                                {/*begin::Input group*/}
                                                <div className="fv-row mb-10">
                                                    {/*begin::Label*/}
                                                    <label className="fs-6 fw-bold form-label mb-2">Select Roles:</label>
                                                    {/*end::Label*/}
                                                    {/*begin::Input*/}
                                                    <select
                                                        name="role"
                                                        data-control="select2"
                                                        data-placeholder="Select a role"
                                                        data-hide-search="true"
                                                        className="form-select form-select-solid fw-bolder"
                                                    >
                                                        <option />
                                                        <option value="Administrator">Administrator</option>
                                                        <option value="Analyst">Analyst</option>
                                                        <option value="Developer">Developer</option>
                                                        <option value="Support">Support</option>
                                                        <option value="Trial">Trial</option>
                                                    </select>
                                                    {/*end::Input*/}
                                                </div>
                                                {/*end::Input group*/}
                                                {/*begin::Input group*/}
                                                <div className="fv-row mb-10">
                                                    {/*begin::Label*/}
                                                    <label className="required fs-6 fw-bold form-label mb-2">Select Export Format:</label>
                                                    {/*end::Label*/}
                                                    {/*begin::Input*/}
                                                    <select
                                                        name="format"
                                                        data-control="select2"
                                                        data-placeholder="Select a format"
                                                        data-hide-search="true"
                                                        className="form-select form-select-solid fw-bolder"
                                                    >
                                                        <option />
                                                        <option value="excel">Excel</option>
                                                        <option value="pdf">PDF</option>
                                                        <option value="cvs">CVS</option>
                                                        <option value="zip">ZIP</option>
                                                    </select>
                                                    {/*end::Input*/}
                                                </div>
                                                {/*end::Input group*/}
                                                {/*begin::Actions*/}
                                                <div className="text-center">
                                                    <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">
                                                        Discard
                                                    </button>
                                                    <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                                                        <span className="indicator-label">Submit</span>
                                                        <span className="indicator-progress">
                                                            Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                        </span>
                                                    </button>
                                                </div>
                                                {/*end::Actions*/}
                                            </form>
                                            {/*end::Form*/}
                                        </div>
                                        {/*end::Modal body*/}
                                    </div>
                                    {/*end::Modal content*/}
                                </div>
                                {/*end::Modal dialog*/}
                            </div>
                            {/*end::Modal - New Card*/}
                            {/*begin::Modal - Add task*/}
                            <div className="modal fade" id="kt_modal_add_user" tabIndex={-1} aria-hidden="true">
                                {/*begin::Modal dialog*/}
                                <div className="modal-dialog modal-dialog-centered mw-650px">
                                    {/*begin::Modal content*/}
                                    <div className="modal-content">
                                        {/*begin::Modal header*/}
                                        <div className="modal-header" id="kt_modal_add_user_header">
                                            {/*begin::Modal title*/}
                                            <h2 className="fw-bolder">Add User</h2>
                                            {/*end::Modal title*/}
                                            {/*begin::Close*/}
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                                                {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x={6}
                                                            y="17.3137"
                                                            width={16}
                                                            height={2}
                                                            rx={1}
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="black"
                                                        />
                                                        <rect
                                                            x="7.41422"
                                                            y={6}
                                                            width={16}
                                                            height={2}
                                                            rx={1}
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                {/*end::Svg Icon*/}
                                            </div>
                                            {/*end::Close*/}
                                        </div>
                                        {/*end::Modal header*/}
                                        {/*begin::Modal body*/}
                                        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                                            {/*begin::Form*/}
                                            <form id="kt_modal_add_user_form" className="form" action="#">
                                                {/*begin::Scroll*/}
                                                <div
                                                    className="d-flex flex-column scroll-y me-n7 pe-7"
                                                    id="kt_modal_add_user_scroll"
                                                    data-kt-scroll="true"
                                                    data-kt-scroll-activate="{default: false, lg: true}"
                                                    data-kt-scroll-max-height="auto"
                                                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                                                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                                                    data-kt-scroll-offset="300px"
                                                >
                                                    {/*begin::Input group*/}
                                                    <div className="fv-row mb-7">
                                                        {/*begin::Label*/}
                                                        <label className="d-block fw-bold fs-6 mb-5">Avatar</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Image input*/}
                                                        <div
                                                            className="image-input image-input-outline"
                                                            data-kt-image-input="true"
                                                            style={{ backgroundImage: "url(/assets/media/avatars/blank.png)" }}
                                                        >
                                                            {/*begin::Preview existing avatar*/}
                                                            <div
                                                                className="image-input-wrapper w-125px h-125px"
                                                                style={{ backgroundImage: "url(/assets/media/avatars/150-1.jpg)" }}
                                                            />
                                                            {/*end::Preview existing avatar*/}
                                                            {/*begin::Label*/}
                                                            <label
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="change"
                                                                data-bs-toggle="tooltip"
                                                                title="Change avatar"
                                                            >
                                                                <i className="bi bi-pencil-fill fs-7" />
                                                                {/*begin::Inputs*/}
                                                                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                                                <input type="hidden" name="avatar_remove" />
                                                                {/*end::Inputs*/}
                                                            </label>
                                                            {/*end::Label*/}
                                                            {/*begin::Cancel*/}
                                                            <span
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="cancel"
                                                                data-bs-toggle="tooltip"
                                                                title="Cancel avatar"
                                                            >
                                                                <i className="bi bi-x fs-2" />
                                                            </span>
                                                            {/*end::Cancel*/}
                                                            {/*begin::Remove*/}
                                                            <span
                                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                data-kt-image-input-action="remove"
                                                                data-bs-toggle="tooltip"
                                                                title="Remove avatar"
                                                            >
                                                                <i className="bi bi-x fs-2" />
                                                            </span>
                                                            {/*end::Remove*/}
                                                        </div>
                                                        {/*end::Image input*/}
                                                        {/*begin::Hint*/}
                                                        <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                                                        {/*end::Hint*/}
                                                    </div>
                                                    {/*end::Input group*/}
                                                    {/*begin::Input group*/}
                                                    <div className="fv-row mb-7">
                                                        {/*begin::Label*/}
                                                        <label className="required fw-bold fs-6 mb-2">Full Name</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Input*/}
                                                        <input
                                                            type="text"
                                                            name="user_name"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="Full name"
                                                            defaultValue="Emma Smith"
                                                        />
                                                        {/*end::Input*/}
                                                    </div>
                                                    {/*end::Input group*/}
                                                    {/*begin::Input group*/}
                                                    <div className="fv-row mb-7">
                                                        {/*begin::Label*/}
                                                        <label className="required fw-bold fs-6 mb-2">Email</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Input*/}
                                                        <input
                                                            type="email"
                                                            name="user_email"
                                                            className="form-control form-control-solid mb-3 mb-lg-0"
                                                            placeholder="example@domain.com"
                                                            defaultValue="e.smith@kpmg.com.au"
                                                        />
                                                        {/*end::Input*/}
                                                    </div>
                                                    {/*end::Input group*/}
                                                    {/*begin::Input group*/}
                                                    <div className="mb-7">
                                                        {/*begin::Label*/}
                                                        <label className="required fw-bold fs-6 mb-5">Role</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Roles*/}
                                                        {/*begin::Input row*/}
                                                        <div className="d-flex fv-row">
                                                            {/*begin::Radio*/}
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                {/*begin::Input*/}
                                                                <input
                                                                    className="form-check-input me-3"
                                                                    name="user_role"
                                                                    type="radio"
                                                                    defaultValue={0}
                                                                    id="kt_modal_update_role_option_0"
                                                                    defaultChecked={true}
                                                                />
                                                                {/*end::Input*/}
                                                                {/*begin::Label*/}
                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_0">
                                                                    <div className="fw-bolder text-gray-800">Administrator</div>
                                                                    <div className="text-gray-600">
                                                                        Best for business owners and company administrators
                                                                    </div>
                                                                </label>
                                                                {/*end::Label*/}
                                                            </div>
                                                            {/*end::Radio*/}
                                                        </div>
                                                        {/*end::Input row*/}
                                                        <div className="separator separator-dashed my-5" />
                                                        {/*begin::Input row*/}
                                                        <div className="d-flex fv-row">
                                                            {/*begin::Radio*/}
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                {/*begin::Input*/}
                                                                <input
                                                                    className="form-check-input me-3"
                                                                    name="user_role"
                                                                    type="radio"
                                                                    defaultValue={1}
                                                                    id="kt_modal_update_role_option_1"
                                                                />
                                                                {/*end::Input*/}
                                                                {/*begin::Label*/}
                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_1">
                                                                    <div className="fw-bolder text-gray-800">Developer</div>
                                                                    <div className="text-gray-600">
                                                                        Best for developers or people primarily using the API
                                                                    </div>
                                                                </label>
                                                                {/*end::Label*/}
                                                            </div>
                                                            {/*end::Radio*/}
                                                        </div>
                                                        {/*end::Input row*/}
                                                        <div className="separator separator-dashed my-5" />
                                                        {/*begin::Input row*/}
                                                        <div className="d-flex fv-row">
                                                            {/*begin::Radio*/}
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                {/*begin::Input*/}
                                                                <input
                                                                    className="form-check-input me-3"
                                                                    name="user_role"
                                                                    type="radio"
                                                                    defaultValue={2}
                                                                    id="kt_modal_update_role_option_2"
                                                                />
                                                                {/*end::Input*/}
                                                                {/*begin::Label*/}
                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_2">
                                                                    <div className="fw-bolder text-gray-800">Analyst</div>
                                                                    <div className="text-gray-600">
                                                                        Best for people who need full access to analytics data, but don't need to update
                                                                        business settings
                                                                    </div>
                                                                </label>
                                                                {/*end::Label*/}
                                                            </div>
                                                            {/*end::Radio*/}
                                                        </div>
                                                        {/*end::Input row*/}
                                                        <div className="separator separator-dashed my-5" />
                                                        {/*begin::Input row*/}
                                                        <div className="d-flex fv-row">
                                                            {/*begin::Radio*/}
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                {/*begin::Input*/}
                                                                <input
                                                                    className="form-check-input me-3"
                                                                    name="user_role"
                                                                    type="radio"
                                                                    defaultValue={3}
                                                                    id="kt_modal_update_role_option_3"
                                                                />
                                                                {/*end::Input*/}
                                                                {/*begin::Label*/}
                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_3">
                                                                    <div className="fw-bolder text-gray-800">Support</div>
                                                                    <div className="text-gray-600">
                                                                        Best for employees who regularly refund payments and respond to disputes
                                                                    </div>
                                                                </label>
                                                                {/*end::Label*/}
                                                            </div>
                                                            {/*end::Radio*/}
                                                        </div>
                                                        {/*end::Input row*/}
                                                        <div className="separator separator-dashed my-5" />
                                                        {/*begin::Input row*/}
                                                        <div className="d-flex fv-row">
                                                            {/*begin::Radio*/}
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                {/*begin::Input*/}
                                                                <input
                                                                    className="form-check-input me-3"
                                                                    name="user_role"
                                                                    type="radio"
                                                                    defaultValue={4}
                                                                    id="kt_modal_update_role_option_4"
                                                                />
                                                                {/*end::Input*/}
                                                                {/*begin::Label*/}
                                                                <label className="form-check-label" htmlFor="kt_modal_update_role_option_4">
                                                                    <div className="fw-bolder text-gray-800">Trial</div>
                                                                    <div className="text-gray-600">
                                                                        Best for people who need to preview content data, but don't need to make any updates
                                                                    </div>
                                                                </label>
                                                                {/*end::Label*/}
                                                            </div>
                                                            {/*end::Radio*/}
                                                        </div>
                                                        {/*end::Input row*/}
                                                        {/*end::Roles*/}
                                                    </div>
                                                    {/*end::Input group*/}
                                                </div>
                                                {/*end::Scroll*/}
                                                {/*begin::Actions*/}
                                                <div className="text-center pt-15">
                                                    <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">
                                                        Discard
                                                    </button>
                                                    <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                                                        <span className="indicator-label">Submit</span>
                                                        <span className="indicator-progress">
                                                            Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                                        </span>
                                                    </button>
                                                </div>
                                                {/*end::Actions*/}
                                            </form>
                                            {/*end::Form*/}
                                        </div>
                                        {/*end::Modal body*/}
                                    </div>
                                    {/*end::Modal content*/}
                                </div>
                                {/*end::Modal dialog*/}
                            </div>
                            {/*end::Modal - Add task*/}
                        </div>
                        {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}







                    <div className="card-body pt-0">

                        <table className="w-100 fw-bold">
                            <thead style={{ padding: '20px' }}>
                                <tr style={{height: '70px'}}>
                                    <th>EMAIL</th>
                                    <th>Title</th>
                                    <th style={{ textAlign: 'right' }}>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getTopics?.map((as) => (
                                    <tr style={{height: "70px"}}>
                                        <td>
                                            <Link style={{ color: 'gray' }} to={`/admin/topic-edit/${as.id}`}>
                                                {as.email}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link style={{ color: 'gray' }} to={`/admin/topic-edit/${as.id}`}>
                                                {as.title}
                                            </Link>
                                        </td>
                                        <td>
                                            <div style={{marginLeft: 'auto'}}>
                                                <CustomSelect
                                                    style={{ width: "120px", marginLeft: 'auto' }}
                                                    id=""
                                                    label={t("Action")}
                                                    options={[
                                                        { value: "edit", label: "ჩასწორება" },
                                                        { value: "delete", label: "წაშლა" },
                                                    ]}
                                                    value={""}
                                                    onChange={async (action) => {
                                                        if (action === "edit") {
                                                            navigate(`/admin/topic-edit/${as.id}`);
                                                        } else {
                                                            if (window.confirm("ნამდვილად გსურთ ტოპიკის წაშლა ?")) {
                                                                const result = await deleteTopic(as.id).unwrap();
                                                                // console.log(result);
                                                                refetchTopics();
                                                                // alert(result.status);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                       
                    </div>
                </div >
            )}
        </>
    );
};

export default Admin_topics_list;
