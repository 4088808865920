import React from "react";
import styled from "styled-components";
import Colors from "../assets/Colors";
import Fonts from "../assets/Fonts";



const Textarea: React.FC<{
    name?: string;
    id?: string;
    className?: string;
    placeholder?: string;
    rows?: number;
    cols?: number;
    value?: string;
    onChange: (value: string) => void;

}> = ({
    name,
    id,
    className,
    placeholder,
    rows,
    cols,
    value,
    onChange
}) => {



        return (
            <TextArea
                id={id}
                name={name}
                className={className}
                placeholder={placeholder}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                rows={rows}
                cols={cols}
            ></TextArea>
        )
    }


const TextArea = styled.textarea`
    padding: 10px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid ${Colors.Geyser};
    color: ${Colors.Trout};
    height: 150px;
    width: 100%;
    line-height: 1.5;
    font-weight: 400;
    background-color: ${Colors.White};
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: ${Fonts.FiraGoRegular};
`

export default Textarea