import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import api, { uploadUrl } from '../api';
import Responsive from '../assets/Responsive';
import Input from '../components/Input';
import Loader from '../components/Loader';
import NotFound from '../components/NotFound';
import TopBanner from '../components/TopBanner';
import SearchImg from '../images/search-icon.svg';
import LocationImg from '../images/maps-line-icon.svg';
import CustomSelect from '../components/CustomSelect';
import SubmitButton from '../components/Button';
import TourCard from '../components/TourCard';
import { BsFilter } from 'react-icons/bs';
import Fonts from '../assets/Fonts';
import { Destination } from '../domain/types';



const GeorgianTopDestinations: React.FC<{}> = ({ }) => {

    const [place, setPlace] = useState('');
    const [where, setWhere] = useState('');
    const [category, setCategory] = useState('');
    // 
    const { slug = 'georgia-top-destinations' } = useParams();
    const { t } = useTranslation();
    // 
    const { data: georgianDestinations = [], isFetching: isGeorgianDestinationsLoading, refetch: refetchGeorgianDestinations } = api.useGetGeorgianDestinationsQuery(undefined);
    const { data: page, isFetching: isPageLoading, refetch: refetchPage } = api.useGetPageQuery(slug);


    // search field
    const [searchField, setSearchField] = useState<string>("");

    // filters
    const search = (page: Destination[]) => {
        return page.filter((item) =>
            item.city?.toLocaleLowerCase().includes(searchField) ||
            item.title?.toLocaleLowerCase().includes(searchField) ||
            item.country?.toLocaleLowerCase().includes(searchField)
        )
    }
    // filters



    return (
        <Wrapper>
            {isPageLoading ? <Loader /> :
                !page ? (
                    <NotFound />
                ) : (
                    <TopBanner
                        title={page.title}
                        bannerBackImg={uploadUrl(page.cover)}
                    />
                )
            }

            <div className="container margin_60_35">
                <div className="col-lg-12">
                    <FormWrapper className="row g-0 custom-search-input-2 inner my-0">
                        <Distance className="col-12">
                            <SearchWrapperStlye className="form-group">
                                <Input
                                    type="text"
                                    className=""
                                    placeholder={t("WhatAreYouLookingFor")}
                                    id=''
                                    label={t("searchFor")}
                                    required={false}
                                    error={false}
                                    value={searchField}
                                    onChange={(value) => setSearchField(value)}
                                />
                                <FilterIconStyle />
                            </SearchWrapperStlye>
                        </Distance>
                        {/* <Distance className="col-lg-3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className=""
                                    placeholder={t("searchWhere")}
                                    id=''
                                    label={t("searchWhere")}
                                    required={false}
                                    error={false}
                                    value={where}
                                    onChange={(value) => setWhere(value)}
                                />
                                <IconImg className="icon_pin_alt" src={LocationImg} alt="" />
                            </div>
                        </Distance>
                        <Distance className="col-lg-3">
                            <select className="wide">
                                <option>All Categories</option>
                                <option>Churches</option>
                                <option>Historic</option>
                                <option>Museums</option>
                                <option>Walking tours</option>
                            </select>
                            <CustomSelect
                                id=''
                                label={t("allCategoryInput")}
                                options={[
                                    { value: '1', label: "ყველა კატეგორია" },
                                    { value: '2', label: "ეკლესიები" },
                                    { value: '3', label: "ისტორიული" },
                                    { value: '4', label: "მუზეუმები" },
                                    { value: '5', label: "გასეირნება" },
                                ]}
                                value={category}
                                onChange={(value) => setCategory(value)}
                            />
                        </Distance>
                        <Distance className="col-lg-2">
                            <SubmitButton
                                className="btn_search"
                                value=""
                                onClick={() => alert('Button 1 is clicked !')}
                                circle={false}
                                title={t("allSearchButton")}
                                widthFluid={true}
                                heightFluid={true}
                            />
                        </Distance> */}
                    </FormWrapper>
                    {/* /row */}
                </div>
                {/* /custom-search-input-2 */}
                {isGeorgianDestinationsLoading === true ? <Loader /> : (
                    <div className="isotope-wrapper row">
                        {search(georgianDestinations).map((d) => (
                            <div key={d.id} className="col-lg-4 mt-4">
                                <TourCard
                                    topic={d.city}
                                    link={'/destination/' + d.slug}
                                    tourId={d.id}
                                    readMore={t("allSeeAllButton")}
                                    img={uploadUrl(d.poster)}
                                    title={d.title}
                                    description={d.shortDescription}
                                />
                            </div>
                        ))}
                        {search(georgianDestinations).length < 1 ? (<SearchNotFount> {t("NotFound")}</SearchNotFount>) : null}
                    </div>
                )}


                {/* /isotope-wrapper */}

                {/* <p className="text-center add_top_30"><a href="#0" className="btn_1 rounded">{t("allLoadMoreButton")}</a></p> */}

            </div>


        </Wrapper>
    )
}


const SearchNotFount = styled.div`
	font-size: 20px;
	font-family: ${Fonts.FiraGoRegular};
	min-height: 50vh;
	width: 80%;
	margin: 0 auto;
`;
const SearchWrapperStlye = styled.div`
	width: 80%;
	margin: 0 auto 30px auto !important;
`;
const FilterIconStyle = styled(BsFilter)`
	border-radius: 3px;
    font-size: 22px;
    position: absolute;
    line-height: 50px;
    top: 16px;
    right: 1px;
    padding-right: 15px;
    display: block;
    box-sizing: content-box;
    z-index: 9;
    color: #999;
`;
const Wrapper = styled.div`

`;
const IconImg = styled.img`
	border-radius: 3px;
    font-size: 18px;
    font-size: 1.125rem;
    position: absolute;
    background-color: #fff;
    line-height: 50px;
    top: 15px;
    right: 1px;
    padding-right: 15px;
    display: block;
    width: 15px;
    box-sizing: content-box;
    z-index: 9;
    color: #999;
	
`
const FormWrapper = styled.div`
	background-color: transparent !important;
	-moz-box-shadow: none !important;
	-goog-ms-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
		input{
			height: unset;
			border-right: unset;
		}
	
`
const Distance = styled.div`
	padding: 0px 5px;
		&:first-child {
			padding-left: 0px;
		}
		&:last-child {
			padding-right: 0px;
		}
	${Responsive.tablet} {
		margin-bottom: 10px;
		&:first-child {
			padding-left: 5px;
		}
		&:last-child {
			padding-right: 5px;
		}
	}
	${Responsive.mobile} {
		padding: 0px;
		margin-bottom: 10px;
	}
`

export default GeorgianTopDestinations