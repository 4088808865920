import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../components/Button";
import Input from "../components/Input";
import TopBanner from "../components/TopBanner";
import { api, uploadUrl } from "../api";
import styled from "styled-components";
import Responsive from "../assets/Responsive";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";
import Loader from "../components/Loader";
import NotFound from "../components/NotFound";
import LightBox from "../components/LightBox";
import CustomSelect from "../components/CustomSelect";

import SimpleReactLightbox from "simple-react-lightbox";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import RichText from "../components/RichText";

const TourDetails: React.FC = () => {
  const { slug = "" } = useParams();

  // export data
  const {
    data: tourDetailed,
    isFetching: isTourDetailedLoading,
    refetch: refetchTourDetailed,
  } = api.useGetTourDetailedQuery(slug);

  const [tel, setTel] = useState("");
  const [mail, setMail] = useState("");
  const navigate = useNavigate();

  const { t } = useTranslation();

  // outgoing tour form
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [passenger, setPassenger] = useState("");
  const [date, setDate] = useState("");
  const [citizen, setCitizen] = useState("");
  const [city, setCity] = useState("");
  const [textAreaMsg, setTextAreaMsg] = useState<string>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [submitForm, setSubmitForm] = useState("");
  const [disableButton, setDisableButton] = useState(false);
	const [fullPassenger, setFullPassenger] = useState('');
	const [teenagePassenger, setTeenagePassenger] = useState('');
  const [disableGetThePrice, setDisableGetThePrice] = useState(false);

  const [Form] = api.usePostFormMutation();

  // send  form
  const travelAboardForm = async (e: any) => {
    e.preventDefault();
    try {
      await Form({
        fullName: name,
        phone: number,
        userEmail: mail,
        topicId: 6,
        type: "travel-aboard-form",
        citizenship: citizen,
        destination: city,
        description: textAreaMsg,
        fullPassenger: fullPassenger,
				teenagePassenger: teenagePassenger,
        startDate: startDate,
        endDate: endDate,
      });
      setSubmitForm("ინფორმაცია წარმატებით გაიგზავნა");
      console.log("contact form submit");
      setDisableButton(true);
    } catch (error) {
      setSubmitForm("გთხოვთ სცადოთ თავიდან ");
      setDisableButton(false);
      console.log("login error", error);
    }
  };
  // send  form

  const emailRegex = /^\S+@\S+\.\S+$/;
  // send get the price form
  const GetThePriceForm = async (e: any) => {
    e.preventDefault();
    if (emailRegex.test(mail) && tel !== "") {
      try {
        await Form({
          phone: tel,
          userEmail: mail,
          topicId: 6,
          type: "get-the-price-form",
        }).unwrap();
        setDisableGetThePrice(true);
        alert("მოთხოვნა წარმატებით გაიგზავნა");
      } catch (err: any) {
        toast.error(err.data.errors[0].message);
        setDisableGetThePrice(false);
      }
    } else {
      if (tel === "") {
        toast.error("გთხოვთ მიუთითოთ სწორი ნომერი");
      }
      if (!emailRegex.test(mail)) {
        toast.error("გთხოვთ მიუთითოთ სწორი მეილი");
      }
    }
  };
  // send get the price form

  return (
    <div>
      {isTourDetailedLoading ? (
        <Loader />
      ) : !tourDetailed ? (
        <NotFound />
      ) : (
        <div>
          <TopBanner bannerBackImg={uploadUrl(tourDetailed.cover)} title={tourDetailed.title} />
          {}
          {/* {tourDetailed.map((t) => ( */}
          <div className="bg_color_1">
            <div className="container margin_60_35">
              <div className="row">
                <div className="col-lg-7">
                  <section id="description" style={{ border: "unset" }}>
                    {/* <TittleH2>{t("shortDescription")}</TittleH2>
												<Paragraph>
													{tourDetailed.shortDescription}
												</Paragraph> */}
                    {/* <p>Cum et probo menandri. Officiis consulatu pro et, ne sea sale invidunt, sed ut sint <strong>blandit</strong> efficiendi. Atomorum explicari eu qui, est enim quaerendum te. Quo harum viris id. Per ne quando dolore evertitur, pro ad cibo commune.</p> */}
                    {tourDetailed.gallery?.length < 1 ? null : <TittleH3>{t("PicturesFromOurUsers")}</TittleH3>}

                    {console.log(tourDetailed.gallery?.length)}
                    <div style={{ marginTop: "15px" }}>
                      {tourDetailed.gallery?.length < 1 ? null : (
                        <SimpleReactLightbox>
                          <LightBox images={tourDetailed.gallery} />
                        </SimpleReactLightbox>
                      )}
                    </div>
                    <hr />
                    <TittleH2>{t("description")}</TittleH2>
                    <Paragraph>
                      <RichText html={tourDetailed.description} />
                      {/* <div dangerouslySetInnerHTML={{__html : tourDetailed.description}}></div> */}
                    </Paragraph>
                    {/* <h3>Program <small>(60 minutes)</small></h3> */}
                    {/* <p>
													Iudico omnesque vis at, ius an laboramus adversarium. An eirmod doctus admodum est, vero numquam et mel, an duo modo error. No affert timeam mea, legimus ceteros his in. Aperiri honestatis sit at. Eos aeque fuisset ei, case denique eam ne. Augue invidunt has ad, ullum debitis mea ei, ne aliquip dignissim nec.
												</p> */}
                    {/* <hr /> */}
                    {/* <p>Mea appareat omittantur eloquentiam ad, nam ei quas <strong>oportere democritum</strong>. Prima causae admodum id est, ei timeam inimicus sed. Sit an meis aliquam, cetero inermis vel ut. An sit illum euismod facilisis, tamquam vulputate pertinacia eum at.</p> */}
                    {/* <div className="row">
													<div className="col-lg-6">
														<ul className="bullets">
															<li>Dolorem mediocritatem</li>
															<li>Mea appareat</li>
															<li>Prima causae</li>
															<li>Singulis indoctum</li>
														</ul>
													</div>
													<div className="col-lg-6">
														<ul className="bullets">
															<li>Timeam inimicus</li>
															<li>Oportere democritum</li>
															<li>Cetero inermis</li>
															<li>Pertinacia eum</li>
														</ul>
													</div>
												</div> */}
                  </section>
                  <hr />
                </div>
                <aside className="col-lg-5" id="sidebar">
                  <div className="add-review">
                    <h5>{t("checkPriceTitle")}</h5>
                    <form>
                      <div className="row">
                        <div className="form-group col-md-6 mt-3 mt-lg-1">
                          <Input
                            id="name_review"
                            type="text"
                            placeholder={t("allFormPhone")}
                            value={tel}
                            error={false}
                            required={false}
                            className="form-control"
                            onChange={(value) => setTel(value)}
                            label={t("allFormPhone")}
                          />
                        </div>
                        <div className="form-group col-md-6 mt-3 mt-lg-1">
                          <Input
                            id="email_review"
                            type="text"
                            placeholder={t("allFormPhone")}
                            value={mail}
                            error={false}
                            required={false}
                            className="form-control"
                            onChange={(value) => setMail(value)}
                            label={t("allFormEmail")}
                          />
                        </div>

                        <div className="form-group col-md-12 add_top_20">
                          <GetPriceButton disabled={disableGetThePrice} onClick={GetThePriceForm}>
                            {t("demand")}
                          </GetPriceButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </aside>
              </div>
              {/* /row */}
              {tourDetailed.type == "outgoing" ? (
                <div className="row">
                  <InpuTWrapperDiv>
                    <FormInputWrapper className="form-group col-md-6">
                      <Input
                        id="fullname_review"
                        label={t("allFormFullName")}
                        className="form-control"
                        type="text"
                        placeholder={t("allFormFullNameExample")}
                        value={name}
                        required={false}
                        error={false}
                        onChange={(value) => setName(value)}
                      />
                    </FormInputWrapper>
                    <FormInputWrapper className="form-group col-md-6">
                      <Input
                        id="number_review"
                        label={t("allFormPhone")}
                        className="form-control"
                        type="number"
                        placeholder={t("allFormPhoneExample")}
                        value={number}
                        required={false}
                        error={false}
                        onChange={(value) => setNumber(value)}
                      />
                    </FormInputWrapper>
                    <FormInputWrapper className="form-group col-md-6">
                      <Input
                        id="email_review"
                        label={t("allFormEmail")}
                        className="form-control"
                        type="email"
                        placeholder={t("allFormEmailExample")}
                        value={mail}
                        required={false}
                        error={false}
                        onChange={(value) => setMail(value)}
                      />
                    </FormInputWrapper>
                    <FormInputWrapper className="form-group col-md-6">
                      {/* <FormInputTittle >თარიღი *</FormInputTittle> */}
                      <Input
                        id="date_review"
                        label={t("dateOfDeparture") + " " + startDate}
                        className="form-control"
                        type="date"
                        placeholder=""
                        value={startDate}
                        required={false}
                        error={false}
                        onChange={(value) => setStartDate(value)}
                      />
                    </FormInputWrapper>
                    <FormInputWrapper className="form-group col-md-6">
                      <Input
                        id="citizenship_review"
                        label={t("allFormCitizenshipInput")}
                        className="form-control"
                        type="text"
                        placeholder={t("allFormCitizenshipExample")}
                        value={citizen}
                        required={false}
                        error={false}
                        onChange={(value) => setCitizen(value)}
                      />
                    </FormInputWrapper>
                    <FormInputWrapper className="form-group col-md-6">
                      <Input
                        id="city_review"
                        label={t("allFormCityInput")}
                        className="form-control"
                        type="text"
                        placeholder={t("allFormCityExample")}
                        value={city}
                        required={false}
                        error={false}
                        onChange={(value) => setCity(value)}
                      />
                    </FormInputWrapper>
                    <FormInputWrapper className="form-group col-md-6">
                      <CustomSelect
                        id="direction_review"
                        label={t("allFormSelectDirection")}
                        options={[
                          {
                            value: t("emirates"),
                            label: t("emirates"),
                          },
                          {
                            value: t("sriLanka"),
                            label: t("sriLanka"),
                          },
                          {
                            value: t("thailand"),
                            label: t("thailand"),
                          },
                          {
                            value: t("tanzania"),
                            label: t("tanzania"),
                          },
                        ]}
                        value={country}
                        onChange={(value) => setCountry(value)}
                      />
                    </FormInputWrapper>
                  </InpuTWrapperDiv>
                  <SubmitFormBtn onClick={travelAboardForm}>send</SubmitFormBtn>
                </div>
              ) : null}
            </div>
            {/* /container */}
          </div>
        </div>
      )}
      <ToastContainer position="bottom-center" />
    </div>
  );
};

const GetPriceButton = styled.button`
  border: none;
  background-color: ${Colors.Carnation};
  outline: none;
  text-decoration: none;
  padding: 15px 30px;
  color: ${Colors.White};
  font-weight: 600;
  text-align: center;
  margin-top: unset;
  line-height: 1;
  transition: all 0.3 ease-in-out;
  border-radius: 0px;
  font-family: ${Fonts.FiraGoBold};
`;
const SubmitFormBtn = styled.button`
  border: none;
  background-color: ${Colors.Carnation};
  outline: none;
  text-decoration: none;
  padding: 15px 50px;
  color: ${Colors.White};
  font-weight: 600;
  text-align: center;
  margin-top: unset;
  line-height: 1;
  transition: all 0.3 ease-in-out;
  border-radius: 25px;
  font-family: ${Fonts.FiraGoBold};
  width: fit-content;
  margin: 0 auto;
  font-size: 16px;
  &:hover {
    opacity: 0.9;
  }
`;
const InpuTWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-top: 20px;
  margin-bottom: 30px;

  ${Responsive.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-bottom: 20px;
  }
`;

const FormInputWrapper = styled.div`
  width: 100%;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-row-gap: 15px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  overflow: hidden;
  /* mobile resolution gallery popup dont show more than 2 elements and give second element count background */
  ${Responsive.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    &:nth-child(1) {
      div:nth-child(n + 3) {
        display: none;
      }
      div:nth-child(2) {
        button {
          div {
            display: flex;
          }
        }
      }
    }
  }
  /* desktop resolution gallery popup dont show more than 4 elements and give number 4 element count background */
  &:nth-child(1) {
    div:nth-child(n + 5) {
      display: none;
    }
    div:nth-child(4) {
      button {
        div {
          display: flex;
        }
      }
    }
  }
`;
const TittleH2 = styled.h2`
  font-family: ${Fonts.FiraGoBold};
  color: ${Colors.Black};
`;
const TittleH3 = styled.h3`
  font-family: ${Fonts.FiraGoBold};
  color: ${Colors.Black};
`;
const Paragraph = styled.p`
  font-family: ${Fonts.FiraGoRegular};
`;
// const GalleryButton = styled.button`
// 	position: relative;
// 	border: 0;
// 	outline: 0;
// 	height: inherit;
// 	width: inherit;
// 	padding: 0 ;
// `
// const GalleryWrapper = styled.div`
// 	height: 120px;
// 	width: 100%;
// 	${Responsive.mobile}{
// 		height: unset;
// 		width: unset;
// 		min-height: 93px;
// 		max-height: 200px;
// 	}
// `
// const GalleryPopupCounter = styled.div`
// 	display: none;
// 	top: 0;
// 	left: 0;
// 	transition: .5s;
// 	flex-grow: 1;
// `
// const GalleryCounterOpacity = styled.div`
// 	background-color: black;
// 	opacity: 0.5;
// 	height: inherit;
// 	width: inherit;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	justify-content: center;
// 	align-items: center;
// 	height: 100%;
// 	width: 100%;
// `
// const GalleryCounterText = styled.div`
// 	position: absolute;
// 	color: white;
// 	top: 0;
// 	left: 0;
// 	height: 100%;
// 	width: 100%;
// 	justify-content: center;
// 	align-items: center;
// `

export default TourDetails;
