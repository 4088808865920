import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from '../assets/Colors';
import Fonts from '../assets/Fonts';
import CustomSelect from '../components/CustomSelect';
import Input from '../components/Input';
import SubmitButton from '../components/Button';
import { useTranslation } from 'react-i18next';


export const RequestCallbackForm: React.FC<{
    showFullNameInput?: boolean;
    showCitizenshipInput?: boolean;
    showNumberInput?: boolean;
    showEmailInput?: boolean;
    showDirectionSelector?: boolean;
    showDateInput?: boolean;
    showNumberOfPassengersSelector?: boolean;
    showCityInput?: boolean;
}> = ({
    showFullNameInput,
    showCitizenshipInput,
    showNumberInput,
    showEmailInput,
    showDirectionSelector,
    showDateInput,
    showNumberOfPassengersSelector,
    showCityInput,
}) => {

        // translation import
        const { t } = useTranslation();
        // input ustates
        const [passenger, setPassenger] = useState('');
        const [country, setCountry] = useState('')
        const [name, setName] = useState('')
        const [number, setNumber] = useState('')
        const [mail, setMail] = useState('')
        const [date, setDate] = useState('')
        const [city, setCity] = useState('')
        const [citizen, setCitizen] = useState('')
        
        return (
            <FormWrapper className="add-review">
                <FormTittle className='FormTittle'>Form</FormTittle>
                <form>
                    <div className="row">
                        {showFullNameInput ?
                            <FormInputWrapper className="form-group col-md-6">
                                <Input
                                    id='fullname_review'
                                    label={t("allFormFullName")}
                                    className="form-control"
                                    type="text"
                                    placeholder={t("allFormFullNameExample")}
                                    value={name}
                                    required={false}
                                    error={false}
                                    onChange={(value) => setName(value)}

                                />
                            </FormInputWrapper>
                            : null}
                        {showCitizenshipInput ?
                            <FormInputWrapper className="form-group col-md-6">
                                {/* <FormInputTittle>Citizenship *</FormInputTittle> */}
                                {/* <input type="text" name="citizenship_review" id="citizenship_review" placeholder="" className="form-control" /> */}
                                <Input
                                    id="citizenship_review"
                                    label={t("allFormCitizenshipInput")}
                                    className="form-control"
                                    type="text"
                                    placeholder={t("allFormCitizenshipExample")}
                                    value={citizen}
                                    required={false}
                                    error={false}
                                    onChange={(value) => setCitizen(value)}

                                />
                            </FormInputWrapper>
                            : null}
                        {showNumberInput ?
                            <FormInputWrapper className="form-group col-md-6">
                                <Input
                                    id="number_review"
                                    label={t("allFormPhone")}
                                    className="form-control"
                                    type="number"
                                    placeholder={t("allFormPhoneExample")}
                                    value={number}
                                    required={false}
                                    error={false}
                                    onChange={(value) => setNumber(value)}

                                />
                            </FormInputWrapper>
                            : null}
                        {showEmailInput ?
                            <FormInputWrapper className="form-group col-md-6">
                                <Input
                                    id="email_review"
                                    label={t("allFormEmail")}
                                    className="form-control"
                                    type="email"
                                    placeholder={t("allFormEmailExample")}
                                    value={mail}
                                    required={false}
                                    error={false}
                                    onChange={(value) => setMail(value)}

                                />
                            </FormInputWrapper>
                            : null}
                        {showDirectionSelector ?
                            <FormInputWrapper className="form-group col-md-6">
                                <CustomSelect
                                    id='direction_review'
                                    label={t("allFormSelectDirection")}
                                    options={[
                                        { value: '1', label: "უკრაინა" },
                                        { value: '2', label: "აფრიკა" },
                                        { value: '3', label: "ნორვეგია" },
                                        { value: '4', label: "კალიფორნია" },
                                        { value: '5', label: "მარტვილი" },
                                    ]}
                                    value={country}
                                    onChange={(value) => setCountry(value)}
                                />
                            </FormInputWrapper>
                            : null}
                        {showCityInput ?
                            <FormInputWrapper className="form-group col-md-6">
                            <Input
                                id="city_review"
                                label={t("allFormCityInput")}
                                className="form-control"
                                type="text"
                                placeholder={t("allFormCityExample")}
                                value={city}
                                required={false}
                                error={false}
                                onChange={(value) => setCity(value)}

                            />
                        </FormInputWrapper>
                            : null}
                        {showNumberOfPassengersSelector ?
                            <FormInputWrapper className="form-group col-md-6">
                                <CustomSelect
                                    id=''
                                    label={t("allFormPassengerSelect")}
                                    options={[
                                        { value: '1', label: "1 მგზავრი" },
                                        { value: '2', label: "2 მგზავრი" },
                                        { value: '3', label: "3 მგზავრი" },
                                        { value: '4', label: "4 მგზავრი" },
                                        { value: '5', label: "5 მგზავრი" },
                                        { value: '6', label: "6 მგზავრი" },
                                        { value: '7', label: "7 მგზავრი" },
                                        { value: '8', label: "8 მგზავრი" },
                                        { value: '9', label: "9 მგზავრი" },
                                        { value: '10', label: "10 მგზავრი" },
                                    ]}
                                    value={passenger}
                                    onChange={(value) => setPassenger(value)}
                                />
                            </FormInputWrapper>
                            : null}
                        {showDateInput ?
                            <FormInputWrapper className="form-group col-md-6">
                                {/* <FormInputTittle >თარიღი *</FormInputTittle> */}
                                <Input
                                    id="date_review"
                                    label=''
                                    className="form-control"
                                    type="date"
                                    placeholder=''
                                    value={date}
                                    required={false}
                                    error={false}
                                    onChange={(value) => setDate(value)}

                                />
                            </FormInputWrapper>
                            : null}
                        <div className="form-group col-md-12 add_top_20">
                            <SubmitButton
                                id="submit-review"
                                className="btn_1"
                                value={''}
                                onClick={()=> alert('Button 1 is clicked !')}
                                circle={true}
                                title = {t("allFormSendButton")}
                            />
                        </div>
                    </div>
                </form>
            </FormWrapper>
        )
    }

export default RequestCallbackForm

const FormTittle = styled.h5`
    font-family: ${Fonts.Roboto};
    font-size: 22px;
    color: ${Colors.Black};
    padding-bottom: 10px;
`;
const FormInputTittle = styled.label`
    font-family: ${Fonts.Roboto};
    font-size: 14px;
    color: ${Colors.Gray};
`;
const FormInputWrapper = styled.div`
    margin-top: 21px;
`;
const FormWrapper = styled.div`
  margin-top: 10px;
  padding-bottom: 25px;
`;


