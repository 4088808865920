import styled from 'styled-components';
import React from 'react'
import Fonts from "../assets/Fonts";
import Responsive from "../assets/Responsive"

const Banner: React.FC<{
    smallTitle?: string;
    mainTitle?: string;
    brMainTitle?: string;
    paragraph?: string;
    link?: string;
    linkText?: string;
    bannerBackground?: string;
}> = ({
    smallTitle,
    mainTitle,
    brMainTitle,
    paragraph,
    link,
    linkText,
    bannerBackground
}) => {

        const text = ""

        return (
            <div className="banner mb-0" style={{ backgroundImage: "url(" + bannerBackground + ")" }}>
                <Shadow />
                <div className="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                    <div>
                        <SmallTitle>{smallTitle}</SmallTitle>
                        {mainTitle && mainTitle.length >= 1 ? (
                            <MainTitle>{mainTitle}<br />{brMainTitle}</MainTitle>
                        ) : null}
                        <p>{paragraph}</p>
                        <a href={link} className="btn_1">{linkText}</a>
                    </div>
                </div>
            </div>
        )
    }

const MainTitle = styled.h3`
    font-family: ${Fonts.FiraGoBold};
    margin-top: 20px !important;
`

const SmallTitle = styled.p`
    font-family: ${Fonts.FiraGoRegular};
    font-size: 17px !important;
    width: 350px;
    margin-bottom: 0;
    ${Responsive.mobile}{
        width: 200px;
    }
`
const Shadow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    user-select: none;
`;


export default Banner