import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import styled from "styled-components";
import api, { uploadUrl } from "../../../api";
import Input from "../../../components/Input";
import LightBox from "../../../components/LightBox";
import Loader from "../../../components/Loader";
import NotFound from "../../../components/NotFound";
import Textarea from "../../../components/Textarea";
import {
    EnabledLanguage,
    supportedLocales,
    Page,
    PageTranslatedFields,
    InfoBlock,
} from "../../../domain/types";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";

// MUI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Fonts from "../../../assets/Fonts";
import { Editor } from "@tinymce/tinymce-react";
//
import { FaCloudUploadAlt } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import MultipleImageUpload from "../../Admin_components/MultipleImageUpload";
import InfoBlocksEditor from "../../Admin_components/InfoBlocksEditor";

// 
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FormInputsArray = [
    "fullName",
    "endDate",
    "city",
    "destination",
    "fullPassenger",
    "teenagePassenger",
    "description",
];


const Admin_page_edit = () => {
    const { pageId: pageIdStr = "0" } = useParams();
    const pageId = parseInt(pageIdStr);

    // export data
    const {
        data: existingPage,
        isFetching: isExistingPageLoading,
        refetch: refetchExistingPage,
    } = api.useGetPageByIdQuery(pageId);
    const {
        data: getTopics,
        isFetching: isGetTopicsLoading,
        refetch: refetchGetTopics,
    } = api.useGetTopicsQuery(undefined);
    // 
    const [editedPage, setEditingPage] = useState<Partial<Page>>({});
    // example: setpageField('slug', 'NEW_CHANGED_SLUG')
    const setPageField = (field: keyof Page, value: any) => {
        setEditingPage({
            ...editedPage,
            [field]: value,
        });
    };
    /** example:
     * setTranslatedField("city", "ge", value)
     */
    const setTranslatedField = (
        field: keyof PageTranslatedFields,
        language: EnabledLanguage,
        value: any
    ) => {
        setEditingPage({
            ...editedPage,
            translations: {
                ...editedPage.translations,
                [language]: {
                    ...editedPage?.translations?.[language],
                    [field]: value,
                },
            },
        });
    };


    const descriptionRef = useRef<any>(null);
    const shortDescriptionRef = useRef<any>(null);

    const [language, setLanguage] = useState<EnabledLanguage>("ge");

    useEffect(() => {
        if (existingPage) {
            // copy loaded page from backend to draft useState page
            setEditingPage(existingPage);
            if (existingPage.formInputs !== null) {
                setFormInputsName(existingPage.formInputs.split(', '))
            }
        }
    }, [existingPage]);

    const [savePage, { isLoading: isSavePageLoading, error: savepageError }] =
        api.useSavePageMutation();

    const [
        uploadFile,
        { isLoading: isUploadFileLoading, error: uploadFileError },
    ] = api.useAdminUploadFileMutation();

    const [previewCoverPhotoTmpUrl, setPreviewCoverPhotoTmpUrl] = useState("");

    const { t } = useTranslation();

    const isLoading = isExistingPageLoading || isSavePageLoading || isGetTopicsLoading;

    // setFormInputsName
    //   
    const [formInputsName, setFormInputsName] = React.useState<string[]>([]);
    const handleChange = (event: SelectChangeEvent<typeof formInputsName>) => {
        const {
            target: { value },
        } = event;
        setFormInputsName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split('') : value,
        );
        setPageField("formInputs", formInputsName.join(', ').toString())
    };
    // 
    // 
    const [selectedTopic, setSelectedTopic] = useState<number | string>();

console.log(selectedTopic)

    return isLoading ? (
        <Loader />
    ) : pageId > 0 && !existingPage ? (
        <NotFound />
    ) : (
        <div className="d-flex flex-column flex-lg-row">
            <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                <div className="card">
                    <div className="card-body p-12">
                        <div id="kt_invoice_form">
                            <div
                                className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4"
                                data-bs-toggle="tooltip"
                                data-bs-trigger="hover"
                                title="Enter invoice number"
                            ></div>
                            <div className="mb-0">
                                <div className="row gx-10 mb-5">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            {/* page Title here */}
                                            <div className="mb-3 col-lg-6">
                                                <Input
                                                    id=""
                                                    label={t("title")}
                                                    placeholder={t("title")}
                                                    type="text"
                                                    // instead city there should be country from data
                                                    value={
                                                        editedPage?.translations?.[language]?.title || ""
                                                    }
                                                    required={false}
                                                    error={false}
                                                    helperText=""
                                                    onChange={(value) =>
                                                        setTranslatedField("title", language, value)
                                                    }
                                                />
                                            </div>
                                            {/* page Slug here */}
                                            <div className="mb-4 col-lg-6">
                                                <Input
                                                    id=""
                                                    label={t("slug")}
                                                    placeholder={t("slug") + "..."}
                                                    type="text"
                                                    value={editedPage.slug || ""}
                                                    required={false}
                                                    error={false}
                                                    helperText=""
                                                    onChange={(value) => setPageField("slug", value)}
                                                />
                                            </div>
                                            {/* page inputs here */}
                                            {/* {editedPage.formInputs ? ( */}
                                                <div className="mb-4 col-lg-6">
                                                    {/* {editedPage && editedPage.formInputs ? ( */}
                                                        <FormControl sx={{}} fullWidth>
                                                            <InputLabel id="demo-multiple-checkbox-label">მიმდინარე გვერდის შესავსები ველები</InputLabel>
                                                            <Select
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                value={formInputsName}
                                                                onChange={handleChange}
                                                                input={<OutlinedInput label="მიმდინარე გვერდის შესავსები ველები" />}
                                                                renderValue={(selected) => selected.join(' | ')}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {FormInputsArray.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        <Checkbox checked={formInputsName.indexOf(name) > -1} />
                                                                        <ListItemText primary={name} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    {/* ) : null} */}
                                                </div>
                                            {/* ) : null} */}
                                            <div className="mb-4 col-lg-6">
                                                <BoxStyle>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            აირჩიეთ თუ ვის მოუვიდეს შეტყობინება
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={editedPage.sentTo || ""}
                                                            label={'აირჩიეთ თუ ვის მოუვიდეს შეტყობინება'}
                                                            onChange={(event) => setPageField('sentTo', event.target.value)}
                                                            >
                                                            {getTopics?.map((topic, index) => (
                                                                <MenuItemStyle key={index} value={topic.id}>{topic.email}</MenuItemStyle>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </BoxStyle>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                        {editedPage.shortDescription ? (
                                            <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">
                                                {t("shortDescription")}
                                            </label>
                                        ) : null}
                                        {/* page Short description here */}
                                        {editedPage.shortDescription ? (
                                            <div className="mb-5">
                                                <Editor
                                                    onInit={(evt: any, editor: any) =>
                                                        (shortDescriptionRef.current = editor)
                                                    }
                                                    initialValue={
                                                        editedPage.translations?.[language]?.shortDescription
                                                    }
                                                    init={{
                                                        height: 500,
                                                        menubar: false,
                                                        plugins: [
                                                            "advlist autolink lists link image charmap print preview anchor",
                                                            "searchreplace visualblocks code fullscreen",
                                                            "insertdatetime media table paste code help wordcount",
                                                        ],
                                                        toolbar:
                                                            "undo redo | formatselect | " +
                                                            "bold italic backcolor | alignleft aligncenter " +
                                                            "alignright alignjustify | bullist numlist outdent indent | " +
                                                            "removeformat | help",
                                                        content_style:
                                                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                    }}
                                                    onChange={() => {
                                                        if (shortDescriptionRef.current) {
                                                            setTranslatedField(
                                                                "shortDescription",
                                                                language,
                                                                shortDescriptionRef.current.getContent()
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">
                                            {t("description")}
                                        </label>
                                        {/* Page Description here */}

                                        <div className="mb-5">
                                            <Editor
                                                onInit={(evt: any, editor: any) =>
                                                    (descriptionRef.current = editor)
                                                }
                                                initialValue={
                                                    editedPage.translations?.[language]?.description
                                                }
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        "advlist autolink lists link image charmap print preview anchor",
                                                        "searchreplace visualblocks code fullscreen",
                                                        "insertdatetime media table paste code help wordcount",
                                                    ],
                                                    toolbar:
                                                        "undo redo | formatselect | " +
                                                        "bold italic backcolor | alignleft aligncenter " +
                                                        "alignright alignjustify | bullist numlist outdent indent | " +
                                                        "removeformat | help",
                                                    content_style:
                                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                }}
                                                onChange={() => {
                                                    if (descriptionRef.current) {
                                                        setTranslatedField(
                                                            "description",
                                                            language,
                                                            descriptionRef.current.getContent()
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                        {/* BEGIN Upload Cover Photo */}
                                        <UploadWrapper>
                                            <TitleLabel className="form-label fs-6 fw-bolder text-gray-700 mb-3">
                                                ქოვერის ფოტო
                                            </TitleLabel>
                                            <div>
                                                {previewCoverPhotoTmpUrl || editedPage.cover ? (
                                                    <PrevViewImg
                                                        src={previewCoverPhotoTmpUrl || uploadUrl(editedPage.cover!)}
                                                    />
                                                ) : null}
                                            </div>
                                            <UploadContent>
                                                <UploadFile>
                                                    <UploadFileIcon />
                                                    <UploadInput
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={async (event) => {
                                                            const files = event.target.files;
                                                            if (!files || !files.length) {
                                                                return;
                                                            }
                                                            try {
                                                                /** for now only single file upload */
                                                                const file = files[0];
                                                                // preview photo before upload
                                                                setPreviewCoverPhotoTmpUrl(
                                                                    URL.createObjectURL(file)
                                                                );
                                                                const uploadResult = await uploadFile({
                                                                    file,
                                                                }).unwrap();
                                                                if (uploadResult.path) {
                                                                    setPageField("cover", uploadResult.path);
                                                                    // preview photo AFTER upload
                                                                    setPreviewCoverPhotoTmpUrl(
                                                                        uploadUrl(uploadResult.path)
                                                                    );
                                                                } else {
                                                                    throw new Error("upload failed");
                                                                }
                                                            } catch (error) {
                                                                console.log(
                                                                    "error while uploading file",
                                                                    error
                                                                );
                                                                alert(
                                                                    (error as Error).message?.[0] ??
                                                                    "ფაილის ატვირთვა ვერ მოხერხდა."
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </UploadFile>
                                                {previewCoverPhotoTmpUrl && (
                                                    <DeleteUploadFileBtn
                                                        type="button"
                                                        onClick={() => setPreviewCoverPhotoTmpUrl("")}
                                                    >
                                                        <DeleteFileIcon />
                                                    </DeleteUploadFileBtn>
                                                )}
                                            </UploadContent>
                                        </UploadWrapper>
                                        {/* END upload cover photo */}



                                        {/* BEGIN Info Blocks */}
                                        <InfoBlocksEditor
                                            language={language}
                                            infoBlocks={editedPage.infoBlocks || ([] as InfoBlock[])}
                                            setInfoBlocks={(value: InfoBlock[]) => {
                                                setPageField("infoBlocks", value);
                                            }}
                                        />
                                        {/* END Info Blocks */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-lg-auto min-w-lg-300px">
                <div
                    className="card"
                    data-kt-sticky="true"
                    data-kt-sticky-name="invoice"
                    data-kt-sticky-offset="{default: false, lg: '200px'}"
                    data-kt-sticky-width="{lg: '250px', lg: '300px'}"
                    data-kt-sticky-left="auto"
                    data-kt-sticky-top="150px"
                    data-kt-sticky-animation="false"
                    data-kt-sticky-zindex={95}
                >
                    <div className="card-body p-10">
                        <div className="mb-10">
                            <BoxStyle>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        {t("changeLanguage")}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={language}
                                        label={t("changeLanguage")}
                                        onChange={(event) =>
                                            setLanguage(event.target.value as EnabledLanguage)
                                        }
                                    >
                                        {supportedLocales.map((locale, index) => (
                                            <MenuItemStyle key={index} value={locale}>{locale}</MenuItemStyle>
                                        ))}
                                    </Select>
                                </FormControl>
                            </BoxStyle>
                        </div>


                        <div className="mb-0">
                            <button
                                type="button"
                                className="btn btn-primary w-100"
                                id="kt_invoice_submit_button"
                                onClick={async () => {
                                    // save page
                                    try {
                                        const updatedPage = await savePage(editedPage).unwrap();
                                        if ("error" in updatedPage) {
                                            // @ts-ignore
                                            throw updatedPage.error;
                                        }
                                        if (!editedPage.id) {
                                            // redirect to newly created page
                                            document.location.href = `/admin/pages-edit/${updatedPage.id}`;
                                        }
                                        alert("მონაცემები წარმატებით დამახსოვრდა");
                                        // refetchExistingpage();
                                    } catch (error) {
                                        const errorMessage =
                                            // @ts-ignore
                                            error?.data?.errors?.[0].message || t("errorWhileSaving");
                                        toast.error(errorMessage);
                                    }
                                }}
                            >
                                <span className="svg-icon svg-icon-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                            fill="black"
                                        />
                                        <path
                                            opacity="0.3"
                                            d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {t("save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" />
        </div>
    );
};

//
const FieldsetStyle = styled.fieldset`
  margin-bottom: 20px;
  border: 1px solid #666;
  padding: 10px;
  border-radius: 10px;
`;
const InfoBlockInput = styled.input`
  border-radius: 4px;
  outline: none;
  border: 1px solid gray;
  padding: 7px 10px;
  margin-bottom: 15px;
  width: 100%;
  &:last-child {
    margin-bottom: 10px;
  }
`;
const InfoBlockTitle = styled.h5`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 16px;
  margin-bottom: 5px;
`;
const InfoBlockWrapper = styled.div`
  border-top: 2px solid gray;
  padding-top: 50px;
  img {
    margin-bottom: 15px;
  }
`;
// 
const UploadContent = styled.div`
  display: flex;
  margin-top: 20px;
`;
const DeleteUploadFileBtn = styled.button`
  height: 50px;
  width: 70px;
  border: 1px solid #d40000;
  border-radius: 10px;
  margin-left: 20px;
  background-color: #fff;
  outline: none;
`;
const UploadWrapper = styled.div`
  margin-bottom: 40px;
`;
const PrevViewImg = styled.img`
  height: 150px;
  width: 150px;
  object-fit: cover;
  display: block;
`;
const UploadFile = styled.div`
  position: relative;
  height: 50px;
  width: 70px;
  border: 1px solid gray;
  border-radius: 10px;
`;
const DeleteFileIcon = styled(RiDeleteBinFill)`
  font-size: 27px;
  color: #d40000;
`;
const UploadFileIcon = styled(FaCloudUploadAlt)`
  font-size: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: gray;
  user-select: none;
  pointer-events: none;
`;
const UploadInput = styled.input`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  &::after {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    cursor: pointer;
  }
`;
const TitleLabel = styled.label`
  font-size: 18px;
  font-family: ${Fonts.FiraGoRegular};
`;
//
const BoxStyle = styled(Box)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;
const MenuItemStyle = styled(MenuItem)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;
const DateDiv = styled.div`
  margin-left: 10px;
`;
const InvoiceDiv = styled.div`
  margin-left: 10px;
`;
export default Admin_page_edit;
