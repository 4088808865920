import React from "react";


const Loader: React.FC =() => {
    return(
        <div id="preloader"style={{margin: '0px'}}><div data-loader="circle-side"></div></div>
    )
}




export default Loader







