import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Fonts from '../assets/Fonts';
import Colors from '../assets/Colors';
import Responsive from "../assets/Responsive"

const NotFound404: React.FC<{}> = ({ }) => {
    const { t, i18n } = useTranslation();
    return (
        <Wrapper >
            <Number>4<span>0</span>4</Number>
            <NotFound>Page Not Found</NotFound>
        </Wrapper>
    )
}



// style
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${Colors.White};
    user-select: none;
    pointer-events: none;
`;
const Number = styled.div`
    font-size: 60px;
    font-family: ${Fonts.Roboto};
    color: ${Colors.Black};
    border-right: 2px solid ${Colors.Primary};
    padding-right: 8px;
        & > span {
            color: ${Colors.Primary};
        }
`;
const NotFound = styled.div`
    font-size: 50px;
    font-family: ${Fonts.Roboto};
    border-left: 2px solid ${Colors.Primary};
    border-top: 2px solid ${Colors.Primary};
    padding-left: 8px;
        & > span {
            color: ${Colors.Black};
        }
`;


export default NotFound404;