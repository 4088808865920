import React from 'react';
import styled from "styled-components";
import Responsive from '../assets/Responsive';
import Fonts from "../assets/Fonts";



const RichText: React.FC<{ html: string }> = ({ html: htmlValue }) => {
  return (
    <Description
      dangerouslySetInnerHTML={{ __html: htmlValue }}
    ></Description>
  );
}

const Description = styled.div`
  &, p {
    font-size: 16px !important;
  	font-family: ${Fonts.FiraGoRegular};
    font-weight: 300;
    line-height: 1.4;
  }
  p {
    margin-bottom: 15px;
  }
  margin-bottom: 20px;
  text-align: left;
  & ul {
    padding-left: 20px;
     li {
      list-style-type: disc;
       &:marker { margin-left: 20px; }
    }
  }
	${Responsive.mobile}{
		font-size: 16px !important;
	}
`

export default RichText;
