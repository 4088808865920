//import axios from 'axios';

export const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

/**
 * remembers token in localStorage. axios `defaults` Authorization header is currently disabled.
 */
export const setAuthToken = (token: string) => {
  if (token) {
    //axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    localStorage.setItem("authToken", token);
  } else {
    //delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem("authToken");
  }
};
