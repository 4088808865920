import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const Admin_menus: React.FC = () => {

  const navigate = useNavigate();

  return (
    <div className="card">
      <div className="card-body pt-5">
        {/*begin::Table*/}
        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
          {/*begin::Table head*/}
          <thead>
            {/*begin::Table row*/}
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              <th className="w-10px pe-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_table_users .form-check-input" defaultValue={1} />
                </div>
              </th>
              <th className="min-w-125px">User</th>
              <th className="min-w-125px">Role</th>
              <th className="min-w-125px">Last login</th>
              <th className="min-w-125px">Two-step</th>
              <th className="min-w-125px">Joined Date</th>
              <th className="text-end min-w-100px">Actions</th>
            </tr>
            {/*end::Table row*/}
          </thead>
          {/*end::Table head*/}
          {/*begin::Table body*/}
          <tbody className="text-gray-600 fw-bold">

            {/*begin::Table row*/}
            <tr>
              {/*begin::Checkbox*/}
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" defaultValue={1} />
                </div>
              </td>
              {/*end::Checkbox*/}
              {/*begin::User=*/}
              <td className="d-flex align-items-center">
                {/*begin:: Avatar */}
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label">
                      <img src="/assets/media/avatars/150-1.jpg" alt="Emma Smith" className="w-100" />
                    </div>
                  </a>
                </div>
                {/*end::Avatar*/}
                {/*begin::User details*/}
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Emma Smith</a>
                  <span>e.smith@kpmg.com.au</span>
                </div>
                {/*begin::User details*/}
              </td>
              {/*end::User=*/}
              {/*begin::Role=*/}
              <td>Administrator</td>
              {/*end::Role=*/}
              {/*begin::Last login=*/}
              <td>
                <div className="badge badge-light fw-bolder">Yesterday</div>
              </td>
              {/*end::Last login=*/}
              {/*begin::Two step=*/}
              <td />
              {/*end::Two step=*/}
              {/*begin::Joined*/}
              <td>10 Mar 2021, 6:05 pm</td>
              {/*begin::Joined*/}
              {/*begin::Action=*/}
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}</a>
                {/*begin::Menu*/}
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <Link to="/admin/tour-edit/1" className="menu-link px-3">Edit</Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                  {/*end::Menu item*/}
                </div>
                {/*end::Menu*/}
              </td>
              {/*end::Action=*/}
            </tr>



            {/*end::Table row*/}
            {/*begin::Table row*/}
            <tr>
              {/*begin::Checkbox*/}
              <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input className="form-check-input" type="checkbox" defaultValue={1} />
                </div>
              </td>
              {/*end::Checkbox*/}
              {/*begin::User=*/}
              <td className="d-flex align-items-center">
                {/*begin:: Avatar */}
                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                  <a href="../../demo1/dist/apps/user-management/users/view.html">
                    <div className="symbol-label fs-3 bg-light-danger text-danger">M</div>
                  </a>
                </div>
                {/*end::Avatar*/}
                {/*begin::User details*/}
                <div className="d-flex flex-column">
                  <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-800 text-hover-primary mb-1">Melody Macy</a>
                  <span>melody@altbox.com</span>
                </div>
                {/*begin::User details*/}
              </td>
              {/*end::User=*/}
              {/*begin::Role=*/}
              <td>Analyst</td>
              {/*end::Role=*/}
              {/*begin::Last login=*/}
              <td>
                <div className="badge badge-light fw-bolder">20 mins ago</div>
              </td>
              {/*end::Last login=*/}
              {/*begin::Two step=*/}
              <td>
                <div className="badge badge-light-success fw-bolder">Enabled</div>
              </td>
              {/*end::Two step=*/}
              {/*begin::Joined*/}
              <td>10 Mar 2021, 2:40 pm</td>
              {/*begin::Joined*/}
              {/*begin::Action=*/}
              <td className="text-end">
                <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                  <span className="svg-icon svg-icon-5 m-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}</a>
                {/*begin::Menu*/}
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <Link to="/admin/tour-edit/2" className="menu-link px-3">Edit</Link>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-users-table-filter="delete_row">Delete</a>
                  </div>
                  {/*end::Menu item*/}
                </div>
                {/*end::Menu*/}
              </td>
              {/*end::Action=*/}
            </tr>
            {/*end::Table row*/}

          </tbody>
          {/*end::Table body*/}
        </table>
        {/*end::Table*/}
      </div>
      {/*end::Card body*/}
    </div>
  )
}

export default Admin_menus