import React, { useState } from 'react';
import TopBanner from '../components/TopBanner';
import TourCard from '../components/TourCard';
import SearchImg from '../images/search-icon.svg'
import LocationImg from '../images/maps-line-icon.svg'
import styled from 'styled-components';
import Input from '../components/Input';
import SubmitButton from '../components/Button';
import CustomSelect from '../components/CustomSelect';
import api, { uploadUrl } from '../api';
import Loader from '../components/Loader';
import { useParams } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { useTranslation } from 'react-i18next';
import Responsive from '../assets/Responsive';
import { BsFilter } from 'react-icons/bs';
import { Destination } from '../domain/types';
import Fonts from '../assets/Fonts';



const Destinations: React.FC = () => {
	// data import
	const { data: destinations = [], isFetching: isDestinationsLoading, refetch: refetchDestinations } = api.useGetDestinationsQuery(undefined);
	const [place, setPlace] = useState('')
	const [where, setWhere] = useState('')
	const [category, setCategory] = useState('')
	const { slug = 'destinations' } = useParams();
	const {
		data: page,
		isFetching: isPageLoading,
		refetch: refetchPage } = api.useGetPageQuery(slug);

	// translation import
	const { t } = useTranslation();



	// search field
	const [searchField, setSearchField] = useState<string>("");

	// filters
	const search = (page: Destination[]) => {
		return page.filter((item) =>
			item.city?.toLocaleLowerCase().includes(searchField) ||
			item.title?.toLocaleLowerCase().includes(searchField) ||
			item.country?.toLocaleLowerCase().includes(searchField)
		)
	}
	// filters


	return (

		<div>
			{isPageLoading ? <Loader /> :
				!page ? (
					<NotFound />
				) : (
					<TopBanner
						title={page.title}
						bannerBackImg={uploadUrl(page.cover)}
					/>
				)
			}

			{/*/hero_in*/}


			<div className="collapse" id="collapseMap">
				<div id="map" className="map"></div>
			</div>
			{/* End Map */}

			<div className="container margin_60_35">
				<div className="col-lg-12">
					<FormWrapper className="row g-0 custom-search-input-2 inner my-0">
						<Distance className="col-lg-12">
							<SearchWrapperStlye className="form-group">
								<Input
									type="text"
									className=""
									placeholder={t("WhatAreYouLookingFor")}
									id=''
									label={t("searchFor")}
									required={false}
									error={false}
									value={searchField}
									onChange={(value) => setSearchField(value)}
								/>
								<FilterIconStyle />
							</SearchWrapperStlye>
						</Distance>
					</FormWrapper>
				</div>

				{isDestinationsLoading ? <Loader /> : (
					<div className="isotope-wrapper row">
						{search(destinations).map((d) => (
							<div key={d.id} className="col-lg-4 mt-4">
								<TourCard
									topic={d.city}
									link={'/destination/' + d.slug}
									tourId={d.id}
									readMore={t("allSeeAllButton")}
									img={uploadUrl(d.poster)}
									title={d.title}
									description={d.shortDescription}
								/>
							</div>
						))}

					</div>
				)}
				{search(destinations).length < 1 ? (<SearchNotFount> {t("NotFound")}</SearchNotFount>) : null}


			</div>
		</div >
	);
}


const SearchNotFount = styled.div`
	font-size: 20px;
	font-family: ${Fonts.FiraGoRegular};
	min-height: 50vh;
	width: 80%;
	margin: 0 auto;
`;
const FilterIconStyle = styled(BsFilter)`
	border-radius: 3px;
    font-size: 22px;
    position: absolute;
    line-height: 50px;
    top: 16px;
    right: 1px;
    padding-right: 15px;
    display: block;
    box-sizing: content-box;
    z-index: 9;
    color: #999;
`;
const SearchWrapperStlye = styled.div`
	width: 80%;
	margin: 0 auto 30px auto !important;
`;
const IconImg = styled.img`
	border-radius: 3px;
    font-size: 18px;
    font-size: 1.125rem;
    position: absolute;
    background-color: #fff;
    line-height: 50px;
    top: 15px;
    right: 1px;
    padding-right: 15px;
    display: block;
    width: 15px;
    box-sizing: content-box;
    z-index: 9;
    color: #999;
	
`

const FormWrapper = styled.div`
	background-color: transparent !important;
	-moz-box-shadow: none !important;
	-goog-ms-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
		input{
			height: unset;
			border-right: unset;
		}
	
`
const Distance = styled.div`
	padding: 0px 5px;
		&:first-child {
			padding-left: 0px;
		}
		&:last-child {
			padding-right: 0px;
		}
	${Responsive.tablet} {
		margin-bottom: 10px;
		&:first-child {
			padding-left: 5px;
		}
		&:last-child {
			padding-right: 5px;
		}
	}
	${Responsive.mobile} {
		padding: 0px;
		margin-bottom: 10px;
	}
`

export default Destinations;
