import React from 'react';
import styled from 'styled-components';
import { uploadUrl } from '../api';

const IconWrapper = styled.span`
  // display: inline-block;
  // width: auto;
  // padding: 30px;
  // background-color: rgba(252,91,98,0.3);
  // border-radius: 50%;
  // filter: invert(100%);
`;
const Icon = styled.img`
  height: 60px; width: auto;
  margin-bottom: 14px;
`;

const InfoBlockCard: React.FC<{ icon: string; title: string; description: string; }> = ({
  icon, title, description,
}) => {
    return (
        <a className="box_topic" href="#">
				<IconWrapper>
        {/* <i className="pe-7s-wallet"></i> */}
        <Icon src={uploadUrl(icon)} style={{opacity: 0.6}} />
        </IconWrapper>
				<h3>{title}</h3>
				<p>{description}</p>
				</a>
    );
}
export default InfoBlockCard;
