import React from 'react'
import styled from 'styled-components';
import api from '../api';
import Loader from './Loader';
import Fonts from '../assets/Fonts';
import Colors from '../assets/Colors';
import Responsive from "../assets/Responsive"
import { useTranslation } from 'react-i18next';

const News: React.FC = () => {
    const { data: news = [], isFetching: isNewsLoading, refetch: refetchNews } = api.useGetNewsQuery(undefined);
    const { t } = useTranslation();
    if (!news.length) {
        return null;
    }

    return (
        <div className="bg_color_1">
            <div className="container margin_80_55">
                <div className="main_title_2">
                    <span><em></em></span>
                    <TittleH3>{t("aboutUsNewsTitle")}</TittleH3>
                    {/* <p>განათლებულმა და ერთჯერადმა მოქალაქეებმა შეაჩერეს ისინი დაფინანსებისგან.</p> */}
                </div>
                <div className="row">
                    {isNewsLoading ? <Loader /> : (
                        <>
                            {news.map((n) => (
                                <div key={n.id} className="col-lg-6">
                                    <a className="news box_news" href={'/news/' + n.slug}>
                                        <DateBox>
                                            <figcaption>{n.date}</figcaption>
                                        </DateBox>
                                        <ul>
                                            <Li>Mark Twain</Li>
                                            <Li>{n.date}</Li>
                                        </ul>
                                        <TittleH4>{n.title}</TittleH4>
                                        <Description>{n.shortDescription}</Description>
                                    </a>
                                </div>
                            ))}
                        </>
                    )}

                </div>
                {/* <p className="btn_home_align"><a href="/" className="btn_1 rounded">ნახე ყველა სიახლე</a></p> */}
            </div>
            {/* /container */}
        </div>
    )
}

const TittleH4 = styled.h4`
    font-family: ${Fonts.FiraGoRegular};
    font-size: 17px !important;
`
const Description = styled.p`
    font-family: ${Fonts.NotoSans};
    color: ${Colors.Gray};
`
const Li = styled.li`
    font-family: ${Fonts.FiraGoBold};
    font-size: 12px !important;
`
const TittleH3 = styled.h3`
    font-family: ${Fonts.FiraGoBold};
    font-size: 24px !important;
    ${Responsive.mobile}{
        font-size: 20px !important;
    }
    ${Responsive.tablet}{
        font-size: 21px !important;
    }
`
const DateBox = styled.figure`
    ${Responsive.mobile}{
        height: 60px !important;
        width: 60px !important;
    }
    ${Responsive.tablet}{
        height: 75px !important;
        width: 75px !important;
    }
`


export default News