import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '../api'
import { EnabledLanguage, supportedLocales, isSupportedLanguage } from '../domain/types';
import { detectUserLanguage, setLang } from './langStorage';


export type AppState = {
    lang: EnabledLanguage
    //
    searchKeyword: string
}

const slice = createSlice({
    name: 'appSlice',
    initialState: {
        lang: detectUserLanguage(),
    } as AppState,
    reducers: {
        setLanguage: (
            state: AppState, { payload: lang }: PayloadAction<EnabledLanguage>
        ) => {
            state.lang = lang
            // also remember language in localStorage
            setLang(lang)
            // ugly hack to refresh everything
            window.document.location.reload()
        },
        setSearchKeyword: (// for global header search
          state: AppState,
          { payload: { searchKeyword } }: PayloadAction<{ searchKeyword: string; }>
        ) => {
          state.searchKeyword = searchKeyword
        },
    },

})

export const { setLanguage, setSearchKeyword } = slice.actions;

export default slice.reducer