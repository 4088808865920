import React from 'react'
import styled from 'styled-components';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from "react-google-maps"
import mapStyles from './mapStyles';
// import MapMarkerIcon from '../assets/map-marker.png'



const MapSetting: React.FC<{}> = ({ }) => {
  return (
    <GoogleMap
      defaultZoom={15} // default map zoom
      defaultCenter={{ lat: 41.70869495925625, lng: 44.7648664983118 }} //location
      defaultOptions={{ styles: mapStyles }} //custom map json
    >
      <Marker
        position={{ lat: 41.70869495925625, lng: 44.7648664983118 }} //custom location
        icon={{
          // url: `${MapMarkerIcon}`, // custom map marker
          scaledSize: { width: 32, height: 32 } //change size
        }}
      />
    </GoogleMap>
  )
};

const WrappedMap = withScriptjs(withGoogleMap(MapSetting)); //exported full map

const Map = () => {
  return (
    <Wrapper>
      <WrappedMap googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBEEeH2DvKTXNJAjIY4aMWUMuC8JciRVFk'} //api url and key
        loadingElement={<div style={{ height: '100%' }}></div>} //needed for height
        containerElement={<div style={{ height: '100%' }}></div>} //needed for height
        mapElement={<div style={{ height: '100%' }}></div>} //needed for height
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export default Map