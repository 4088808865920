import React, { useEffect, useState } from "react";
import i18next from "i18next";
import i18n from "../i18n";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";
import api from "../api";
import { setLanguage } from "../store/appSlice";
import { EnabledLanguage } from "../domain/types";
import { useAppDispatch, useAppSelector } from "../store/index";

const Footer: React.FC = () => {
  const {
    data: footerMenuItems,
    isFetching: isFooterMenuItemsLoading,
    refetch: refetchFooterMenuItems,
  } = api.useGetFooterMenuItemsQuery(undefined);

  const dispatch = useAppDispatch();

  const { lang } = useAppSelector((root) => root.app);

  return (
    <footer id="footer">
      <div className="container margin_60_35">
        <div className="row">
          {footerMenuItems?.map((f) => (
            <div className="col-lg-3 col-md-6 ms-lg-auto" key={f.id}>
              <TrevelTopic>{f.title}</TrevelTopic>
              {f.subMenu.length > 0 ? (
                <ul className="links">
                  {f.subMenu.map((sub) => (
                    <Li key={f.id}>
                      <Link
                        to={
                          sub.url.includes("mailto:") ||
                          sub.url.includes("tel:")
                            ? "#"
                            : sub.url
                        }
                        onClick={(event) => {
                          if (sub.url.includes("mailto:")) {
                            document.location.href = sub.url;
                            event.preventDefault();
                          }
                          if (sub.url.includes("tel:")) {
                            document.location.href = sub.url;
                            event.preventDefault();
                          }
                        }}
                      >
                        {sub.title}
                      </Link>
                    </Li>
                  ))}
                </ul>
              ) : null}
            </div>
          ))}

          {/* <div className="col-lg-3 col-md-6 ms-lg-auto">
                        <TrevelTopic>იმოგზაურე საზღვარგარეთ</TrevelTopic>
                        <ul className="links">
                            <Li><Link to={`/tickets`}>ავიაბილეთები</Link></Li>
                            <Li><Link to={`/hotels`}>სასტუმროს დაჯავშნვა</Link></Li>
                            <Li><Link to={`/packages`}>ტურისტული საგზური</Link></Li>
                            <Li><Link to={`/other`}>სხვა</Link></Li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 ms-lg-auto">
                        <TrevelTopic>სავიზო მომსახურება</TrevelTopic>
                        <ul className="links">
                            {[
                                "ემირატები", "შრი-ლანკა", "ტაილანდი", "ტანზანია",
                            ].map((country, index) => (
                                <li key={index}><Link to={`/visa/{index}`}>{country}</Link></li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5>Contact Us</h5>
                        <ul className="contacts">
                            <Li><a href="tel://61280932400"><i className="ti-mobile"></i> + 995 599 30 00 00 </a></Li>
                            <Li><a href="mailto:info@Panagea.com"><i className="ti-email"></i> info@voyager.com</a></Li>
                        </ul>
                    </div> */}
        </div>

        <div className="follow_us">
          <ul>
            <Li>Follow us</Li>
            <Li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/voyagergeorgia1"
              >
                <i className="ti-facebook"></i>
              </a>
            </Li>
            <Li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/voyager_georgia/?fbclid=IwAR262eUps0ZyVgNEvFgHHa5S1LhEiQ_85cCJC8zaRu2jNgFPlZkpYa-XkE4"
              >
                <i className="ti-instagram"></i>
              </a>
            </Li>
          </ul>
        </div>
        {/*/row*/}
        <hr />
        <div className="row">
          <div className="col-lg-6">
            <ul id="footer-selector">
              <li>
                <div className="styled-select" id="lang-selector">
                  <select
                    value={lang}
                    onChange={(event) =>
                      dispatch(
                        setLanguage(event.target.value as EnabledLanguage)
                      )
                    }
                  >
                    <option value="en">English</option>
                    <option value="ge">ქართული</option>
                  </select>
                </div>
              </li>

              <li>
                <img src="img/cards_all.svg" alt="" />
              </li>
            </ul>
          </div>
          {/* <div className="col-lg-6">
                        <ul id="additional_links">
                            <li><a href="#0">Terms and conditions</a></li>
                            <li><a href="#0">Privacy</a></li>
                            <li><span>© Voyager</span></li>
                        </ul>
                    </div> */}
        </div>
      </div>
    </footer>
  );
};

const Li = styled.li`
  font-family: ${Fonts.FiraGoBold};
`;
const TrevelTopic = styled.h5`
  font-family: ${Fonts.FiraGoBold};
  font-size: 18px;
  color: ${Colors.White};
`;

export default Footer;
