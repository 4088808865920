import React from 'react'
import styled from 'styled-components';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Card from './Card';
import CardImg from '../images/restaurant_img.jpg'
import Fonts from '../assets/Fonts';
import Responsive from '../assets/Responsive';
import Colors from '../assets/Colors';
import WrapperImg from '../images/tour_img.jpg'
import TourCard from './TourCard';
import fakeData from './fakeData';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import api, { uploadUrl } from '../api';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1201 },
		items: 4,
		slidesToSlide: 1, // optional, default to 1.
	},
	labtop: {
		breakpoint: { max: 1200, min: 991 },
		items: 3,
		slidesToSlide: 1, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 1, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 768, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

const PopularCard: React.FC<{}> = ({ }) => {

	const { slug = '' } = useParams();
	const { data: popularTours = [], isFetching: isPopularToursLoading, refetch: refetchPopularTours } = api.useGetTourPopularQuery(undefined);

	const CustomDot = ({ onClick, ...rest }: any) => {
		const {
			// onMove,
			// index,
			active,
			carouselState: { currentSlide, deviceType, totalItems, slidesToShow },
		} = rest;
		return (
			<>
				<DotButton
					className={active ? "active" : "inactive"}
					onClick={() => onClick()}
				></DotButton>
			</>
		);
	};

	const { t } = useTranslation();

	return (
		<Wrapper className="container container-custom margin_80_0">
			<div className="main_title_2">
				<span><em></em></span>
				<h2 style={{ fontFamily: Fonts.FiraGoRegular }}>{t("mainTourTitle")}</h2>
			</div>
			<div id="reccomended" className="owl-carousel owl-theme slider-pb-55" style={{ display: "unset" }}>
				<Carousel
					responsive={responsive}
					swipeable={true}
					showDots={true}
					autoPlay={false}
					autoPlaySpeed={3000}
					keyBoardControl={true}
					arrows={false}
					draggable={true}
					infinite={true}
					sliderClass='slidet-class'
					itemClass='popular-tour-card'
					containerClass="popular-tour-carousel-container"
					customDot={<CustomDot />}
				>
					{popularTours.map((pt, index) => (
						<div key={pt.id} className="item">
							<TourCard
								topic={pt.country}
								link={'/tour/' + pt.slug}
								tourId={pt.id}
								readMore={t("seeMoreButton")}
								img={uploadUrl(pt.poster)}
								title={pt.title}
								description={pt.shortDescription}
							/>
						</div>
					))}
				</Carousel>

			</div>
			<p className="btn_home_align mt-3"><a href="/tours" className="btn_1 rounded">{t("mainTourButton")}</a></p>
			<hr className="large" />
		</Wrapper>
	)
}


const Wrapper = styled.div`
	&.react-multi-carousel-item + .react-multi-carousel-item--active + .popular-tour-card {
		background-color: red !important;
	}
`;
const ComponentWrapper = styled.div`
	  width: 290px;
    margin: 0 auto !important;
    font-family: ${Fonts.FiraGoRegular};
`;
const DotButton = styled.button`
    height: 15px;
    width: 15px;
    margin: 5px;
    border-radius: 50%;
    border: 0px;
    background-color: rgb(214, 214, 214);
    cursor: pointer;
        ${Responsive.mobile}{
            background-color: transparent;
            &::before {
                content: '';
                min-width: 15px;
                min-height: 15px;
                height: 15px;
                width: 15px;
                display: block;
                border-radius: 50%;
                background-color: rgb(214, 214, 214);
                margin-left: -6px;
                margin-top: -1px;
            }
            &.active {
                background-color: transparent !important;
                    &::before {
                        background-color: #fc5b62;
                        min-width: 15px;
                        min-height: 15px;
                        height: 15px;
                        width: 15px;
                    }
            }
        }
        &.active {
            background-color: #fc5b62;
        }
 
`;



export default PopularCard
