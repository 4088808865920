const Colors = {
    Primary: "#5a87a8",
    White: "#ffffff",
    Black: "#000000",
    Carnation: "#FC5B62",
    Gray: "#555",
    Cornflower: '#97C9ED',  
    Geyser: '#D2D8DD',
    Trout: '#495057'
}
export default Colors