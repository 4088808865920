import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import api from "../../api";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
// MUI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Option from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Fonts from "../../assets/Fonts";
import { EnabledLanguage, MenuItem, supportedLocales } from "../../domain/types";
import { useTranslation } from "react-i18next";
import NotFound from "../../components/NotFound";
//
const Admin_menu_item_edit = () => {
  //
  const { id: menuItemIdStr = "0" } = useParams();
  const menuItemId = parseInt(menuItemIdStr);

  // export data
  const {
    data: existingMenuItem,
    isFetching: isExistingMenuItemLoading,
    refetch: refetchExistingMenuItem,
  } = api.useGetMenuItemByIdQuery(menuItemId);

  const [editedMenuItem, setEditingMenuItem] = useState<Partial<MenuItem>>({});
  // example: setMenuItemField('slug', 'NEW_CHANGED_SLUG')
  const setMenuItemField = (field: keyof MenuItem, value: any) => {
    setEditingMenuItem({
      ...editedMenuItem,
      [field]: value,
    });
  };
  /** example:
   * setTranslatedField("city", "ge", value)
   */
  const setTranslatedField = (field: "title", language: EnabledLanguage, value: any) => {
    setEditingMenuItem({
      ...editedMenuItem,
      translations: {
        ...editedMenuItem.translations,
        [language]: {
          ...editedMenuItem?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  const [language, setLanguage] = useState<EnabledLanguage>("ge");

  useEffect(() => {
    if (existingMenuItem) {
      // copy loaded menuItem from backend to draft useState menuItem
      setEditingMenuItem(existingMenuItem);
    }
  }, [existingMenuItem]);

  const [saveMenuItem, { isLoading: isSaveMenuItemLoading, error: saveMenuItemError }] = api.useSaveMenuItemMutation();

  const { t } = useTranslation();

  const isLoading = isExistingMenuItemLoading || isSaveMenuItemLoading;

  const navigate = useNavigate();

  return isLoading ? (
    <Loader />
  ) : menuItemId > 0 && !existingMenuItem ? (
    <NotFound />
  ) : (
    <Wrapper>
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
          <div className="card">
            <div className="card-body p-12">
              <div
                className="d-flex flex-space-between flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                style={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <span onClick={() => navigate(-1)} style={{ fontWeight: "bold", fontSize: 16, cursor: "pointer" }}>
                  &lt; მენიუში დაბრუნება
                </span>
                <span className="fs-2x fw-bolder text-gray-800">მენიუს ჩასწორება</span>
                <span style={{ width: 170 }}>&nbsp;</span>
              </div>
              <div className="separator separator-dashed my-10" />
              <div className="mb-0">
                <div className="row gx-10 mb-5">
                  <div className="col-lg-12">
                    <div className="row">
                      {/* Menu item Title here */}
                      <div className="mb-3 col-lg-6">
                        <Input
                          id=""
                          label={t("title")}
                          placeholder={t("title")}
                          type="text"
                          // instead city there should be country from data
                          value={editedMenuItem?.translations?.[language]?.title || ""}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setTranslatedField("title", language, value)}
                        />
                      </div>
                      {/* Menu item URL here */}
                      <div className="mb-4 col-lg-6">
                        <Input
                          id=""
                          label={t("url")}
                          placeholder={t("url") + "..."}
                          type="text"
                          value={editedMenuItem.url || ""}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setMenuItemField("url", value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-lg-auto min-w-lg-300px">
          <div
            className="card"
            data-kt-sticky="true"
            data-kt-sticky-name="invoice"
            data-kt-sticky-offset="{default: false, lg: '200px'}"
            data-kt-sticky-width="{lg: '250px', lg: '300px'}"
            data-kt-sticky-left="auto"
            data-kt-sticky-top="150px"
            data-kt-sticky-animation="false"
            data-kt-sticky-zindex={95}
          >
            <div className="card-body p-10">
              <div className="separator separator-dashed mb-8" />

              <div className="mb-10">
                <BoxStyle>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t("changeLanguage")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={language}
                      label={t("changeLanguage")}
                      onChange={(event) => setLanguage(event.target.value as EnabledLanguage)}
                    >
                      {supportedLocales.map((locale) => (
                        <OptionStyled key={locale} value={locale}>
                          {locale}
                        </OptionStyled>
                      ))}
                    </Select>
                  </FormControl>
                </BoxStyle>
              </div>

              <div className="mb-0">
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  id="kt_invoice_submit_button"
                  onClick={async () => {
                    // save menuItem
                    try {
                      const updatedMenuItem = await saveMenuItem(editedMenuItem).unwrap();
                      if ("error" in updatedMenuItem) {
                        // @ts-ignore
                        throw updatedMenuItem.error;
                      }
                      alert("მონაცემები წარმატებით დამახსოვრდა");
                      if (!editedMenuItem.id) {
                        // redirect to newly created menuItem
                        document.location.href = `/admin/menu-item-edit/${updatedMenuItem.id}`;
                      } else {
                        refetchExistingMenuItem();
                      }
                      // refetchExistingMenuItem();
                    } catch (error) {
                      const errorMessage =
                        // @ts-ignore
                        error?.data?.errors?.[0].message || t("errorWhileSaving");
                      toast.error(errorMessage);
                    }
                  }}
                >
                  <span className="svg-icon svg-icon-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <path
                        d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                        fill="black"
                      />
                      <path
                        opacity="0.3"
                        d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

// styles
//
//
const Wrapper = styled.div``;
const BoxStyle = styled(Box)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;
const OptionStyled = styled(Option)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;

export default Admin_menu_item_edit;
