import React, { useState } from "react";
import api, { uploadUrl } from "../../api";
import { Image } from "../../domain/types";
import { AiFillCloseSquare } from 'react-icons/ai';
import styled from 'styled-components';

type Props = {
  images: Image[];
  setImages: (_: Image[]) => void;
};
const MultipleImageUpload = ({ images, setImages }: Props) => {
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [
    uploadImages,
    { isLoading: isUploadImagesLoading, error: uploadImagesError },
  ] = api.useAdminUploadMultipleImagesMutation();
  return (
    //
    <Wrapper>
      {/*  */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {images.map((img) => (
          <Block
            key={img.id}>
            <img
              src={uploadUrl(img.image)}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <MinusButton
              type="button"
              onClick={() => {
                setImages(images.filter((next) => next.id != img.id));
              }}
            >
              <AiFillCloseSquare/>
            </MinusButton>
          </Block>
        ))}
        <div
          style={{
            width: 150,
            height: 150,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #999",
            borderRadius: 12,
            fontSize: 24,
            position: "relative",
          }}
          onClick={() => {}}
        >
          +
          <input
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
            type="file"
            accept="image/*"
            multiple
            onChange={async (event) => {
              const files = event.target.files;
              if (!files || !files.length) {
                return;
              }
              try {
                /** for now only single file upload */
                for (let i = 0; i < files.length; i++) {
                  // preview photo before upload
                  setPreviewUrls([
                    ...previewUrls,
                    URL.createObjectURL(files[i]),
                  ]);
                }
                const uploadedImageObjects = await uploadImages({
                  files,
                }).unwrap();
                if (uploadedImageObjects.length) {
                  setImages([...images, ...uploadedImageObjects]);
                  // preview photo AFTER upload
                  setPreviewUrls([]);
                } else {
                  throw new Error("upload failed");
                }
              } catch (error) {
                console.log("error while uploading file", error);
                alert(
                  (error as Error).message?.[0] ??
                    "ფაილის ატვირთვა ვერ მოხერხდა."
                );
              }
            }}
          />
        </div>
      </div>
    </Wrapper>
    //
  );
};

// styles levan madurashvili

const Block = styled.div`
  width: 150px;
  height: 150px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
    &:last-child {
      margin-right: 0px;
    }
`;
const Wrapper = styled.div`

`;
const MinusButton = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  position: absolute;
  right: -16px;
    top: -13px;
  transition: all 200ms ease-in;
    svg {
      font-size: 20px;
    }
    &:hover {
      transition: all 200ms ease-in;
      svg {
        transform: scale(1.1);
        fill: red;
      }
    }
`;

export default MultipleImageUpload;
