import React, { useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from 'styled-components';
import Colors from './../assets/Colors';
import Fonts from './../assets/Fonts';
import Responsive from '../assets/Responsive'
import { Link } from "react-scroll"
import { useTranslation } from 'react-i18next';
import api from '../api';
import Loader from './Loader';
import { uploadUrl } from './../api/index';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};

const MainSlider: React.FC<{}> = ({ }) => {
    const { data: slider = [], isFetching: issliderLoading, refetch: refetchslider } = api.useGetMainSliderQuery(undefined);
    const CustomDot = ({ onClick, ...rest }: any) => {
        const {
            // onMove,
            // index,
            active,
            carouselState: { currentSlide, deviceType, totalItems, slidesToShow },
        } = rest;
        return (
            <>
                <DotButton
                    className={active ? "active" : "inactive"}
                    onClick={() => onClick()}
                ></DotButton>
            </>
        );
    };

    const { t } = useTranslation();

    return (
        <>
            {issliderLoading ? <Loader /> : (
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    showDots={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    arrows={true}
                    draggable={true}
                    infinite={true}
                    removeArrowOnDeviceType={['mobile', 'tablet']}
                    customDot={<CustomDot />}
                >
                    {slider.map((s) => (
                        <Wrapper key={s.id}>
                            <MainImg src={uploadUrl(s.cover)} alt="" />
                            <Shadow />
                            <ContactUsWrapper>
                                <ContactUsTitle>{s.title}</ContactUsTitle>
                                <ContactUsDescription>{s.description}</ContactUsDescription>
                                <ContactUsBtn to='dashboard-submit-form'>{t("mainSliderButton")}</ContactUsBtn>
                            </ContactUsWrapper>
                        </Wrapper>
                    ))}
                </Carousel>
            )}
        </>
    )
}


const Wrapper = styled.div`
    height: 100vh;
    position: relative;
`;
const MainImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const Shadow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    user-select: none;
`;
const ContactUsWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const ContactUsTitle = styled.h3`
    font-size: 55px;
    color: white;
    font-family: ${Fonts.FiraGoBold};
    user-select: none;
    text-align: center;
    ${Responsive.mobile}{
        font-size: 30px;
    }
    ${Responsive.tablet}{
        font-size: 36px;
    }
    ${Responsive.laptop}{
        font-size: 40px;
    }
`;
const ContactUsDescription = styled.p`
    font-size: 18px;
    color: white;
    font-family: ${Fonts.NotoSans};
    font-weight: 300;
    user-select: none;
    text-align: center;
    ${Responsive.mobile}{
        font-size: 16px;
    }
`;
const ContactUsBtn = styled(Link)`
    padding: 15px 30px;
    border-radius: 4px;
    background-color: ${Colors.Carnation};
    outline: none;
    color: white;
    border: 0px;
    font-size: 14px;
    font-family: ${Fonts.Roboto};
    cursor: pointer;
        &:hover {
            color: white;
        }
`;
const DotButton = styled.button`
    display: none;
    margin-bottom: 30px;
    height: 17px;
    width: 17px;
    min-width: 15px;
    min-height: 15px;
    /* border: 1px solid ${Colors.White};
    border-radius: 50%; */
    background-color: transparent;
    margin-left: 5px;
    margin-right: 5px;
    border: 0px;
    
    &::before {
        content: '';
        display: block;
        min-width: 15px;
        min-height: 15px;
        height: 15px;
        width: 15px;
        background-color: transparent;
        margin-left: -7px;
        margin-top: -2px;
        border: 1px solid ${Colors.White};
        border-radius: 50%;
    }
    &.active {
        &::before {
            background-color: ${Colors.White};
        }
    }
    ${Responsive.tablet} {
        display: block;
    }
    ${Responsive.mobile} {
        display: block;
    }
`;

export default MainSlider