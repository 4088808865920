import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import api, { uploadUrl } from "../api";
import Colors from "../assets/Colors";
import Fonts from "../assets/Fonts";
import Banner from "../components/Banner";
import CustomSelect from "../components/CustomSelect";
import Input from "../components/Input";
import Loader from "../components/Loader";
import MainSlider from "../components/MainSlider";
import NotFound from "../components/NotFound";
import PopularCard from "../components/PopularCard";
import SeaImage from "../images/test-image.jpg";

// alt + shift + o fix imports

const Home: React.FC = () => {
  const { data: banner, isFetching: isMainPageBannerLoading } = api.useGetMainPageBannerQuery(undefined);
  const { data: topic = [], isFetching: isTopicLoading } = api.useGerTopicsQuery(undefined);
  const [Form] = api.usePostFormMutation();

  const { t } = useTranslation();

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedValue, setSelectedValue] = useState(Number);
  const [disableSeenFormBtn, setdisableSeenFormBtn] = useState(false);

  // email validation
  const emailRegex = /^\S+@\S+\.\S+$/;

  // main page contact form
  const sendForm = async (e: any) => {
    e.preventDefault();
    if (emailRegex.test(userEmail) && phone !== "" && fullName !== "" && selectedValue !== 0) {
      try {
        await Form({ fullName, phone, userEmail, topicId: selectedValue, type: "main-form" });
        setdisableSeenFormBtn(true);
        alert("მოთხოვნა წარმატებით გაიგზავნა");
      } catch (err: any) {
        toast.error(err.data.errors[0].message);
        setdisableSeenFormBtn(false);
      }
    } else {
      if (phone === "") {
        toast.error("გთხოვთ მიუთითოთ სწორი ნომერი");
      }
      if (fullName === "") {
        toast.error("გთხოვთ შეავსოთ სახელი / გვარი - ს ველი");
      }
      if (!emailRegex.test(userEmail)) {
        toast.error("გთხოვთ მიუთითოთ სწორი მეილი");
      }
      if (selectedValue === 0) {
        toast.error("გთხოვთ აირჩიოთ თემა");
      }
    }
  };
  // main page contact form

  return (
    <div>
      <MainSlider />
      <PopularCard />
      {isMainPageBannerLoading ? (
        <Loader />
      ) : !banner ? (
        <div>
          <NotFound />
        </div>
      ) : (
        <div className="container container-custom margin_30_95">
          <div>
            <Banner
              smallTitle={banner.titleOne}
              mainTitle={banner.titleTwo}
              link={"/banner/" + banner.slug}
              linkText={t("mainBannerButton")}
              bannerBackground={uploadUrl(banner.banner)}
            />
          </div>
        </div>
      )}

      <div className="call_section" id="dashboard-submit-form" style={{ backgroundImage: "url(" + SeaImage + ")" }}>
        <div className="container clearfix">
          <FormWrapper
            className="col-12 col-md-12 col-lg-6 float-end wow position-relative animated"
            data-wow-offset="250"
            style={{ visibility: "visible" }}
          >
            <div className="block-reveal">
              <div className="block-vertical"></div>
              <div className="box_1" style={{ backgroundColor: "unset" }}>
                <Title>{t("mainSliderButton")}</Title>
                <div className="row mt-4 home-form-input">
                  <div className="col-12 col-lg-6 mt-4">
                    <Input
                      id="IdHere"
                      label={t("allFormFullName")}
                      type="text"
                      placeholder={t("allFormFullName")}
                      required={false}
                      error={false}
                      helperText=""
                      value={fullName}
                      onChange={(value) => setFullName(value)}
                    />
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <Input
                      id="IdHere"
                      label={t("allFormPhone")}
                      type="number"
                      placeholder={t("allFormPhoneExample")}
                      required={false}
                      error={false}
                      helperText=""
                      value={phone}
                      onChange={(value) => setPhone(value)}
                    />
                  </div>
                  <div className="col-12 col-lg-6 mt-4">
                    <Input
                      id="IdHere"
                      label={t("allFormEmail")}
                      type="text"
                      placeholder={t("allFormEmailExample")}
                      required={false}
                      error={false}
                      helperText=""
                      value={userEmail}
                      onChange={(value) => setUserEmail(value)}
                    />
                  </div>

                  <div className="col-12 col-lg-6 mt-4">
                    <CustomSelect
                      id="1"
                      label={t("Theme")}
                      value={selectedValue}
                      options={[
                        ...topic.map((s) => ({
                          value: s.id,
                          label: s.title,
                        })),
                      ]}
                      onChange={(value) => setSelectedValue(value)}
                    />
                  </div>

                  <div className="col-12 mt-5">
                    <button disabled={disableSeenFormBtn} onClick={sendForm} className="btn btn-danger col-12">
                      {t("allFormSendButton")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </FormWrapper>
        </div>
      </div>

      <ToastContainer position="top-center" />
    </div>
  );
};

const FormWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
`;
const Title = styled.h3`
  color: ${Colors.Black};
  font-family: ${Fonts.FiraGoBold};
`;

export default Home;
