import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import { MessengerChat } from "react-messenger-chat-plugin";
import 'react-toastify/dist/ReactToastify.css';

// fot messenger chat
const currentLang = localStorage.getItem('i18nextLng') === 'ge' ? 'ka-GE' : 'en_US';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
    <MessengerChat
      pageId="138684196233413"
      language={currentLang}
      themeColor={"#5385A3"}
      bottomSpacing={10}
      loggedInGreeting="Logged In Voyager"
      loggedOutGreeting="Logged Out Voyager"
      greetingDialogDisplay={"show"}
      debugMode={true}
      onMessengerShow={() => {
        console.log("onMessengerShow");
      }}
      onMessengerHide={() => {
        console.log("onMessengerHide");
      }}
      onMessengerDialogShow={() => {
        console.log("onMessengerDialogShow");
      }}
      onMessengerDialogHide={() => {
        console.log("onMessengerDialogHide");
      }}
      onMessengerMounted={() => {
        console.log("onMessengerMounted");
      }}
      onMessengerLoad={() => {
        console.log("onMessengerLoad");
      }}
    />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
