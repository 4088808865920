import { TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../assets/Colors";

const Input: React.FC<{
  id: string;
  label: string;
  type?: string;
  placeholder?: string;
  value: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  onChange: (value: string) => void;
  fontSize?:number;
  border?: boolean
}> = ({
  id,
  type ,
  placeholder ,
  label,
  value,
  onChange,
  required = false,
  error,
  helperText,
  className,
  fontSize = 13,
  border = true
}) => {
    return (
      <>
        <TextFieldStyled
          fullWidth
          id={id}
          label={label}
          variant="outlined"
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          required={required}
          error={error}
          helperText={helperText}
          className={className}
          neededColor={'red'}
          fontSize={fontSize}
          border= {border}
        />
      </>
    );
  };

  const TextFieldStyled = styled(TextField)<{ neededColor: string, fontSize: number, border: boolean }>((params) => ({
    '&:.MuiTextField-root': {
      borderColor: Colors.Black,
    },
    'fieldset': {
      border: params.border === true ? '1px solid rgba(0, 0, 0, 0.23);' : 'unset'
    },
    'input': {
      fontSize: params.fontSize
    }
  }))

export default Input;