import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../store';
import { AuthState } from '../../store/authSlice';
import DocumentTitle from '../Admin_components/DocumentTitle';
import Loader from '../../components/Loader';
import api from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import Fonts from '../../assets/Fonts';
import Colors from '../../assets/Colors';

const Admin_dashboard = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const [logout] = api.useLogoutMutation();
  const {
    data: adminMenuItems,
    isFetching: isAdminMenuItemsLoading,
    refetch: refetchAdminMenuItems,
  } = api.useGetAdminMenuItemsQuery(undefined);

  // logout
  const AdminLogout = async () => {
    try {
      await logout({}).unwrap();
      navigate(`/admin`);
    } catch (error) {
      // most likely error reason: token was already expired
      console.log("logout error", error);
      navigate(`/admin`);
    }
  };
  // logout

  return (
    <Wrapper>
      {adminMenuItems?.map((am, index) => (
        <Box className="menu-item" key={am.id} onClick={() => navigate(`${am.url}`)}>
          <span className="menu-title text-center d-block">{am.title}</span>
        </Box>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
`;
const Box = styled.div`
  cursor: pointer;
  width: 160px;
  height: 90px;
  border: 1px solid ${Colors.Primary};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: ${Fonts.FiraGoRegular};
  transition: all 200ms ease-in;
  margin-right: 20px;
  margin-bottom: 20px;
  span {
    color: #727272;
  }

  &:hover {
    box-shadow: 0px 0px 19px -9px rgba(90,135,168,0.75);
    -webkit-box-shadow: 0px 0px 19px -9px rgba(90,135,168,0.75);
    -moz-box-shadow: 0px 0px 19px -9px rgba(90,135,168,0.75);
    transform: scale(1.1);
    transition: all 200ms ease-in;
  }
`;

export default Admin_dashboard