import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logoSvg from "../assets/img/logo.svg";
import logoStickySvg from "../assets/img/logo_sticky.svg";
import { IoIosArrowForward } from "react-icons/io";
import Dropdown from "react-bootstrap/Dropdown";

import { Link } from "react-router-dom";

import MainLogoNormal from "../assets/img/logo/logo-normal.svg";
import MainLogoSticky from "../assets/img/logo/logo-sticky.svg";
import Fonts from "./../assets/Fonts";
import i18next from "i18next";
import i18n from "../i18n";
import Colors from "../assets/Colors";
import api from "../api";
import { EnabledLanguage, MenuItem } from "../domain/types";
import { useSelector } from "react-redux";
import Responsive from "../assets/Responsive";
import { setLanguage } from "../store/appSlice";
import { useAppSelector, useAppDispatch } from "../store/index";

const Header: React.FC = () => {
  const [selectedLang, setselectedLang] = useState("ge");

  const {
    data: mainMenuItems = [],
    isFetching: isMainMenuItemsLoading,
    refetch: refetchMainMenuItems,
  } = api.useGetMainMenuItemsQuery(undefined);
  const {
    data: language,
    isFetching: isLangLoading,
    refetch: refetchLang,
  } = api.useGetTourDetailedQuery(selectedLang);

  const Pathname = window.location.pathname;
  const [activeBackground, setactiveBackground] = useState(false);

  const changeBackground = () => {
    let selectSlider = Pathname == "/" ? window.innerHeight - 100 : 90;
    if (window.scrollY >= selectSlider) {
      setactiveBackground(true);
    } else {
      setactiveBackground(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  const [burgerMenuActive, setBurgerMenuState] = useState(false);

  const [openSubmenu, setOpenSubmenu] = useState(false);

  const [openSubmenuSecond, setOpenSubmenuSecond] = useState(false);

  const closeAllMenus = () => {
    setOpenSubmenu(false);
    setOpenSubmenuSecond(false);
    setBurgerMenuState(false);
  };

  const [getId, setGetId] = useState<number>();
  const [getSubId, setGetSubId] = useState<number>();

  const level1SelectedMenu = mainMenuItems.find(
    (x) => x.id === getId && x.subMenu
  );
  const level2SelectedMenu = level1SelectedMenu?.subMenu.find(
    (x) => x.id === getSubId && x.subMenu
  ); //TODO Levan Madurashvili

  // console.log("parent id " + getId)
  // console.log("children id " + getSubId)

  const { lang } = useAppSelector((root) => root.app);

  const dispatch = useAppDispatch();

  return (
    <HeaderWrapper
      className={
        activeBackground ? "header menu_fixed sticky" : "header menu_fixed"
      }
    >
      <div id="logo">
        <Link to="/">
          <MainLogoStyle
            src={MainLogoNormal}
            width="100"
            alt=""
            className="logo_normal"
          />
          <img
            src={MainLogoSticky}
            width="135"
            alt=""
            className="logo_sticky"
          />
        </Link>
      </div>
      <a href="#menu" className="btn_mobile">
        <div
          onClick={() => setBurgerMenuState(!burgerMenuActive)}
          className={`hamburger hamburger--spin ${
            burgerMenuActive ? "is-active" : ""
          }`}
          id="hamburger"
        >
          <BurgerMenuButton
            className={`hamburger-box ${
              burgerMenuActive
                ? "position-absolute end-0 top-0 mx-3 my-3 user-select-0"
                : ""
            }`}
          >
            <BuergerMenu
              className={
                activeBackground ? "hamburger-inner mobile" : "hamburger-inner"
              }
            ></BuergerMenu>
          </BurgerMenuButton>
        </div>
      </a>
      <nav id="menu" className="main-menu ms-auto">
        <ul>
          {mainMenuItems.map((m) => (
            <ParentLi key={m.id}>
              <span>
                <MainLink
                  to={m.url}
                  className={activeBackground ? "changeColor" : ""}
                >
                  {m.title}
                </MainLink>
              </span>
              {m.subMenu.length > 0 ? (
                <ul>
                  {m.subMenu.map((sub) => (
                    <Li key={sub.id}>
                      <MainLinkChildren to={sub.url}>
                        {sub.title}{" "}
                        {sub.subMenu.length > 0 ? <LsSubMenuIcon /> : null}{" "}
                      </MainLinkChildren>
                      <ul>
                        {sub.subMenu.length > 0 ? (
                          <>
                            {m.subMenu.map((s) => (
                              <>
                                {s.subMenu.map((ls) => (
                                  <Li key={ls.id}>
                                    <MainLinkChildren to={ls.url}>
                                      {ls.title}
                                    </MainLinkChildren>
                                  </Li>
                                ))}
                              </>
                            ))}
                          </>
                        ) : null}
                      </ul>
                    </Li>
                  ))}
                </ul>
              ) : null}
            </ParentLi>
          ))}

          <Dropdown>
            <Dropdown.Toggle variant="transparent">
              <DropDownIcon
                className={`icon_globe ${
                  activeBackground ? "changeColor" : ""
                }`}
              ></DropDownIcon>
              <SelectedLangInt
                style={{ textTransform: "uppercase", marginTop: "2px" }}
              >
                {lang}
              </SelectedLangInt>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                value="en"
                onClick={() => {
                  // i18n.changeLanguage('en');
                  dispatch(setLanguage("en"));
                }}
              >
                EN
              </Dropdown.Item>
              <Dropdown.Item
                value="ka"
                onClick={() => {
                  // i18n.changeLanguage('ge');
                  dispatch(setLanguage("ge"));
                }}
              >
                GE
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </nav>
      {/* mobile burger menu */}
      <div
        className={` ${
          burgerMenuActive
            ? "mm-opened mm-blocking mm-background mm-opening"
            : ""
        }`}
      >
        <BurgerMenuNav
          id="mm-menu"
          className={`main-menu mm-menu mm-offcanvas mm-hasnavbar-bottom-1 mm-pagedim-black ${
            burgerMenuActive ? "mm-opened" : ""
          }`}
        >
          <div className="mm-panels">
            <div className="mm-panel mm-hasnavbar mm-opened" id="mm-1">
              <div className="mm-navbar">
                <a className="mm-title">MENU</a>
              </div>
              <ul className="mm-listview">
                {mainMenuItems.map((m) => {
                  return (
                    <>
                      {m.subMenu.length > 0 ? (
                        <Li key={m.id} onClick={() => setGetId(m.id)}>
                          <em className="mm-counter">
                            {m.subMenu.length > 0 ? m.subMenu.length : null}
                          </em>
                          <Link
                            className="mm-next mm-fullsubopen"
                            to={m.subMenu.length > 0 ? "#mm-2" : m.url}
                            aria-owns="mm-2"
                            aria-haspopup="true"
                            onClick={() => setOpenSubmenu(!openSubmenu)}
                          >
                            <span className="mm-sronly">
                              Open submenu (Home)
                            </span>
                          </Link>
                          <span>
                            <a href="#0">{m.title}</a>
                          </span>
                        </Li>
                      ) : (
                        <Li>
                          <span>
                            <Link onClick={closeAllMenus} to={m.url}>
                              {m.title}
                            </Link>
                          </span>
                        </Li>
                      )}
                    </>
                  );
                })}
              </ul>
              <MobileLangDropdown>
                <Dropdown.Toggle variant="transparent">
                  <DropDownIcon
                    className={`icon_globe ${
                      activeBackground ? "changeColor" : ""
                    }`}
                  ></DropDownIcon>
                  <span
                    style={{ textTransform: "uppercase", marginTop: "2px" }}
                  >
                    {lang}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    value="en"
                    onClick={() => {
                      // i18n.changeLanguage('en');
                      dispatch(setLanguage("en"));
                    }}
                  >
                    EN
                  </Dropdown.Item>
                  <Dropdown.Item
                    value="ka"
                    onClick={() => {
                      // i18n.changeLanguage('ge');
                      dispatch(setLanguage("ge"));
                    }}
                  >
                    GE
                  </Dropdown.Item>
                </Dropdown.Menu>
              </MobileLangDropdown>
            </div>

            <div
              className={`mm-panel mm-hasnavbar ${
                openSubmenu ? "mm-opened" : "mm-hidden"
              }`}
              id="mm-2"
              aria-hidden="true"
            >
              <div className="mm-navbar">
                <a
                  className="mm-btn mm-prev"
                  href="#mm-1"
                  aria-owns="mm-1"
                  aria-haspopup="true"
                  onClick={() => setOpenSubmenu(!openSubmenu)}
                >
                  <span className="mm-sronly">Close submenu (Home)</span>
                </a>
                <a className="mm-title" href="#mm-1" aria-hidden="true">
                  {level1SelectedMenu?.title}
                </a>
              </div>

              <ul className="mm-listview">
                {level1SelectedMenu?.subMenu.map((s) => (
                  <>
                    {s.subMenu.length > 0 ? (
                      <Li onClick={() => setGetSubId(s.id)} key={s.id}>
                        <em className="mm-counter">
                          {s.subMenu.length > 0 ? s.subMenu.length : null}
                        </em>
                        <Link
                          className="mm-next mm-fullsubopen"
                          to={s.url}
                          aria-owns="mm-3"
                          aria-haspopup="true"
                          onClick={() =>
                            setOpenSubmenuSecond(!openSubmenuSecond)
                          }
                        >
                          <span className="mm-sronly">
                            Open submenu (Sliders - Parallax)
                          </span>
                        </Link>

                        <span>
                          <Link to="#0">{s.title}</Link>
                        </span>
                      </Li>
                    ) : (
                      <Li>
                        <span>
                          <Link onClick={closeAllMenus} to={s.url}>
                            {s.title}
                          </Link>
                        </span>
                      </Li>
                    )}
                  </>
                ))}
              </ul>
            </div>

            <div
              className={`mm-panel mm-hasnavbar ${
                openSubmenuSecond ? "mm-opened" : "mm-hidden"
              }`}
              id="mm-3"
              aria-hidden="true"
            >
              <div className="mm-navbar">
                <a
                  className="mm-btn mm-prev"
                  href="#mm-2"
                  aria-owns="mm-2"
                  aria-haspopup="true"
                  onClick={() => setOpenSubmenuSecond(!openSubmenuSecond)}
                >
                  <span className="mm-sronly">
                    Close submenu (Sliders - Parallax)
                  </span>
                </a>
                <a className="mm-title" href="#mm-2" aria-hidden="true">
                  {level2SelectedMenu?.title}
                </a>
              </div>
              <ul className="mm-listview">
                {level2SelectedMenu?.subMenu.map((s) => (
                  <Li key={s.id}>
                    <Link onClick={closeAllMenus} to={s.url}>
                      {s.title}{" "}
                    </Link>
                  </Li>
                ))}
              </ul>
            </div>
          </div>
        </BurgerMenuNav>
        <BurgerMenuBackground
          id="mm-0"
          className={`mm-page mm-slideout ${
            burgerMenuActive ? "d-flex" : "d-none"
          }`}
          onClick={() => setBurgerMenuState(!burgerMenuActive)}
        ></BurgerMenuBackground>
      </div>
    </HeaderWrapper>
  );
};

const SelectedLangInt = styled.span`
  display: inline-block;
  color: ${Colors.White};
  margin-left: 2px;
`;
const MobileLangDropdown = styled(Dropdown)`
  margin-left: -5px;
`;
const MainLogoStyle = styled.img`
  transform: scale(1.3);
  margin-left: 28px;
  ${Responsive.mobile} {
    transform: unset;
  }
`;
const LsSubMenuIcon = styled(IoIosArrowForward)`
  color: #555;
  position: absolute;
  right: 10px;
  font-size: 14px;
  /* margin-top: -1px; */
`;
const BurgerMenuNav = styled.nav`
  z-index: 3 !important;
`;
const BurgerMenuBackground = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  background: ${Colors.Gray};
  opacity: 0.7;
`;

const HeaderWrapper = styled.header`
  height: 90px;
  transition: all 200ms;
  background-color: transparent;
  align-items: center;
  position: fixed;
  &.sticky {
    background-color: ${Colors.White};
    transition: all 200ms;
  }
  @media only screen and (max-width: 991px) {
    height: 55px;
  }
  #logo {
    a {
      .logo_normal {
        margin-top: 14px !important;
      }
    }
  }
`;
const MainLink = styled(Link)`
  color: ${Colors.Gray};
  font-size: 14px !important;
  font-family: ${Fonts.FiraGoRegular};
  font-weight: normal;
  &.changeColor {
    color: ${Colors.Black};
  }
`;
const BuergerMenu = styled.div`
  &.mobile {
    background-color: black;
    &::before {
      background-color: black;
    }
    &::after {
      background-color: black;
    }
  }
`;
const ParentLi = styled.li`
  font-family: ${Fonts.FiraGoRegular};
  font-weight: 300;
`;

const DropDownIcon = styled.i`
  color: ${Colors.White};
  &.changeColor {
    color: ${Colors.Black};
  }
  ${Responsive.mobile} {
    color: ${Colors.Black};
    margin-top: -2px;
    margin-right: 2px;
    margin-left: -7px;
  }
`;

const BurgerMenuButton = styled.div`
  z-index: 2;
`;
const Li = styled.li`
  font-family: ${Fonts.FiraGoBold};
`;
const MainLinkChildren = styled(Link)`
  font-family: ${Fonts.FiraGoBold};
  font-size: 12px !important;
  transition: all 300ms !important;
`;
const ChildrenTittle = styled.a`
  font-family: ${Fonts.FiraGoBold};
  font-size: 12px !important;
`;

export default Header;
