import React, { useState } from 'react';
import TopBanner from '../components/TopBanner';
import TourCard from '../components/TourCard';
import SearchImg from '../images/search-icon.svg'
import styled from 'styled-components';
import Input from '../components/Input';
import api, { uploadUrl } from '../api';
import Loader from '../components/Loader';
import { useParams } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { useTranslation } from 'react-i18next';
import Responsive from "../assets/Responsive"
import { Page, Tour } from '../domain/types';
import Fonts from '../assets/Fonts';
import { BsFilter } from 'react-icons/bs';

const Tours: React.FC = () => {
	// data import
	const { data: tours = [], isFetching: isToursLoading, refetch: refetchTours } = api.useGetToursQuery(undefined);
	const [place, setPlace] = useState('')
	const [where, setWhere] = useState('')
	const [category, setCategory] = useState('')
	const { slug = 'tours' } = useParams();
	const {
		data: page,
		isFetching: isPageLoading,
		refetch: refetchPage } = api.useGetPageQuery(slug);


	// translation import
	const { t } = useTranslation();



	// search field
	const [searchField, setSearchField] = useState<string>("");

	// filters
	const search = (page: Tour[]) => {
		return page.filter((item) =>
			item.city?.toLocaleLowerCase().includes(searchField) ||
			item.title?.toLocaleLowerCase().includes(searchField) ||
			item.country?.toLocaleLowerCase().includes(searchField)
		)
	}
	// filters



	return (

		<div>
			{isPageLoading ? <Loader /> :
				!page ? (
					<NotFound />
				) : (
					<TopBanner
						title={page.title}
						bannerBackImg={uploadUrl(page.cover)}
					/>
				)
			}



			<div className="collapse" id="collapseMap">
				<div id="map" className="map"></div>
			</div>

			<div className="container margin_60_35">
				<div className="col-lg-12">
					<FormWrapper className="row g-0 custom-search-input-2 inner my-0">
						<Distance className="col-lg-12">
							<SearchWrapperStlye className="form-group">
								<Input
									type="text"
									className=""
									placeholder={t("WhatAreYouLookingFor")}
									id=''
									label={t("searchFor")}
									required={false}
									error={false}
									value={searchField}
									onChange={(value) => setSearchField(value)}
								/>
								<FilterIconStyle />
							</SearchWrapperStlye>

						</Distance>


						{/* <Distance className="col-lg-3">
							<div className="form-group">
								<Input
									type="text"
									className=""
									placeholder={t("searchWhere")}
									id=''
									label={t("searchWhere")}
									required={false}
									error={false}
									value={where}
									onChange={(value) => setWhere(value)}
								/>
								<IconImg className="icon_pin_alt" src={LocationImg} alt="" />
							</div>
						</Distance>
						<Distance className="col-lg-3">
							<select className="wide">
								<option>All Categories</option>
								<option>Churches</option>
								<option>Historic</option>
								<option>Museums</option>
								<option>Walking tours</option>
							</select>
							<CustomSelect
								id=''
								label={t("allCategoryInput")}
								options={[
									{ value: '1', label: "ყველა კატეგორია" },
									{ value: '2', label: "ეკლესიები" },
									{ value: '3', label: "ისტორიული" },
									{ value: '4', label: "მუზეუმები" },
									{ value: '5', label: "გასეირნება" },
								]}
								value={category}
								onChange={(value) => setCategory(value)}
							/>
						</Distance>
						<Distance className="col-lg-2">
							<SubmitButton
								className="btn_search"
								value=""
								onClick={() => alert('Button 1 is clicked !')}
								circle={false}
								title={t("allSearchButton")}
								widthFluid={true}
								heightFluid={true}
							/>
						</Distance> */}
					</FormWrapper>
					{/* /row */}
				</div>


				{/* /custom-search-input-2 */}
				{isToursLoading ? <Loader /> : (
					<div className="isotope-wrapper row">
						{search(tours).map((tr, index) => (
							<div key={index} className="col-lg-4 mt-4">
								<TourCard
									topic={tr.city}
									link={'/tour/' + tr.slug}
									tourId={tr.id}
									readMore={t("allSeeAllButton")}
									img={uploadUrl(tr.poster)}
									title={tr.title}
									description={tr.shortDescription}
								/>
							</div>
						))}
						{search(tours).length < 1 ? (<SearchNotFount> {t("NotFound")}</SearchNotFount>) : null}

					</div>
				)}


			</div>
			{/* /container */}
			{/* <div className="bg_color_1">
				<div className="container margin_60_35">
					<div className="row">
						<div className="col-md-4">
							<a href="#0" className="boxed_list">
								<i className="pe-7s-help2"></i>
								<h4>გჭირდება დახმარება? დაგვიკავშირდი</h4>
								<p>როდესაც უცნობმა მბეჭდავმა ამწყობ დაზგაზე .</p>
							</a>
						</div>
						<div className="col-md-4">
							<a href="#0" className="boxed_list">
								<i className="pe-7s-wallet"></i>
								<h4>გადახდები და თანხის დაბრუნება</h4>
								<p>წიგნის საცდელი ეგზემპლარი დაბეჭდა.</p>
							</a>
						</div>
						<div className="col-md-4">
							<a href="#0" className="boxed_list">
								<i className="pe-7s-note2"></i>
								<h4>ხარისხის სტანდარტები</h4>
								<p>იგი სტანდარტად 1500-იანი წლებიდან იქცა.</p>
							</a>
						</div>
					</div>
				
				</div>
				
			</div> */}

		</div >
	);
}

const FilterIconStyle = styled(BsFilter)`
	border-radius: 3px;
    font-size: 22px;
    position: absolute;
    line-height: 50px;
    top: 16px;
    right: 1px;
    padding-right: 15px;
    display: block;
    box-sizing: content-box;
    z-index: 9;
    color: #999;
`;
const SearchNotFount = styled.div`
	font-size: 20px;
	font-family: ${Fonts.FiraGoRegular};
	min-height: 50vh;
	width: 80%;
	margin: 0 auto;
`;
const SearchWrapperStlye = styled.div`
	width: 80%;
	margin: 0 auto 30px auto !important;
`;
const IconImg = styled.img`
	border-radius: 3px;
    font-size: 18px;
    font-size: 1.125rem;
    position: absolute;
    background-color: #fff;
    line-height: 50px;
    top: 18px;
    right: 1px;
    padding-right: 15px;
    display: block;
    width: 15px;
    box-sizing: content-box;
    z-index: 9;
    color: #999;
	
`

const FormWrapper = styled.div`
	background-color: transparent !important;
	-moz-box-shadow: none !important;
	-goog-ms-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
		input{
			height: unset;
			border-right: unset;
		}
	
`
const Distance = styled.div`
	padding: 0px 5px;
		&:first-child {
			padding-left: 0px;
		}
		&:last-child {
			padding-right: 0px;
		}
	${Responsive.tablet} {
		padding: 0px;
		margin-bottom: 10px;
	}
	${Responsive.mobile} {
		padding: 0px;
		margin-bottom: 10px;
	}
`

export default Tours;
