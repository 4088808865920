import React, { useState } from "react";
import TopBanner from "../components/TopBanner";
import ContactCoverImg from "../images/contact_cover_img.jpg";
import Box from "../components/Box";
import IconImg from "../images/icon.svg";
import { useParams, Link } from "react-router-dom";
import api, { uploadUrl } from "../api";
import Loader from "../components/Loader";
import NotFound from "../components/NotFound";
import Fonts from "../assets/Fonts";
import styled from "styled-components";
import Responsive from "../assets/Responsive";
import Input from "../components/Input";
import { useTranslation } from "react-i18next";
import CustomSelect from "../components/CustomSelect";
import Colors from "../assets/Colors";
import dayjs from "dayjs";
import IconCard from "../components/IconCard";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import i18n from "../i18n";
import type { EnabledLanguage } from "../domain/types";

import RichText from "../components/RichText";

import InfoBlockCard from "../components/InfoBlockCard";

const PageDetail: React.FC = () => {
  const { slug = "" } = useParams();
  const { data: page, isFetching: isPageLoading, refetch: refetchPage } = api.useGetPageQuery(slug);
  const [Form] = api.usePostFormMutation();
  const { t } = useTranslation();

  const [submitForm, setSubmitForm] = useState("");
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [mail, setMail] = useState("");
  const [country, setCountry] = useState("");
  const [passenger, setPassenger] = useState("");
  const [date, setDate] = useState("2021-09-09");
  const [startDate, setStartDate] = useState("2021-09-09");
  const [endDate, setEndDate] = useState("2021-09-09");
  const [citizen, setCitizen] = useState("");
  const [city, setCity] = useState("");
  const [textAreaMsg, setTextAreaMsg] = useState<string>("");
  const [fullPassenger, setFullPassenger] = useState("");
  const [teenagePassenger, setTeenagePassenger] = useState("");

  // if (name.trim().length !== 0 && number.trim().length !== 0 && country.trim().length !== 0 && passenger.trim().length !== 0 && citizen.trim().length !== 0) {
  // 	console.log("ara carieli")
  // } else {
  // 	console.log("carielia")
  // }

  // current date for date inputs
  const currentDate = new Date();
  const currentDateValue = dayjs(currentDate).format('YYYY/mm/DD')

  // 
  // send  form
  const travelAboardForm = async (e: any) => {
    e.preventDefault();
    try {
      await Form({
        fullName: name,
        phone: number,
        userEmail: mail,
        topicId: page?.sentTo,
        type: "travel-aboard-form",
        citizenship: citizen,
        destination: country,
        city: city,
        description: textAreaMsg,
        fullPassenger: fullPassenger,
        teenagePassenger: teenagePassenger,
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          toast.success("ინფორმაცია წარმატებით გაიგზავნა");
        })
        .catch((err) => {
          toast.error("მოხდა შეცდომა " + err);
        });
    } catch (error) {
      toast.error("მოხდა შეცდომა " + error);
    }
  };

  // form input
  const formJsxArray = [
    {
      field: "fullName",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="fullName"
            label={t("allFormFullName")}
            className="form-control"
            type="text"
            placeholder={t("allFormFullNameExample")}
            value={name}
            required={false}
            error={false}
            onChange={(value) => setName(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "phone",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="number_review"
            label={t("allFormPhone")}
            className="form-control"
            type="number"
            placeholder={t("allFormPhoneExample")}
            value={number}
            required={false}
            error={false}
            onChange={(value) => setNumber(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "userEmail",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="email_review"
            label={t("allFormEmail")}
            className="form-control"
            type="email"
            placeholder={t("allFormEmailExample")}
            value={mail}
            required={false}
            error={false}
            onChange={(value) => setMail(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "date",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="date_review"
            label={" " + date}
            className="form-control"
            type="date"
            placeholder=""
            value={date}
            required={false}
            error={false}
            onChange={(value) => setDate(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "startDate",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="start-date_review"
            label={t("dateOfDeparture") + " " + startDate}
            className="form-control"
            type="date"
            placeholder=""
            value={startDate}
            required={false}
            error={false}
            onChange={(value) => setStartDate(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "endDate",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="end-date_review"
            label={t("dateOfArrival") + " " + endDate}
            className="form-control"
            type="date"
            placeholder=""
            value={endDate}
            required={false}
            error={false}
            onChange={(value) => setEndDate(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "destination",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="country_review"
            label={t("allFormCountry")}
            className="form-control"
            type="text"
            placeholder={t("allFormCountry")}
            value={country}
            required={false}
            error={false}
            onChange={(value) => setCountry(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "city",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="city_review"
            label={t("allFormCityInput")}
            className="form-control"
            type="text"
            placeholder={t("allFormCityInput")}
            value={city}
            required={false}
            error={false}
            onChange={(value) => setCity(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "citizenship",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <Input
            id="citizenship_review"
            label={t("allFormCitizenshipInput")}
            className="form-control"
            type="text"
            placeholder={t("allFormCitizenshipExample")}
            value={citizen}
            required={false}
            error={false}
            onChange={(value) => setCitizen(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "description",
      formJsx: (
        <div>
          <FormTextArea
            value={textAreaMsg}
            onChange={(event) => setTextAreaMsg(event.target.value)}
            placeholder={t("LeaveUsYourRequest")}
          ></FormTextArea>
        </div>
      ),
    },
    {
      field: "teenagePassenger",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <CustomSelect
            id=""
            label={t("numberOfChildrenUnder12Years")}
            options={[
              { value: "1", label: "1 " + t("children") },
              { value: "2", label: "2 " + t("children") },
              { value: "3", label: "3 " + t("children") },
              { value: "4", label: "4 " + t("children") },
              { value: "5", label: "5 " + t("children") },
              { value: "6", label: "6 " + t("children") },
              { value: "7", label: "7 " + t("children") },
              { value: "8", label: "8 " + t("children") },
              { value: "9", label: "9 " + t("children") },
              { value: "other", label: t("other") },
            ]}
            value={teenagePassenger}
            onChange={(value) => setTeenagePassenger(value)}
          />
        </FormInputWrapper>
      ),
    },
    {
      field: "fullPassenger",
      formJsx: (
        <FormInputWrapper className="form-group col-md-6">
          <CustomSelect
            id=""
            label={t("numberOfAdults")}
            options={[
              { value: "1", label: "1 " + t("adult") },
              { value: "2", label: "2 " + t("adult") },
              { value: "3", label: "3 " + t("adult") },
              { value: "4", label: "4 " + t("adult") },
              { value: "5", label: "5 " + t("adult") },
              { value: "6", label: "6 " + t("adult") },
              { value: "7", label: "7 " + t("adult") },
              { value: "8", label: "8 " + t("adult") },
              { value: "9", label: "9 " + t("adult") },
              { value: "other", label: t("other") },
            ]}
            value={fullPassenger}
            onChange={(value) => setFullPassenger(value)}
          />
        </FormInputWrapper>
      ),
    },
  ];

  const inputArray = [
    "fullName",
    "phone",
    "userEmail",
    "startDate",
    "endDate",
    "city",
    "destination",
    "fullPassenger",
    "teenagePassenger",
    "description",
  ];
  const formSplitedArray = [];

  if (page) {
    if (page.formInputs) {
      const usingSplit = page.formInputs.split(", ");
      formSplitedArray.push(usingSplit);
    }
  }

  const a = formSplitedArray.flat();
  const b = inputArray.flat();

  const formResult = b.filter((x) => a.includes(x));

  var formLastArray = formJsxArray.filter(function (event) {
    return formResult.indexOf(event.field) > -1;
  });

  // check inputs value lenght
  let emptyValidation = formLastArray.filter((x) => x.formJsx.props.children.props.value.trim() !== "");

  return (
    <div>
      {isPageLoading ? (
        <Loader />
      ) : !page ? (
        <NotFound />
      ) : (
        <div>
          <TopBanner title={page.title} bannerBackImg={uploadUrl(page.cover)} />
          {/* <InfoBlocksWrapper className="container">
								{page.infoBlocks.map((i, index) => (
									<IconCardWrapper key={index}>
										<IconCard
											icon={`${i.icon}`}
											title={`${i.title}`}
											description={`${i.description}`}
										/>
									</IconCardWrapper>
								))}
							</InfoBlocksWrapper> */}
          <div className="container margin_80_55">
            <div className="">
              <span>
                <em></em>
              </span>
              <TittleH2 className="mb-4">{page.shortTitle}</TittleH2>
              <RichText html={page.shortDescription}></RichText>
              <RichText html={page.description}></RichText>
              {slug === "travel-packages" ? (
                <MainTitleLink to="/destinations">Popular Destinations</MainTitleLink>
              ) : null}
            </div>
            {page.infoBlocks?.length ? (
              <div className="">
                <div className="main_title_2">
                  <span>
                    <em></em>
                  </span>
                  <h2>{t("servicesWeOffer")}</h2>
                </div>
                <div className="row justify-content-center">
                  {page.infoBlocks.map((block, index) => (
                    <div className="col-lg-4 col-md-6">
                      <InfoBlockCard
                        title={block[i18n.language as EnabledLanguage]?.title || ""}
                        description={block[i18n.language as EnabledLanguage]?.description || ""}
                        icon={block.icon}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            <div className="row">
              <Title>{t("leaveYourContactInfo")}:</Title>
              <InpuTWrapperDiv>
                {formLastArray.map((i, index) => {
                  return <div key={index}>{i.formJsx}</div>;
                })}
              </InpuTWrapperDiv>
              <SubmitMessage>{submitForm}</SubmitMessage>
              <SubmitFormBtn
                disabled={emptyValidation.length !== formLastArray.length ? true : false}
                onClick={travelAboardForm}
              >
                send
              </SubmitFormBtn>
              <ToastContainer position="bottom-center" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const InfoBlocksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  margin-top: 50px;

  ${Responsive.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${Responsive.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const IconCardWrapper = styled.div``;
const MainTitleLink = styled(Link)`
  font-family: FiraGO;
  font-size: 16px;
  text-decoration: underline;
`;
const SubmitMessage = styled.p`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 13px;
  color: ${Colors.Black};
`;

const TittleH2 = styled.h2`
  font-size: 28px !important;
  font-family: ${Fonts.Roboto};
  ${Responsive.mobile} {
    font-size: 22px !important;
  }
`;
const Description = styled.p`
  font-size: 18px !important;
  font-family: ${Fonts.Roboto};
  ${Responsive.mobile} {
    font-size: 16px !important;
  }
`;
const FormInputWrapper = styled.div`
  width: 100%;
`;

const InpuTWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-top: 20px;
  margin-bottom: 30px;

  ${Responsive.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-bottom: 20px;
  }
`;
const Title = styled.div`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 16px;
`;

const SubmitFormBtn = styled.button`
  border: none;
  background-color: ${Colors.Carnation};
  outline: none;
  text-decoration: none;
  padding: 15px 50px;
  color: ${Colors.White};
  font-weight: 600;
  text-align: center;
  margin-top: unset;
  line-height: 1;
  transition: all 0.3 ease-in-out;
  border-radius: 25px;
  font-family: ${Fonts.FiraGoBold};
  width: fit-content;
  margin: 0 auto;
  font-size: 16px;
  &:hover {
    opacity: 0.9;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  font-size: 12px;
  border-radius: 10px;
  padding: 13px 14px 0px 10px;
  height: 51px;
  outline: none;
  color: #555;
  border-color: rgb(191, 191, 191);
  background-color: transparent;
  &:hover {
    border-color: #555;
  }
  &:focus {
    border-color: #555;
  }
  &::placeholder {
    color: rgb(191, 191, 191);
  }
`;

export default PageDetail;
