import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../components/Button";
import Input from "../components/Input";
import TopBanner from "../components/TopBanner";
import api, { uploadUrl } from "../api";
import styled from "styled-components";
import Responsive from "../assets/Responsive";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";
import Loader from "../components/Loader";
import NotFound from "../components/NotFound";
import LightBox from "../components/LightBox";
import SimpleReactLightbox from "simple-react-lightbox";
import { useTranslation } from "react-i18next";
import IconCard from "../components/IconCard";
import { ToastContainer, toast } from "react-toastify";
import { AxiosError } from "axios";
import i18n from "../i18n";
import type { EnabledLanguage } from "../domain/types";

import RichText from "../components/RichText";

import InfoBlockCard from "../components/InfoBlockCard";

const DestinationDetails: React.FC = () => {
  const { slug = "" } = useParams();

  // export data
  const {
    data: destination,
    isFetching: isDestinationDetailsLoading,
    refetch: refetchDestinationDetails,
  } = api.useGetDestinationDetailsQuery(slug);

  const initialFormValue = { phone: "", userEmail: "" };
  const [tel, setTel] = useState("");
  const [mail, setMail] = useState("");
  const [disableGetThePrice, setDisableGetThePrice] = useState(false);
  const navigate = useNavigate();

  // translation import
  const { t } = useTranslation();
  const [Form] = api.usePostFormMutation();

  const emailRegex = /^\S+@\S+\.\S+$/;
  // send get the price form
  const GetThePriceForm = async (e: any) => {
    e.preventDefault();
    if (emailRegex.test(mail) && tel !== "") {
      try {
        await Form({
          phone: tel,
          userEmail: mail,
          topicId: 6,
          type: "get-the-price-form",
        }).unwrap();
        setDisableGetThePrice(true);
        alert("მოთხოვნა წარმატებით გაიგზავნა");
      } catch (err: any) {
        toast.error(err.data.errors[0].message);
        setDisableGetThePrice(false);
      }
    } else {
      if (tel === "") {
        toast.error("გთხოვთ მიუთითოთ სწორი ნომერი");
      }
      if (!emailRegex.test(mail)) {
        toast.error("გთხოვთ მიუთითოთ სწორი მეილი");
      }
    }
  };
  // send get the price form

  return (
    <div>
      {isDestinationDetailsLoading ? (
        <Loader />
      ) : !destination ? (
        <NotFound />
      ) : (
        <div>
          <TopBanner bannerBackImg={uploadUrl(destination.cover)} title={destination.title} />
          {/* {tourDetailed.map((t) => ( */}
          <div className="bg_color_1">
            <div className="container margin_60_35">
              {destination.infoBlocks.length > 0 && (
                <div className="mt-4">
                  <div className="row justify-content-center">
                    {destination.infoBlocks?.map((block) => (
                      <div className="col-lg-3 col-md-6">
                        <InfoBlockCard
                          icon={block.icon}
                          title={block[i18n.language as EnabledLanguage]?.title || ""}
                          description={block[i18n.language as EnabledLanguage]?.description || ""}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="container">
              {/* margin_60_35 */}
              <div className="row">
                <div className="col-lg-7">
                  <section id="description" style={{ border: "unset" }}>
                    {/* <TittleH2>მოკლე აღწერა</TittleH2> */}
                    {/* <p>Per consequat adolescens ex, cu nibh commune <strong>temporibus vim</strong>, ad sumo viris eloquentiam sed. Mea appareat omittantur eloquentiam ad, nam ei quas oportere democritum. Prima causae admodum id est, ei timeam inimicus sed. Sit an meis aliquam, cetero inermis vel ut. An sit illum euismod facilisis, tamquam vulputate pertinacia eum at.</p> */}
                    {/* <p>Cum et probo menandri. Officiis consulatu pro et, ne sea sale invidunt, sed ut sint <strong>blandit</strong> efficiendi. Atomorum explicari eu qui, est enim quaerendum te. Quo harum viris id. Per ne quando dolore evertitur, pro ad cibo commune.</p> */}
                    <Paragraph>{/* <RichText html={destination.shortDescription}></RichText> */}</Paragraph>
                    {destination.gallery?.length < 1 ? null : <TittleH3>{t("PicturesFromOurUsers")}</TittleH3>}
                    <div style={{ marginTop: "15px" }}>
                      {destination.gallery?.length < 1 ? null : (
                        <SimpleReactLightbox>
                          <LightBox images={destination.gallery} />
                        </SimpleReactLightbox>
                      )}
                    </div>
                    <hr />
                    <TittleH2>{t("description")}</TittleH2>
                    <RichText html={destination.description}></RichText>
                    {/* <h3>Program <small>(60 minutes)</small></h3> */}
                    {/* <p>
													Iudico omnesque vis at, ius an laboramus adversarium. An eirmod doctus admodum est, vero numquam et mel, an duo modo error. No affert timeam mea, legimus ceteros his in. Aperiri honestatis sit at. Eos aeque fuisset ei, case denique eam ne. Augue invidunt has ad, ullum debitis mea ei, ne aliquip dignissim nec.
												</p> */}
                    {/* <hr /> */}
                    {/* <p>Mea appareat omittantur eloquentiam ad, nam ei quas <strong>oportere democritum</strong>. Prima causae admodum id est, ei timeam inimicus sed. Sit an meis aliquam, cetero inermis vel ut. An sit illum euismod facilisis, tamquam vulputate pertinacia eum at.</p> */}
                    {/* <div className="row">
													<div className="col-lg-6">
														<ul className="bullets">
															<li>Dolorem mediocritatem</li>
															<li>Mea appareat</li>
															<li>Prima causae</li>
															<li>Singulis indoctum</li>
														</ul>
													</div>
													<div className="col-lg-6">
														<ul className="bullets">
															<li>Timeam inimicus</li>
															<li>Oportere democritum</li>
															<li>Cetero inermis</li>
															<li>Pertinacia eum</li>
														</ul>
													</div>
												</div> */}
                  </section>
                  <hr />
                </div>
                <aside className="col-lg-5" id="sidebar">
                  <div className="add-review">
                    <h5 style={{ marginBottom: "10px" }}>{t("checkPriceTitle")}</h5>
                    <form>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <Input
                            id="name_review"
                            type="number"
                            placeholder={t("allFormPhoneExample")}
                            value={tel}
                            error={false}
                            required={false}
                            className="form-control"
                            onChange={(value) => setTel(value)}
                            label={t("allFormPhone")}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <Input
                            id="email_review"
                            type="email"
                            placeholder={t("allFormEmailExample")}
                            value={mail}
                            error={false}
                            required={false}
                            className="form-control"
                            onChange={(value) => setMail(value)}
                            label={t("allFormEmail")}
                          />
                        </div>

                        <div className="form-group col-md-12 add_top_20">
                          <GetPriceButton disabled={disableGetThePrice} onClick={GetThePriceForm}>
                            {t("demand")}
                          </GetPriceButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </aside>
              </div>
              {/* /row */}
            </div>
            {/* /container */}
          </div>
        </div>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

const GetPriceButton = styled.button`
  border: none;
  background-color: ${Colors.Carnation};
  outline: none;
  text-decoration: none;
  padding: 15px 30px;
  color: ${Colors.White};
  font-weight: 600;
  text-align: center;
  margin-top: unset;
  line-height: 1;
  transition: all 0.3 ease-in-out;
  border-radius: 0px;
  font-family: ${Fonts.FiraGoBold};
`;

const GridWrapper = styled.div`
  display: grid;
  grid-row-gap: 15px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  overflow: hidden;
  /* mobile resolution gallery popup dont show more than 2 elements and give second element count background */
  ${Responsive.mobile} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    &:nth-child(1) {
      div:nth-child(n + 3) {
        display: none;
      }
      div:nth-child(2) {
        button {
          div {
            display: flex;
          }
        }
      }
    }
  }
  /* desktop resolution gallery popup dont show more than 4 elements and give number 4 element count background */
  &:nth-child(1) {
    div:nth-child(n + 5) {
      display: none;
    }
    div:nth-child(4) {
      button {
        div {
          display: flex;
        }
      }
    }
  }
`;
const TittleH2 = styled.h2`
  font-family: ${Fonts.FiraGoBold};
  color: ${Colors.Black};
`;
const TittleH3 = styled.h3`
  font-family: ${Fonts.FiraGoBold};
  color: ${Colors.Black};
`;
const Paragraph = styled.p`
  font-family: ${Fonts.FiraGoRegular};
`;
// const GalleryButton = styled.button`
// 	position: relative;
// 	border: 0;
// 	outline: 0;
// 	height: inherit;
// 	width: inherit;
// 	padding: 0 ;
// `
// const GalleryWrapper = styled.div`
// 	height: 120px;
// 	width: 100%;
// 	${Responsive.mobile}{
// 		height: unset;
// 		width: unset;
// 		min-height: 93px;
// 		max-height: 200px;
// 	}
// `
// const GalleryPopupCounter = styled.div`
// 	display: none;
// 	top: 0;
// 	left: 0;
// 	transition: .5s;
// 	flex-grow: 1;
// `
// const GalleryCounterOpacity = styled.div`
// 	background-color: black;
// 	opacity: 0.5;
// 	height: inherit;
// 	width: inherit;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	justify-content: center;
// 	align-items: center;
// 	height: 100%;
// 	width: 100%;
// `
// const GalleryCounterText = styled.div`
// 	position: absolute;
// 	color: white;
// 	top: 0;
// 	left: 0;
// 	height: 100%;
// 	width: 100%;
// 	justify-content: center;
// 	align-items: center;
// `

export default DestinationDetails;
