import React from "react";
import RequestCallbackForm from '../components/RequestCallbackForm';
import TopBanner from "../components/TopBanner";
import ContactCoverImg from '../images/contact_cover_img.jpg'
import Map from '../components/Map'
import styled from "styled-components";
import Colors from "../assets/Colors";
import Fonts from "../assets/Fonts";

const RequestOther: React.FC = () => {
  return (
    <div>
      <TopBanner
        title='სხვა'
        bannerBackImg={ContactCoverImg}
      />
      {/*/hero_in*/}

      <div className="container">
      <OtherUl className="px-3 mt-4">
        <Li className="mt-1">ტრანსფერი</Li>
        <Li className="mt-1">სამოგზაურო დაზღვევა</Li>
        <Li className="mt-1">მატარებლის / ავტობუსის ბილეთები</Li>
        <Li className="mt-1">კონცერტის ბილეთები</Li>
        <Li className="mt-1">ადგილობრივი ტურების დაგეგმვა</Li>
        <Li className="mt-1">მუზეუმის ბილეთები</Li>
        <Li className="mt-1">სხვა</Li>
      </OtherUl>


      <RequestCallbackForm 
        showFullNameInput={true}
        showNumberInput={true}
        showEmailInput={true}
      />

      </div>
      
    </div>
  );
};
export default RequestOther;

const OtherUl = styled.ul`
    list-style-type: circle;
    color: ${Colors.Gray};
`;
const Li = styled.li`
    font-size: 15px;
    font-family: ${Fonts.FiraGoBold};
`;
