import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AdminUser } from '../domain/types'
import type { RootState } from '.'
import api from '../api'
import { getAuthToken, setAuthToken } from './tokenStorage'

export type AuthState = {
  user: AdminUser | null
  userLoading: boolean
  token: string | null
}

const authTokenFromStorage = getAuthToken();
const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: authTokenFromStorage,
    userLoading: !!authTokenFromStorage,
    searchKeyword: '',
  } as AuthState,
  reducers: {
    setCredentials: (// for manually setting logged-in credentials
      state: AuthState,
      { payload: { user, token } }: PayloadAction<{ user: AdminUser; token: string }>
    ) => {
      state.user = user
      state.userLoading = false
      state.token = token
      setAuthToken(token)
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(// remember token in localStorage after successful login
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
        state.userLoading = false
        setAuthToken(payload.token)
      }
    ).addMatcher(// clear logged-in user and localStorage after logout
      api.endpoints.logout.matchFulfilled,
      (state, { payload }) => {
        state.token = null
        state.user = null
        state.userLoading = false
        setAuthToken('')
      }
    ).addMatcher(// remember token in localStorage after successful login
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
        state.userLoading = false
        setAuthToken(payload.token)
      }
    ).addMatcher(// mark user as loading while logging in
      api.endpoints.profile.matchPending,
      (state, { payload }) => {
        state.userLoading = true
      }
    ).addMatcher(// set user in redux after auto-login from localStorage.token
      api.endpoints.profile.matchFulfilled,
      (state, { payload }) => {
        state.user = payload
        state.userLoading = false
      }
    ).addMatcher(// if auto-login (localStorage.token => dispatch(profile)) failed
      api.endpoints.profile.matchRejected,
      (state, { payload }) => {
        state.token = null
        state.user = null
        state.userLoading = false
        console.log("fetching user data failed, auto logging out ")
        setAuthToken('')
      }
    )
  },
})

export const { setCredentials } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user
