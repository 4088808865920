import React, { useState } from "react";
import TopBanner from "../components/TopBanner";
import ContactCoverImg from "../images/contact_cover_img.jpg";
import Map from "../components/Map";
import Input from "../components/Input";
import SubmitButton from "../components/Button";
import Textarea from "../components/Textarea";
import Fonts from "../assets/Fonts";
import styled from "styled-components";
import api, { uploadUrl } from "../api";
import Loader from "../components/Loader";
import Colors from "../assets/Colors";
import NotFound from "../components/NotFound";
import { useTranslation } from "react-i18next";
import Responsive from "../assets/Responsive";
import { IoIosTimer } from "react-icons/io";
import MetaDecorator from "../components/MetaDecorator";
import { Helmet } from 'react-helmet';

const Contact: React.FC = () => {
  const {
    data: contact,
    isFetching: isContactLoading,
    refetch: refetchContact,
  } = api.useGetContactQuery(undefined);
  const [Form] = api.usePostFormMutation();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");

  // translation import
  const { t } = useTranslation();

  const [submitForm, setSubmitForm] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const DisableBtn =
    name.trim() === "" ||
    surname.trim() === "" ||
    message.trim() === "" ||
    mail.trim() === "" ||
    tel.trim() === "";

  // send contact form
  const contactForm = async (e: any) => {
    e.preventDefault();
    try {
      await Form({
        fullName: name + " " + surname,
        phone: tel,
        userEmail: mail,
        description: message,
        topicId: 6,
        type: "contact-form",
      });
      setSubmitForm("ინფორმაცია წარმატებით გაიგზავნა");
      console.log("contact form submit");
      setDisableButton(true);
    } catch (error) {
      setSubmitForm("გთხოვთ სცადოთ თავიდან ");
      setDisableButton(false);
      console.log("login error", error);
    }
  };

  return (
    <>
      <div>
        {isContactLoading ? (
          <Loader />
        ) : !contact ? (
          <NotFound />
        ) : (
          <>
            <TopBanner
              title={contact.title}
              bannerBackImg={uploadUrl(contact.cover)}
            />
            <div key={contact.id} className="contact_info">
              <div className="container">
                <ContactCardUl className="clearfix">
                  <li>
                    <i className="pe-7s-map-marker"></i>
                    <LabelMini>{t("address")}</LabelMini>
                    <SpanMini>
                      {contact.address}
                      <br />
                      {contact.city}
                    </SpanMini>
                  </li>
                  <li>
                    <i className="pe-7s-mail-open-file"></i>
                    <LabelMini>{t("allFormEmail")}</LabelMini>
                    <SpanMini>
                      {contact.email}
                      <br />
                      <small> {contact.workingHours}</small>
                    </SpanMini>
                  </li>
                  <li>
                    <i className="pe-7s-phone"></i>
                    <LabelMini>{t("mobileContact")}</LabelMini>
                    <SpanMini>
                      +{contact.phone}
                      <br />
                      <small> {contact.workingHours}</small>
                    </SpanMini>
                  </li>
                  <li>
                    <TimeIcon />
                    <LabelMini>{t("workDay")}</LabelMini>
                    <SpanMini>
                      {contact.mondayFriday + " / " + contact.weekend}
                      <br />
                      {/* <small> {contact.mondayFriday + ' / ' + contact.weekend}</small> */}
                    </SpanMini>
                  </li>
                </ContactCardUl>
              </div>
            </div>
          </>
        )}

        {/*/contact_info*/}

        <div className="bg_color_1">
          <div className="container margin_80_55">
            <div className="row justify-content-between">
              <div className="col-lg-5">
                <div className="map_contact">
                  <Map />
                </div>
              </div>
              <div className="col-lg-6">
                <Title>{t("contactFormTitle")}</Title>
                <Text>{t("contactFormDescription")}</Text>
                <div id="message-contact"></div>
                <form id="contactform">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          id="name_contact"
                          label={t("mainFormFirstName")}
                          type="text"
                          placeholder={t("mainFormFirstNameExample")}
                          value={name}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setName(value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          id="lastname_contact"
                          label={t("mainFormLastName")}
                          type="text"
                          placeholder={t("mainFormLastNameExample")}
                          value={surname}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setSurname(value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /row */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          id="email_contact"
                          label={t("allFormEmail")}
                          type="text"
                          placeholder={t("allFormEmailExample")}
                          value={mail}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setMail(value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Input
                          id="phone_contact"
                          label={t("allFormPhone")}
                          type="textarea"
                          placeholder={t("allFormPhoneExample")}
                          value={tel}
                          required={false}
                          error={false}
                          helperText=""
                          onChange={(value) => setTel(value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /row */}
                  <div className="form-group">
                    <Label>{t("contactFormSecondTitle")}</Label>
                    <Textarea
                      id="message_contact"
                      name="message_contact"
                      className="form-control"
                      placeholder={t("allEnterTextInput")}
                      value={message}
                      rows={50}
                      cols={50}
                      onChange={(value) => setMessage(value)}
                    />
                  </div>
                  <Message>{submitForm}</Message>
                  <p className="add_top_30">
                    <SubmitBtn
                      disabled={
                        DisableBtn
                          ? true
                          : false || DisableBtn === false
                          ? disableButton
                          : true
                      }
                      style={{
                        opacity: DisableBtn || disableButton ? 0.8 : "unset",
                      }}
                      onClick={contactForm}
                    >
                      {t("allFormSendButton")}
                    </SubmitBtn>
                  </p>
                </form>
              </div>
            </div>
            {/* /row */}
          </div>
          {/* /container */}
        </div>
        {/* /bg_color_1 */}
      </div>
    </>
  );
};

const ContactCardUl = styled.ul`
  display: flex;
  ${Responsive.mobile} {
    flex-direction: column;
  }
  justify-content: space-between;
  align-items: flex-start;
`;
const TimeIcon = styled(IoIosTimer)`
  color: #fff;
  font-size: 47px;
  height: 47px;
`;

const Message = styled.span`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 13px;
  color: ${Colors.Black};
  display: inline-block;
  margin-left: auto;
`;

const Label = styled.label`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 13px;
  color: ${Colors.Black};
  font-weight: unset;
  margin-bottom: 5px;
`;
const Title = styled.h4`
  font-family: ${Fonts.FiraGoBold};
  color: ${Colors.Black};
  font-size: 24px;
`;
const Text = styled.p`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 13px;
  color: ${Colors.Black};
`;
const SubmitBtn = styled.button`
  border: none;
  background-color: ${Colors.Carnation};
  outline: none;
  text-decoration: none;
  padding: 15px 30px;
  color: ${Colors.White};
  font-weight: 600;
  text-align: center;
  margin-top: unset;
  line-height: 1;
  transition: all 0.3 ease-in-out;
  border-radius: 25px;
  font-family: ${Fonts.FiraGoBold};
  ${Responsive.mobile} {
    width: 100%;
  }
`;
const SpanMini = styled.span`
  ${Responsive.mobile} {
    font-size: 10px;
  }
`;
const LabelMini = styled.h4`
  ${Responsive.mobile} {
    font-size: 10px;
  }
`;

export default Contact;
