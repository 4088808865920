import React from "react";
import TopBanner from "../components/TopBanner";
import ContactCoverImg from '../images/contact_cover_img.jpg'
import Map from '../components/Map'
import RequestCallbackForm from "../components/RequestCallbackForm";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";
import styled from "styled-components";

const RequestHotels: React.FC = () => {
  return (
    <div>
      <TopBanner
        title='სასტუმროს დაჯავშნვა'
        bannerBackImg={ContactCoverImg}
      />
      {/*/hero_in*/}

      <div className="container">
        <HotelsTittle >
          კომპანია ვოიაჟერი გთავაზობთ სასტუმროებს მსოფლიოს მასშტაბით.
        </HotelsTittle>

        <RequestCallbackForm
          showFullNameInput={true}
          showCitizenshipInput={true}
          showNumberInput={true}
          showEmailInput={true}
          showDirectionSelector={true}
          showDateInput={true}
          showCityInput={true}
        />

      </div>

    </div>
  );
};
export default RequestHotels;

const HotelsTittle = styled.p`
    font-family: ${Fonts.FiraGoRegular};
    font-size: 17px;
    color: ${Colors.Black};
    text-align: center;
    margin-top: 20px;
`;
