import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SubmitButton from '../components/Button';
import Input from '../components/Input';
import TopBanner from '../components/TopBanner';
import BackImg from '../images/about_cover_img.jpg'
import api, { uploadUrl } from '../api';
import Tours from './Tours';
import styled from 'styled-components';
// import Fancybox from '../components/Fancybox';
import ContactCoverImg from '../images/contact_cover_img.jpg'

import Responsive from "../assets/Responsive";
import Fonts from '../assets/Fonts';
import Colors from '../assets/Colors';
import Loader from '../components/Loader';
import NotFound from '../components/NotFound';
import LightBox from '../components/LightBox';

import SimpleReactLightbox from "simple-react-lightbox";


const NewsDetailed: React.FC = () => {

    const { slug = '' } = useParams();

    // export data
    const {
        data: news,
        isFetching: isNewsDetailedLoading,
        refetch: refetchNewsDetailed } = api.useGetNewsDetailedQuery(slug);

    // catch tourdetailed all image count for display it as extra images in popup gallery 
    const x = news?.cover?.length;



    return (
        <div>
            {isNewsDetailedLoading ? <Loader /> :
                !news ? (
                    <NotFound />
                ) :
                    (
                        <div>
                            <TopBanner
                                bannerBackImg={uploadUrl(news.cover)}
                                title={news.title}
                            />
                            <div className="bg_color_1">
                                <div className="container margin_60_35">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <section id="description">
                                                {news.description}
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
        </div>

    );
}

const GridWrapper = styled.div`
	display: grid;
	grid-row-gap: 15px;
	grid-column-gap: 20px;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 1fr;
	overflow: hidden;
	/* mobile resolution gallery popup dont show more than 2 elements and give second element count background */
	${Responsive.mobile}{
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
		&:nth-child(1) {
		div:nth-child(n + 3) {
			display: none;
			
		}
		div:nth-child(2) {
			button {
				div {
					display: flex;
				}
			}
		}
	}
	}
	/* desktop resolution gallery popup dont show more than 4 elements and give number 4 element count background */
	&:nth-child(1) {
		div:nth-child(n + 5) {
			display: none;
		}
		div:nth-child(4) {
			button {
				div {
					display: flex;
				}
			}
		}
	}
`
const TittleH2 = styled.h2`
	font-family: ${Fonts.FiraGoBold};
	color: ${Colors.Black};
`
const TittleH3 = styled.h3`
	font-family: ${Fonts.FiraGoBold};
	color: ${Colors.Black};
`


export default NewsDetailed;
