import React from 'react';
import aboutOriginImg from "../images/about_origin_img.jpg";
import TopBanner from '../components/TopBanner';
import AboutCoverImg from '../images/about_cover_img.jpg'
import FounderSlider from '../components/FounderSlider';
import News from '../components/News';
import api, { uploadUrl } from '../api';
import Loader from '../components/Loader';
import styled from 'styled-components';
import Fonts from '../assets/Fonts';
import Colors from '../assets/Colors';
import NotFound from '../components/NotFound';
import Responsive from "../assets/Responsive"
import RichText from '../components/RichText';

const About: React.FC = () => {
	const { data: AboutUs, isFetching: isAboutUsLoading, refetch: refetchAboutUs } = api.useGetAboutUsQuery(undefined);
	return (
		<div>

			{isAboutUsLoading ? <Loader /> :
				!AboutUs ? (
					<NotFound />
				) :
					(
						<div>
							<TopBanner
								title={AboutUs.title}
								bannerBackImg={uploadUrl(AboutUs.image)}
							/>
							<div className="bg_color_1">
								<div className="container margin_80_55">
									<div className="main_title_2">
										<span><em></em></span>
										<TitleText>{AboutUs.title}</TitleText>
									</div>
									<TextWrapper className="row">
										<div>
											<RichText html={AboutUs.description} />
										</div>
									</TextWrapper>
								</div>
							</div>
						</div>

					)}


			{/* <FounderSlider /> */}
			<News />
		</div>
	);
}

const DescrText = styled.p`
	font-family: ${Fonts.FiraGoBold};
	font-size: 15px !important;
	color: ${Colors.Gray};
`
const TitleText = styled.h3`
	font-family: ${Fonts.FiraGoBold};
	font-size: 24px !important;
	color: ${Colors.Black};
	${Responsive.mobile}{
		font-size: 20px !important;
	}
	${Responsive.tablet}{
		font-size: 21px !important;
	}

`
const TextWrapper = styled.div`
	justify-content: center;
	text-align: center;
`

export default About;
