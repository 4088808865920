import React, { useState } from "react";
import styled from "styled-components";
import api, { uploadUrl } from "../../api";
import Colors from "../../assets/Colors";
import Fonts from "../../assets/Fonts";
import { EnabledLanguage, InfoBlock } from "../../domain/types";
import { BsPlus } from 'react-icons/bs';
import { AiOutlineCloudUpload } from 'react-icons/ai';
// 
const updateInfoBlockIcon = (infoBlocks: InfoBlock[], updateAtIndex: number, newIcon: string) => {
  return infoBlocks?.map((ib, indexIb) => ({
    ...ib,
    icon: indexIb == updateAtIndex ? newIcon : ib.icon,
  }));
};
const updateInfoBlockField = (
  infoBlocks: InfoBlock[],
  language: EnabledLanguage,
  updateAtIndex: number,
  fieldName: "title" | "description",
  value: any
) => {
  return infoBlocks?.map((ib, indexIb) => ({
    ...ib,
    [language]: {
      ...ib[language],
      [fieldName]: indexIb == updateAtIndex ? value : ib[language]?.[fieldName],
    },
  }));
};

const SingleBlockEditor = ({
  block,
  blockIndex,
  infoBlocks,
  setInfoBlocks,
  language,
}: {
  block: InfoBlock;
  blockIndex: number;
  language: EnabledLanguage;
  infoBlocks: InfoBlock[];
  setInfoBlocks: (_: InfoBlock[]) => void;
}) => {
  const [previewTempUrl, setPreviewTempUrl] = useState("");
  const [uploadFile, { isLoading: isUploadFileLoading, error: uploadFileError }] = api.useAdminUploadFileMutation();
  return (
    <FieldsetStyle>
      <UploadWrapper>
        <BlockImg src={uploadUrl(previewTempUrl || block.icon)} />
        {previewTempUrl || block.icon ? null : (<CloudIconWrapper><AiOutlineCloudUploadStyle /></CloudIconWrapper>)}
        <UploadInput
          type="file"
          accept="image/*"
          onChange={async (event) => {
            const files = event.target.files;
            if (!files || !files.length) {
              return;
            }
            try {
              /** for now only single file upload */
              const file = files[0];
              // preview photo before upload
              setPreviewTempUrl(URL.createObjectURL(file));
              const uploadResult = await uploadFile({
                file,
              }).unwrap();
              if (uploadResult.path) {
                setInfoBlocks(updateInfoBlockIcon(infoBlocks, blockIndex, uploadResult.path));
                // preview photo AFTER upload
                setPreviewTempUrl(uploadResult.path);
              } else {
                throw new Error("upload failed");
              }
            } catch (error) {
              console.log("error while uploading file", error);
              alert((error as Error).message?.[0] ?? "ფაილის ატვირთვა ვერ მოხერხდა.");
            }
          }}
        />
      </UploadWrapper>
      <InfoBlockTitle>სათაური</InfoBlockTitle>
      <InfoBlockInput
        value={block[language]?.title}
        onChange={(event) => {
          setInfoBlocks(updateInfoBlockField(infoBlocks, language, blockIndex, "title", event.target.value));
        }}
      />
      <InfoBlockTitle>წარწერა</InfoBlockTitle>
      <InfoBlockInput
        value={block[language]?.description}
        onChange={(event) => {
          setInfoBlocks(updateInfoBlockField(infoBlocks, language, blockIndex, "description", event.target.value));
        }}
      />
    </FieldsetStyle>
  );
};

type Props = {
  language: EnabledLanguage;
  infoBlocks: InfoBlock[];
  setInfoBlocks: (_: InfoBlock[]) => void;
};
const InfoBlocksEditor = ({ language, infoBlocks, setInfoBlocks }: Props) => {
  return (
    <>
      {infoBlocks?.map((block, index) => (
        <SingleBlockEditor
          block={block}
          blockIndex={index}
          infoBlocks={infoBlocks}
          setInfoBlocks={setInfoBlocks}
          language={language}
        />
      ))}
      <AddBlockBtn
        type="button"
        onClick={() => {
          setInfoBlocks([
            ...infoBlocks,
            { icon: "", ge: { title: "", description: "" }, en: { title: "", description: "" } },
          ]);
        }}
      >
        <BsPlus /> ბლოკის დამატება
      </AddBlockBtn>
    </>
  );
};
// styles 
const CloudIconWrapper = styled.div`
  width: 76px;
  height: 56px;
  background-color: #fff;
  position: absolute;
  top: -3px;
  left: -3px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  border: 1px solid ${Colors.Primary};
`;
const AiOutlineCloudUploadStyle = styled(AiOutlineCloudUpload)`
  font-size: 30px;
  position: absolute;
  top: 12px;
  left: 22px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
  color: ${Colors.Primary};
`;
const BlockImg = styled.img`
  width: inherit;
  height: inherit;
  /* border-radius: inherit; */
  object-fit: contain;
`;
const AddBlockBtn = styled.button`
  height: 40px;
  border: 1px solid ${Colors.Gray};
  border-radius: 10px;
  background-color: transparent;
  padding: 0px 10px;
  transition: all 200ms ease-in-out;
  color: ${Colors.Gray};
  margin-left: auto;
  svg {
    /* font-size: 20px; */
  }
    &:hover {
      transition: all 200ms ease-in-out;
      transform: scale(1.03);
      border-color: ${Colors.Primary};
      color: ${Colors.Primary};
    }
`;
const FieldsetStyle = styled.fieldset`
  margin-bottom: 20px;
  border: 1px solid #666;
  padding: 10px;
  border-radius: 10px;
`;
const InfoBlockInput = styled.input`
  border-radius: 4px;
  outline: none;
  border: 1px solid gray;
  padding: 7px 10px;
  margin-bottom: 15px;
  width: 100%;
  cursor: pointer;
  &:last-child {
    margin-bottom: 10px;
  }
`;
const InfoBlockTitle = styled.h5`
  font-family: ${Fonts.FiraGoRegular};
  font-size: 16px;
  margin-bottom: 5px;
`;
const UploadWrapper = styled.div`
  position: relative;
  height: 50px;
  width: 70px;
  /* border: 1px solid gray; */
  border-radius: 10px;
  margin-bottom: 30px;
`;
const UploadInput = styled.input`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

export default InfoBlocksEditor;
