import React from "react";
import styled from "styled-components";
import Fonts from "../assets/Fonts";


const TourCard: React.FC<{
    topic?: string;
    link?: string;
    readMore?: string;
    img?: string;
    title?: string;

    description?: string;
    price?: string;
    duration?: string;
    rate?: string;
    reviews?: string;
    avr?: string;
    tourId?: number,
}> = ({
    topic,
    link,
    readMore,
    img,
    title,

    description,
    price,
    duration,
    rate,
    reviews,
    avr,
    tourId
}) => {


        return (

            <div className="row g-0 box_list isotope-item popular m-0" style={{ fontFamily: 'FiraGoRegular' }}>
                <div className="col-lg-12">
                    <figure>
                        {/* <small>{topic}</small> */}
                        <a href={link}><ImageStyle src={img} className="img-fluid" alt="" height="533" /><div className="read_more"><span>{readMore}</span></div></a>
                    </figure>
                </div>
                <div className="col-lg-12">
                    <div className="wrapper">
                        {/* <a href="#0" className="wish_bt"></a> */}
                        <Title><a href={link}>{title}</a></Title>
                        <DescrText className="m-0">{description}</DescrText>
                        {/* <span className="price"> <strong>${price}</strong> დან /ერთ პერსონაზე</span> */}
                    </div>
                    {/* <ul>
                        <li><i className="icon_clock_alt"></i> {duration}</li>
                        <li><div className="score"><span>{rate}<em>{reviews} მიმოხილვა </em></span><strong>{avr}</strong></div></li>
                    </ul> */}
                </div>
            </div>
        )
    }

const Title = styled.h3`
    font-family: ${Fonts.FiraGoBold};
`
const ImageStyle = styled.img`
    width: 100% !important; 
    object-fit: cover;
`;
const DescrText = styled.p`
     font-family: ${Fonts.FiraGoBold};
     font-size: 13px;
     overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* number of lines to show */
    -webkit-box-orient: vertical;
`









export default TourCard