import React, { useState } from "react";
import TopBanner from "../components/TopBanner";
import TourCard from "../components/TourCard";
import TopDestCoverImg from '../images/TopDestionationImg.jpg'
import SearchImg from '../images/search-icon.svg'
import LocationImg from '../images/maps-line-icon.svg'
import styled from "styled-components";
import Input from "../components/Input";
import CustomSelect from "../components/CustomSelect";
import SubmitButton from "../components/Button";
import api from "../api";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";



const TopDestinations: React.FC = () => {
    // data import
    const { data: destinations = [], isFetching: isDestinationsLoading, refetch: refetchDestinations } = api.useGetDestinationsQuery(undefined);
    const [place, setPlace] = useState('')
    const [where, setWhere] = useState('')
    const [category, setCategory] = useState('')
    
    // translation import
    const { t } = useTranslation();

    return (
        <>
            <TopBanner
                title='ტოპ მიმართულებები'
                bannerBackImg={TopDestCoverImg}
            />
            <div className="container margin_60_35">
                <div className="col-lg-12">
                    <FormWrapper className="row g-0 custom-search-input-2 inner my-0">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className=""
                                    placeholder="რას ეძებთ..."
                                    id=''
                                    label='რას ეძებთ'
                                    required={false}
                                    error={false}
                                    value={place}
                                    onChange={(value) => setPlace(value)}
                                />
                                <IconImg className="icon_search" src={SearchImg} alt="" />
                            </div>
                        </div>
                        <PaddLeft className="col-lg-3">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    className=""
                                    placeholder="სად ..."
                                    id=''
                                    label='სად ?'
                                    required={false}
                                    error={false}
                                    value={where}
                                    onChange={(value) => setWhere(value)}
                                />
                                <IconImg className="icon_pin_alt" src={LocationImg} alt="" />
                            </div>
                        </PaddLeft>
                        <PaddLeft className="col-lg-3">
                            <CustomSelect
                                id=''
                                label={t("allCategoryInput")}
                                options={[
                                    { value: '1', label: "ყველა კატეგორია" },
                                    { value: '2', label: "ეკლესიები" },
                                    { value: '3', label: "ისტორიული" },
                                    { value: '4', label: "მუზეუმები" },
                                    { value: '5', label: "გასეირნება" },
                                ]}
                                value={category}
                                onChange={(value) => setCategory(value)}
                            />
                        </PaddLeft>
                        <PaddLeft className="col-lg-2">
                            <SubmitButton
                                className="btn_search"
                                value=""
                                onClick={() => alert('Button 1 is clicked !')}
                                circle={false}
                                title={t("allSearchButton")}
                                widthFluid={true}
                                heightFluid={true}
                            />
                        </PaddLeft>
                    </FormWrapper>
                    {/* /row */}
                </div>
                {/* /custom-search-input-2 */}
                {isDestinationsLoading ? <Loader /> : (
                    <div className="isotope-wrapper row">
                        {destinations.map((d) => (
                            <div key={d.id} className="col-lg-4 mt-4">
                                <TourCard
                                    topic={''}//d.category}
                                    link={'/destination/' + d.slug}
                                    tourId={d.id}
                                    readMore={t("allSeeAllButton")}
                                    img={d.cover}
                                    title={d.title}
                                    description={d.description}
                                />
                            </div>
                        ))}

                    </div>)}

                <p className="text-center add_top_30"><a href="#0" className="btn_1 rounded">{t("allLoadMoreButton")}</a></p>

            </div>

        </>
    )
}


const IconImg = styled.img`
	border-radius: 3px;
    font-size: 18px;
    font-size: 1.125rem;
    position: absolute;
    background-color: #fff;
    line-height: 50px;
    top: 15px;
    right: 1px;
    padding-right: 15px;
    display: block;
    width: 15px;
    box-sizing: content-box;
    /* height: 50px; */
    z-index: 9;
    color: #999;
	
`

const FormWrapper = styled.div`
	input{
		height: unset;
		border-right: unset;
	}
	
`
const PaddLeft = styled.div`
	padding-left: 5px;
`

export default TopDestinations