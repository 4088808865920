import React from "react";
import TopBanner from "../components/TopBanner";
import ContactCoverImg from '../images/contact_cover_img.jpg'
import Map from '../components/Map'
import RequestCallbackForm from "../components/RequestCallbackForm";
import styled from "styled-components";
import Fonts from "../assets/Fonts";
import Colors from "../assets/Colors";

const RequestTickets: React.FC = () => {
  return (
    <div>
      <TopBanner
        title='ავიაბილეთები'
        bannerBackImg={ContactCoverImg}
      />
      {/*/hero_in*/}

      <div className="container">
      <TicketsTittle>
        კომპანია ვოიაჟერი ავიაკომპანიების flydubai, gulfair, Jazeera, Salamair, flynas გენერალური დისტრიბუტორია საქართველოში. ჩვენ გთავაზობთ ავიაბილეთებს მსოფლიოს მასშტაბით.
      </TicketsTittle>      

      <RequestCallbackForm
      showFullNameInput={true}
      showNumberInput={true}
      showEmailInput={true}
      showDirectionSelector={true}
      showDateInput={true}
      showNumberOfPassengersSelector={true}
      />

      </div>
      
    </div>
  );
};
export default RequestTickets;

const TicketsTittle = styled.p`
    font-family: ${Fonts.FiraGoRegular};
    font-size: 17px;
    color: ${Colors.Black};
    text-align: center;
    margin-top: 20px;
`;
