import React from "react"
import styled from 'styled-components';
import Fonts from "../assets/Fonts";
import Responsive from '../assets/Responsive'

const TopBanner: React.FC<{
    title?: string;
    bannerBackImg?: string;
}> = ({
    title,
    bannerBackImg
}) => {
        return (
            <Wrapper className="hero_in general" style={{ backgroundImage: "url(" + bannerBackImg + ")" }}>
                <div className="wrapper">
                    <div className="container">
                        <Title className="fadeInUp"><span></span>{title}</Title>
                    </div>
                </div>
            </Wrapper>
        )
    }




const Wrapper = styled.section`
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;
const Title = styled.h1`
    font-family: ${Fonts.FiraGoBold};
    font-weight: 400 !important;
    ${Responsive.mobile}{
        font-size: 21px !important;
    }
    ${Responsive.tablet}{
        font-size: 27px !important;
    }
`;


export default TopBanner