import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api, { uploadUrl } from "../../../api";
import Loader from "../../../components/Loader";
import NotFound from "../../../components/NotFound";
//
import { FaCloudUploadAlt } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import Fonts from "../../../assets/Fonts";
// MUI
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//
import { useTranslation } from "react-i18next";
import { AdminContact, ContactTranslatedFields, EnabledLanguage, supportedLocales } from "../../../domain/types";
import Input from "../../../components/Input";

const Admin_contact_info = () => {
  const {
    data: existingContact,
    isFetching: isAdminContactLoading,
    refetch: refetchAdminContact,
  } = api.useGetAdminContactQuery(undefined);
  //
  console.log(existingContact);
  //
  const [editedContactMessage, setEditingContactMessage] = useState<Partial<AdminContact>>({});
  // example: setTourField('slug', 'NEW_CHANGED_SLUG')
  const setContactInfoField = (field: keyof AdminContact, value: any) => {
    setEditingContactMessage({
      ...editedContactMessage,
      [field]: value,
    });
  };
  /** example:
   * setTranslatedField("city", "ge", value)
   */
  const setTranslatedField = (field: keyof ContactTranslatedFields, language: EnabledLanguage, value: any) => {
    setEditingContactMessage({
      ...editedContactMessage,
      translations: {
        ...editedContactMessage.translations,
        [language]: {
          ...editedContactMessage?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  //
  const [previewCoverPhotoTmpUrl, setPreviewCoverPhotoTmpUrl] = useState("");
  //
  const [language, setLanguage] = useState<EnabledLanguage>("ge");
  //
  useEffect(() => {
    if (existingContact) {
      // copy loaded tour from backend to draft useState tour
      setEditingContactMessage(existingContact);
    }
  }, [existingContact]);
  //
  const [saveContactInfo, { isLoading: isSaveCOntactInfoLoading }] = api.useSaveContactInfoMutation();
  //
  const [uploadFile, { isLoading: isUploadFileLoading, error: uploadFileError }] = api.useAdminUploadFileMutation();
  //
  const { t } = useTranslation();
  //

  return (
    <>
      {isAdminContactLoading ? (
        <Loader />
      ) : !existingContact ? (
        <NotFound />
      ) : (
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <div className="card">
              <div className="card-body p-12">
                <form action="" id="kt_invoice_form">
                  <div
                    className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                  >
                    <span className="fs-2x fw-bolder text-gray-800">საკონტაქტო ინფორმაციის განახლება</span>
                  </div>
                  <div className="separator separator-dashed my-10" />
                  <div className="mb-0">
                    <div className="row gx-10 mb-5">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="mb-4 col-lg-6">
                            <div className="mb-3 col-lg-6 w-100">
                              <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">მისამართი</label>
                              <Input
                                id=""
                                label={"მისამართი"}
                                placeholder={"მისამართი"}
                                type="text"
                                // instead city there should be country from data
                                value={editedContactMessage?.translations?.[language]?.address || ""}
                                required={false}
                                error={false}
                                helperText=""
                                onChange={(value) => setTranslatedField("address", language, value)}
                              />
                            </div>
                          </div>
                          <div className="mb-4 col-lg-6">
                            <div className="mb-3 col-lg-6 w-100">
                              <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">ორშაბათ-პარასკევი</label>
                              <Input
                                id=""
                                label={"ორშაბათ-პარასკევი"}
                                placeholder={"ორშაბათ-პარასკევი"}
                                type="text"
                                value={editedContactMessage.mondayFriday || ""}
                                required={false}
                                error={false}
                                helperText=""
                                onChange={(value) => setContactInfoField("mondayFriday", value)}
                              />
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <div className="row">
                          <div className="mb-4 col-lg-6">
                            <div className="mb-3 col-lg-6 w-100">
                              <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">მეილი</label>
                              <Input
                                id=""
                                label={"მეილი"}
                                placeholder={"მეილი"}
                                type="text"
                                // instead city there should be country from data
                                required={false}
                                error={false}
                                helperText=""
                                value={editedContactMessage.email || ""}
                                onChange={(value) => setContactInfoField("email", value)}
                              />
                            </div>
                          </div>
                          <div className="mb-4 col-lg-6">
                            <div className="mb-3 col-lg-6 w-100">
                              <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">ტელეფონის ნომერი</label>
                              <Input
                                id=""
                                label={"ტელეფონის ნომერი"}
                                placeholder={"ტელეფონის ნომერი"}
                                type="text"
                                required={false}
                                error={false}
                                helperText=""
                                value={editedContactMessage.phone || ""}
                                onChange={(value) => setContactInfoField("phone", value)}
                              />
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <div className="row">
                          <div className="mb-4 col-lg-6">
                            <div className="mb-3 col-lg-6 w-100">
                              <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">
                                შაბათ-კვირა საათები
                              </label>
                              <Input
                                id=""
                                label={"შაბათ-კვირა საათები"}
                                placeholder={"შაბათ-კვირა საათები"}
                                type="text"
                                required={false}
                                error={false}
                                helperText=""
                                value={editedContactMessage.weekend || ""}
                                onChange={(value) => setContactInfoField("weekend", value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* BEGIN Upload Slider Photo */}
                      <UploadWrapper className="mt-5">
                        <TitleLabel className="form-label fs-6 fw-bolder text-gray-700 mb-3">ქოვერის ფოტო</TitleLabel>
                        <div>
                          {previewCoverPhotoTmpUrl || editedContactMessage.cover ? (
                            <PrevViewImg src={previewCoverPhotoTmpUrl || uploadUrl(editedContactMessage.cover!)} />
                          ) : null}
                        </div>
                        <UploadContent>
                          <UploadFile>
                            <UploadFileIcon />
                            <UploadInput
                              type="file"
                              accept="image/*"
                              onChange={async (event) => {
                                const files = event.target.files;
                                if (!files || !files.length) {
                                  return;
                                }
                                try {
                                  /** for now only single file upload */
                                  const file = files[0];
                                  // preview photo before upload
                                  setPreviewCoverPhotoTmpUrl(URL.createObjectURL(file));
                                  const uploadResult = await uploadFile({
                                    file,
                                  }).unwrap();
                                  if (uploadResult.path) {
                                    setContactInfoField("cover", uploadResult.path);
                                    // preview photo AFTER upload
                                    setPreviewCoverPhotoTmpUrl(uploadUrl(uploadResult.path));
                                  } else {
                                    throw new Error("upload failed");
                                  }
                                } catch (error) {
                                  console.log("error while uploading file", error);
                                  alert((error as Error).message?.[0] ?? "ფაილის ატვირთვა ვერ მოხერხდა.");
                                }
                              }}
                            />
                          </UploadFile>
                          {previewCoverPhotoTmpUrl && (
                            <DeleteUploadFileBtn type="button" onClick={() => setPreviewCoverPhotoTmpUrl("")}>
                              <DeleteFileIcon />
                            </DeleteUploadFileBtn>
                          )}
                        </UploadContent>
                      </UploadWrapper>
                      {/* END upload Slider photo */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="flex-lg-auto min-w-lg-300px">
            <div
              className="card"
              data-kt-sticky="true"
              data-kt-sticky-name="invoice"
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', lg: '300px'}"
              data-kt-sticky-left="auto"
              data-kt-sticky-top="150px"
              data-kt-sticky-animation="false"
              data-kt-sticky-zindex={95}
            >
              <div className="card-body p-10">
                <div className="separator separator-dashed mb-8" />

                <div className="separator separator-dashed mb-8" />

                <div className="mb-10">
                  <BoxStyle>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">{t("changeLanguage")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        label={t("changeLanguage")}
                        onChange={(event) => setLanguage(event.target.value as EnabledLanguage)}
                      >
                        {supportedLocales.map((locale) => (
                          <MenuItemStyle value={locale}>{locale}</MenuItemStyle>
                        ))}
                      </Select>
                    </FormControl>
                  </BoxStyle>
                </div>

                <div className="separator separator-dashed mb-8" />
                <div className="mb-0">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    id="kt_invoice_submit_button"
                    onClick={async () => {
                      // save tour
                      try {
                        const updatedContact = await saveContactInfo(editedContactMessage);
                        if ("error" in updatedContact) {
                          throw updatedContact.error;
                        }
                        alert("მონაცემები წარმატებით დამახსოვრდა");
                        // refetchExistingTour();
                      } catch (error) {
                        const errorMessage =
                          // @ts-ignore
                          error?.data?.errors?.[0].message || t("errorWhileSaving");
                        toast.error(errorMessage);
                      }
                    }}
                  >
                    <span className="svg-icon svg-icon-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                        <path
                          d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" />
        </div>
      )}
    </>
  );
};

//
const UploadContent = styled.div`
  display: flex;
  margin-top: 20px;
`;
const DeleteUploadFileBtn = styled.button`
  height: 50px;
  width: 70px;
  border: 1px solid #d40000;
  border-radius: 10px;
  margin-left: 20px;
  background-color: #fff;
  outline: none;
`;
const UploadWrapper = styled.div`
  margin-bottom: 40px;
`;
const PrevViewImg = styled.img`
  height: 150px;
  width: 150px;
  object-fit: cover;
  display: block;
`;
const UploadFile = styled.div`
  position: relative;
  height: 50px;
  width: 70px;
  border: 1px solid gray;
  border-radius: 10px;
`;
const DeleteFileIcon = styled(RiDeleteBinFill)`
  font-size: 27px;
  color: #d40000;
`;
const UploadFileIcon = styled(FaCloudUploadAlt)`
  font-size: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: gray;
  user-select: none;
  pointer-events: none;
`;
const UploadInput = styled.input`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  &::after {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    cursor: pointer;
  }
`;
const TitleLabel = styled.label`
  font-size: 18px;
  font-family: ${Fonts.FiraGoRegular};
`;
//
//
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Block = styled.div``;
const BoxStyle = styled(Box)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;
const MenuItemStyle = styled(MenuItem)`
  font-family: ${Fonts.FiraGoRegular} !important;
  text-transform: uppercase;
`;
const DateDiv = styled.div`
  margin-left: 10px;
`;
const InvoiceDiv = styled.div`
  justify-content: unset;
  margin-left: 10px;
`;
const MailSpan = styled.span`
  font-size: 13px !important;
`;
export default Admin_contact_info;

//   < div className = "d-flex flex-column flex-lg-row" >
//     <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
//       <div className="card">
//         <div className="card-body p-12">

//           <Wrapper>
//             <Row>
//               <Col>
//                 <Block>
//                   <div className="fs-6 fw-bolder text-gray-700 text-nowrap">საკონტაქტო ნომერი:</div>

//                   <DateDiv className="position-relative d-flex align-items-center w-150px">
//                     <Input
//                       id=''
//                       label='ნომერი'
//                       placeholder="+995..."
//                       type='text'
//                       value={phone}
//                       required={false}
//                       error={false}
//                       helperText=''
//                       onChange={(value) => setPhone(value)}
//                     />
//                   </DateDiv>
//                 </Block>
//               </Col>
//               <Col>
//                 <Block>
//                   <div className="fs-6 fw-bolder text-gray-700 text-nowrap">კვირის დღეებში:</div>
//                   <DateDiv className="position-relative d-flex align-items-center w-150px">
//                     <Input
//                       id=''
//                       label='საათი'
//                       placeholder="09:00 - 14:00"
//                       type='text'
//                       value={weekend}
//                       required={false}
//                       error={false}
//                       helperText=''
//                       onChange={(value) => setWeekend(value)}
//                     />
//                   </DateDiv>
//                 </Block>
//               </Col>
//             </Row>

//             <Row>
//               <Col>
//                 <Block>
//                   <div className="fs-6 fw-bolder text-gray-700 text-nowrap">კვირის დღეებში:</div>
//                   <DateDiv>
//                     <Input
//                       id=''
//                       label='საათი'
//                       placeholder="09:00 - 18:00"
//                       type='text'
//                       value={mondayFriday}
//                       required={false}
//                       error={false}
//                       helperText=''
//                       onChange={(value) => setMondayFriday(value)}
//                       border={true}
//                     />
//                   </DateDiv>
//                 </Block>
//               </Col>
//               <Col>
//                 <Block>
//                   <MailSpan className="fs-2x fw-bolder text-gray-800">ელ.ფოსტა</MailSpan>
//                   <InvoiceDiv>
//                     <Input
//                       id=''
//                       label='ელ.ფოსტა'
//                       placeholder="...@example.com"
//                       type='text'
//                       value={email}
//                       required={false}
//                       error={false}
//                       helperText=''
//                       onChange={(value) => setEmail(value)}
//                       border={true}
//                     />
//                   </InvoiceDiv>
//                 </Block>
//               </Col>
//             </Row>

//           </Wrapper>

//         </div>
//       </div>
//     </div>
// </ >
