import { createGlobalStyle } from "styled-components";
import Fonts from "../assets/Fonts";

import FiragoBoldWoff from "../assets/fonts/FiraGO-Bold.woff";
import FiragoBoldWoff2 from "../assets/fonts/FiraGO-Bold.woff2";
import FiragoRegularWoff from "../assets/fonts/FiraGO-Regular.woff";
import FiragoRegularWoff2 from "../assets/fonts/FiraGO-Regular.woff2";




export default createGlobalStyle`
    @font-face {
    font-family: ${Fonts.FiraGoBold};
    src: url(${FiragoBoldWoff2}) format('woff2'),
        url(${FiragoBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
    @font-face {
    font-family:${Fonts.FiraGoRegular};
    src: url(${FiragoRegularWoff2}) format('woff2'),
        url(${FiragoRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
`